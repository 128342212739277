import request from "@/utils/request";

// 获取列表
export function list(params) {
  return request({
    url: "/member/colorcomprehistory/selectColorCompreHistorylist",
    method: "get",
    params: params,
  });
}

// 获取信息
export function info(params) {
  return request({
    url: "/member/colorcomprehistory/info",
    method: "get",
    params: params,
  });
}

// 添加
export function add(params) {
  return request({
    url: "/member/colorcomprehistory/save",
    method: "post",
    data: params,
  });
}

// 修改
export function update(params) {
  return request({
    url: "/member/colorcomprehistory/update",
    method: "post",
    data: params,
  });
}

// 删除
export function del(params) {
  return request({
    url: "/member/colorcomprehistory/delete",
    method: "post",
    data: params,
  });
}

// 添加
export function saveColorimetricHistoryApi(data) {
  let url = "/product/colorimetricHistory/save";
  if (data.id) {
    url = "/product/colorimetricHistory/update";
  }
  return request({
    url: url,
    method: "post",
    data,
  });
}

// 列表
export function listColorimetricHistoryApi(params) {
  return request({
    url: "/product/colorimetricHistory/list",
    method: "get",
    params: params,
  });
}

// 信息
export function infoColorimetricHistoryApi(id) {
  return request({
    url: `/product/colorimetricHistory/info/${id}`,
    method: "get",
  });
}

// 删除
export function delColorimetricHistoryApi(id) {
  return request({
    url: `/product/colorimetricHistory/delete/${id}`,
    method: "delete",
  });
}
