<template>
  <el-dialog
    :title="$t('print_x', { x: $t('employee_number').toLowerCase() })"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
    @close="close()"
  >
    <div id="printarea" ref="print">
      <table border="1" width="100%" frame="void" rules="none">
        <tr>
          <td style="width: 50%">
            <vue-qr
              style="width: 250px; height: 250px; margin-left: 10px"
              :text="dataForm.userNo"
              v-if="dataForm.userNo"
            />
          </td>
          <td style="width: 50%">
            <div
              style="
                width: 70%;
                height: 240px;
                writing-mode: vertical-lr;
                margin-top: 10px;
                font-size: 35px;
                text-align: center;
              "
            >
              <strong>{{ dataForm.userNo }}</strong>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">{{ $t('cancel') }}</el-button>
      <el-button
        type="primary"
        @click="printSub()"
        size="small"
        :disabled="dataLoading"
        >{{ $t('print') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      showBtn: false,
      dataForm: {
        userNo: ''
      }
    }
  },
  components: { VueQr },
  methods: {
    close() {
      this.visible = false
    },
    init(userNo) {
      this.dataForm.userNo = userNo
      this.visible = true
    },
    printSub() {
      document.body.innerHTML = document.getElementById('printarea').innerHTML
      window.print()
      setTimeout('window.close();', 0)
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 30px;
  }
  .right {
    margin-left: 5px;
    margin-right: 30px;
  }
}
.fontNormal {
  font-weight: normal;
}
</style>
