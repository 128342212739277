<template>
  <div class="app-container colorcompre">
    <div style="margin: 0px; top: 140px; position: absolute">
      {{ $t('comparison_conditions') }}：
      <el-select v-model="observerAngle" class="condition-form" size="mini" @change="changeObserver()"
        :disabled="canChangeConditions">
        <el-option label="2°" value="two_deg"></el-option>
        <el-option label="10°" value="ten_deg"></el-option>
      </el-select>
      <el-select v-model="illuminant" class="condition-form" size="mini" @change="changeIlluminant()"
        :disabled="canChangeConditions">
        <el-option label="A" value="A"></el-option>
        <el-option label="C" value="C"></el-option>
        <el-option label="D50" value="D50"></el-option>
        <el-option label="D55" value="D55"></el-option>
        <el-option label="D65" value="D65"></el-option>
        <el-option label="F2" value="F2"></el-option>
        <el-option label="F11" value="F11"></el-option>
      </el-select>
    </div>
    <div type="flex" style="
        text-align: center;
        align-items: center;
        height: 75px;
        font-size: 20px;
        font-weight: 550;
        color: #000000;
        -webkit-text-stroke: 1 #000000;
        text-stroke: 1 #000000;
      ">
      <div class="titleone">
        <img :src="require('@/icons/svg/colorlogo.png')" style="'width:84px; height: 27px; margin-top:-10px;'" />L*a*b*
        {{ $t('comparison_color_diff') }}
      </div>
    </div>

    <div class="contentBox">
      <el-row type="flex" align="middle" style="
          text-align: center;
          border-bottom: 2px solid #d2d2d2;
          height: 56px;
        ">
        <el-col :span="2" style="font-weight: 550">{{
          $t('add_comparison')
        }}</el-col>
        <el-col :span="3" style="
            font-weight: 550;
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 56px;
          ">{{ $t('sample_name') }}</el-col>
        <el-col :span="7" style="
            font-weight: 550;
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 56px;
          ">{{ $t('query_mode') }}</el-col>
        <el-col :span="2" style="
            font-weight: 550;
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 56px;
          ">LAB</el-col>
        <el-col :span="2" v-for="(x4, index) in proLabAngle" :key="'labtitle' + index" style="
            font-weight: 550;
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 56px;
          ">{{ x4 }}</el-col>
        <el-col :span="4" style="
            font-weight: 550;
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 56px;
          ">{{ $t('color_preview') }}</el-col>
      </el-row>
      <el-row v-for="(x, index) in formList" :key="'row' + index"
        style="border-bottom: 2px solid rgb(210, 210, 210); height: 180px">
        <el-col :span="2" style="height: 100%; text-align: center">
          <el-row :style="{
            'margin-top': index != 0 && formList.length > 2 ? '40px' : '70px'
          }"><el-checkbox v-model="formList[index].checkState" size="medium" @change="checkState(index)"
              :disabled="index == 0 ? true : false"></el-checkbox></el-row>
          <el-row v-if="index > 0 && formList.length > 2" style="margin-top: 20px">
            <el-button @click="handleDelSample(index)">{{
              $t('remove')
            }}</el-button>
          </el-row>
        </el-col>
        <el-col :span="3" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 180px;
            text-align: center;
          ">
          <el-row class="inputkuang">
            <el-input v-model="x.itemName" :placeholder="x.itemName" style="width: 60%" size="small"
              @input="setItemName(index)"></el-input></el-row></el-col>
        <el-col :span="7" style="padding: 13px 8px; height: 100%">
          <el-row type="flex" align="middle" style="height: 40px">
            <el-col :span="24">
              <el-radio-group v-model="x.checkType" @change="checkTypeChanged(x.checkType)">
                <el-radio :label="0">{{ $t('product_number') }}</el-radio>
                <el-radio :label="1">{{ $t('lab_values') }}</el-radio>
                <el-radio :label="2">{{ $t('from_device') }}</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row align="middle" style="
              height: 90px;
              justify-content: center;
              vertical-align: middle;
            ">
            <el-col :span="24" style="height: 90px">
              <div v-show="x.checkType == 0" class="inputkuangtwo" style="line-height: 90px; white-space: nowrap">
                <el-autocomplete v-model="x.productNo" :fetch-suggestions="querySearch"
                  :placeholder="$t('enter_product_number')" :trigger-on-focus="true" @select="(item) => {
                    handleSelect(item, index)
                  }
                    " @input="inputValue = x.productNo" @blur="inputValue = ''" @focus="inputValue = x.productNo"
                  size="small" :debounce="10" ref="mycolorNoType" style="width: 70%">
                  <template slot-scope="{ item }">
                    <div>
                      <span class="color-card-item" :style="{ backgroundColor: item.hex }"></span>

                      <div class="value-style">
                        {{ item.value }}
                      </div>

                      <div class="name-style">
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-autocomplete>
                <el-button size="small" type="primary" @click="getE00value()"
                  style="margin-left: 2px; padding: 9px 10px">{{
                    $t('search') }}</el-button>
              </div>
              <div v-show="x.checkType == 1" class="input12">
                <el-row v-for="(x4, index1) in proLab" :key="index1">
                  <el-col :span="4">
                    <el-input :placeholder="x4" size="small" @input="change($event)" :disabled="true"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="x['l' + (index1 + 1)]" :placeholder="'L*'" size="small">
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="x['a' + (index1 + 1)]" :placeholder="'a*'" size="small">
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="x['b' + (index1 + 1)]" :placeholder="'b*'" size="small">
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button v-show="index1 == 1" size="small" type="primary" @click="getE00value()"
                      style="margin-left: 2px; padding: 9px 10px">{{ $t('search') }}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-show="x.checkType == 2" @click="setIndex(index)">
                <read-device @read="dataReceived" @readError="readError" :observerAngle="observerAngle"
                  :illuminant="illuminant" />
                <!-- <read-device @read="dataReceived" @readError="readError" @changeObserver="changeObserver" @changeIlluminant="changeIlluminant" :observerAngle="observerAngle" :illuminant="illuminant" /> -->
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="2" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          ">
          <el-row class="row-text">25°</el-row>
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2">45°</el-row>
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2">75°</el-row>
        </el-col>

        <el-col :span="2" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          ">
          <template v-for="(x4, index) in proLab">
            <el-row style="border-bottom: 2px solid rgb(210, 210, 210); height:60px ;" class="row-text">
              {{ x['l' + (index + 1)] }}
            </el-row>
            <!-- <el-row class="row-text" style="border-top: 2px solid #d2d2d2">{{
              x['a' + (index + 1)]
            }}</el-row>
            <el-row class="row-text" style="border-top: 2px solid #d2d2d2">{{
              x['b' + (index + 1)]
            }}</el-row> -->
          </template>
        </el-col>
        <el-col :span="2" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          ">
          <template v-for="(x4, index) in proLab">
            <el-row style="border-bottom: 2px solid rgb(210, 210, 210); height:60px ;" class="row-text">
              {{ x['a' + (index + 1)] }}
            </el-row>
          </template>
        </el-col>
        <el-col :span="2" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          ">
          <template v-for="(x4, index) in proLab">
            <el-row style="border-bottom: 2px solid rgb(210, 210, 210); height:60px ;" class="row-text">
              {{ x['b' + (index + 1)] }}
            </el-row>
          </template>
        </el-col>
        <el-col :span="4" style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          ">
          <div style="
              display: flex;
              justify-content: center;
              height: 100%;
              line-height: 180px;
            ">
            <img v-if="x.picUrl" class="pic" :src="x.picUrl" min-width="100%" />
            <div v-else :style="{ backgroundColor: x.hex }" class="pic"></div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row v-if="formList.length < 51" style="text-align: right; margin-top: 10px">
      <el-button @click="handleAddSample">{{ $t('add_sample') }}</el-button>
    </el-row>

    <div type="flex" class="twotitle" style="
        text-align: center;
        height: 75px;
        font-size: 20px;
        font-weight: 550;
        color: #000000;
        -webkit-text-stroke: 1 #000000;
        text-stroke: 1 #000000;
      ">
      <div class="titletwo">
        <img :src="require('@/icons/svg/colorlogo.png')" style="'width:84px; height: 27px;'" />L*a*b* {{
          $t('color_difference_comparison_results') }}
      </div>
      <div v-if="testList && testList.length > 0" style="'font-size:14px;color: #6F73FF;'" class="daochu">
        <el-button size="small" type="primary" @click="handleSave()" style="margin-right: 30px" :loading="hisloading">{{
          $t('save') }}</el-button>
        <el-button v-if="dataForm.id" size="small" type="primary" @click="handleSaveAs()" style="margin-right: 30px"
          :loading="hisloading">{{ $t('save_as') }}</el-button>
        <a @click="exportExcelHandle()">
          <div class="daochu_">
            <img :src="require('@/icons/svg/daochu.png')" style="'width:15px; height: 15px;'" />
            {{ $t('export_report') }}
          </div>
        </a>
      </div>
    </div>
    <div style="margin-top: -40px; text-align: left; position: absolute">
      {{ $t('comparison_conditions') }}：
      <el-select v-model="observerAngle" class="condition-form" size="mini" @change="changeObserver()"
        :disabled="canChangeConditions">
        <el-option label="2°" value="two_deg"></el-option>
        <el-option label="10°" value="ten_deg"></el-option>
      </el-select>
      <el-select v-model="illuminant" class="condition-form" size="mini" @change="changeIlluminant()"
        :disabled="canChangeConditions">
        <el-option label="A" value="A"></el-option>
        <el-option label="C" value="C"></el-option>
        <el-option label="D50" value="D50"></el-option>
        <el-option label="D55" value="D55"></el-option>
        <el-option label="D65" value="D65"></el-option>
        <el-option label="F2" value="F2"></el-option>
        <el-option label="F11" value="F11"></el-option>
      </el-select>
    </div>
    <div class="contentBoxtwo">
      <div v-for="(testItem, index1) in testList" :key="index1" style="border-bottom: 2px solid #d2d2d2">
        <el-row type="flex" style="
            text-align: center;
            border-bottom: 2px solid #d2d2d2;
            height: 40px;
          ">
          <el-col :span="24" style="
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              height: 40px;
              line-height: 8px;
            ">
            <p>{{ testItem.testName }}</p>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" style="font-size: 14px; height: 132px; font-weight: 550">
          <el-col :span="3" style="'border-right: 2px solid #D2D2D2;height:132px;'">
            <el-row type="flex" style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 36px;
              ">
              <el-col :span="24" style="line-height: 9px">
                <p>{{ $t('multi-angle') }}</p>
              </el-col>
            </el-row>
            <el-row type="flex" style="height: 98px; line-height: 48px; text-align: center">
              <el-col :span="24" style="font-size: 24px; font-weight: 500; color: #6f73ff">
                <p>{{ testItem.del2000 === null ? '-' : testItem.del2000 }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7" v-for="(resultItem, index2) in testItem.testResult" :key="index2"
            style="padding: 0px; height: 132px; font-weight: 500">
            <el-row type="flex" style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 36px;
                line-height: 9px;
              ">
              <el-col :span="24">
                <p>{{ resultItem.resultName }}</p>
              </el-col>
            </el-row>
            <el-row type="flex" style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                color: #6f73ff;
                height: 32px;
                line-height: 6px;
              ">
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>ΔL*</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>Δa*</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>Δb*</p>
              </el-col>
              <el-col :span="4" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>ΔE*ab</p>
              </el-col>
              <el-col :span="5">
                <p>ΔE00</p>
              </el-col>
            </el-row>
            <el-row type="flex" style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 32px;
                line-height: 6px;
              ">
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.deL }}</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.dea }}</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.deb }}</p>
              </el-col>
              <el-col :span="4" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.deab }}</p>
              </el-col>
              <el-col :span="5">
                <p>{{ resultItem.dee }}</p>
              </el-col>
            </el-row>
            <el-row type="flex" style="text-align: center; height: 32px; line-height: 6px">
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.deLStr }}</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.deaStr }}</p>
              </el-col>
              <el-col :span="5" style="border-right: 2px solid #d2d2d2; height: 32px">
                <p>{{ resultItem.debStr }}</p>
              </el-col>
              <el-col :span="9">
                <p>{{ resultItem.deeStr }}</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-dialog :visible.sync="dialogVisible" :title="$t('please_enter_a_colorimetric_name')" width="30%">
        <el-input v-model="dataForm.colorimetricName" placeholder="" size="small">
        </el-input>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">{{
              $t('cancel')
            }}</el-button>
            <el-button type="primary" @click="handleSaveColorimetricHistory()">{{ $t('confirm') }}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { selectProductNoList } from '@/api/modules/product'
import {
  infoColorimetricHistoryApi,
  saveColorimetricHistoryApi
} from '@/api/modules/colorcomprehistory'
import { colorcomparison } from '@/api/modules/colorcompre'
import { axiosDownFile } from '@/utils/index'
import ReadDevice from '../../components/readDevice.vue'
import { getSecondsAgoMessage } from '../../util/i18n/time'
export default {
  data() {
    return {
      observerAngle: 'ten_deg',
      illuminant: 'D65',
      activeIndex: '',
      loading: false,
      copydata: [],
      hisloading: false,
      inputValue: '',
      formList: [],
      proLab: ['25°', '45°', '75°'],
      proLabAngle: ['L*', 'A*', 'B*'],
      testList: [],
      testShow: [true, false],
      is_query: false,
      dialogVisible: false, //输入比色名称弹框
      // 保存动作 1.新增 2.编辑 3.另存为
      saveAction: 1,
      dataForm: {
        id: '',
        colorimetricName: '' //比色名称
      },
      dataListSelections: []
    }
  },
  computed: {
    canChangeConditions() {
      var hasNonDevice = false
      this.formList.forEach((item) => {
        console.log('item' + item.spectral2)
        if (item.checkType == 0) {
          hasNonDevice = true
        } else if (
          item.spectral2 == null ||
          (item.spectral2 == undefined && item.checkType == 2)
        ) {
          hasNonDevice = true
        } else if (item.spectral2.length == 0 && item.checkType == 2) {
          hasNonDevice = true
        }
      })
      return hasNonDevice
    }
  },
  components: {
    ReadDevice
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '' && value !== null) {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = ''
      }
      return realVal
    }
  },
  activated() {
    var query = this.$route.query
    if (query && query.id) {
      var id = query.id
      this.getInfo(id)
    } else {
      this.dataForm = {
        id: '',
        colorimetricName: ''
      }
      this.testList = []
      this.formList = [
        {
          checkState: true,
          itemName: this.$t('standard_sample'),
          checkType: 0,
          picUrl: '',
          hex: '',
          productNo: '',
          l1: '',
          a1: '',
          b1: '',
          l2: '',
          a2: '',
          b2: '',
          l3: '',
          a3: '',
          b3: '',
          spectral1: {},
          spectral2: {},
          spectral3: {},
          illuminant: '',
          observerAngle: ''
        },
        {
          checkState: true,
          itemName: this.$t('sample1'),
          checkType: 0,
          picUrl: '',
          hex: '',
          productNo: '',
          l1: '',
          a1: '',
          b1: '',
          l2: '',
          a2: '',
          b2: '',
          l3: '',
          a3: '',
          b3: '',
          spectral1: {},
          spectral2: {},
          spectral3: {},
          illuminant: '',
          observerAngle: ''
        }
      ]
    }
  },

  methods: {
    handleAddSample() {
      this.formList.push({
        checkState: true,
        itemName: this.$t('sample') + this.formList.length,
        checkType: 2,
        picUrl: '',
        hex: '',
        productNo: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        spectral1: {},
        spectral2: {},
        spectral3: {},
        illuminant: '',
        observerAngle: ''
      })
    },
    handleDelSample(index) {
      this.$confirm(
        this.$t('confirm_whether_delete_sample'),
        this.$t('attention'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }
      ).then(() => {
        this.formList.splice(index, 1)
      })
    },
    // 保存
    handleSaveColorimetricHistory() {
      let params = {
        colorimetricName: this.dataForm.colorimetricName,
        formList: this.formList
      }
      if (this.saveAction === 2) {
        this.$confirm(this.$t('confirm_overwrite'), this.$t('attention'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          params.id = this.dataForm.id
          this.saveColorimetricHistory(params)
        })
      } else {
        this.saveColorimetricHistory(params)
      }
    },
    saveColorimetricHistory(params) {
      saveColorimetricHistoryApi(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 1500
          })
          this.hisloading = false
          this.dialogVisible = false
          if (this.saveAction === 1 || this.saveAction === 3) {
            // 新增/另存为
            this.$router.push({
              path: '/colorcompre/',
              query: { id: data.id }
            })
            this.dataForm.id = data.id
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    handleSave() {
      if (this.dataForm.id) {
        this.saveAction = 2
      } else {
        this.saveAction = 1
      }
      this.dialogVisible = true
    },
    handleSaveAs() {
      this.saveAction = 3
      this.dialogVisible = true
    },
    handleSelect(item, index) {
      this.formList[index].productId = item.id
    },
    // handleSelect(item) {},
    querySearch(queryString, cb) {
      if (queryString && this.inputValue.length > 2) {
        setTimeout(() => {
          let params = {
            productNo: this.inputValue
          }
          selectProductNoList(params).then(({ data }) => {
            if (data && data.code === 0) {
              // var restaurants = []
              var restaurants = data.data
              restaurants.forEach((v) => {
                v.value = v.productNo
                v.name = v.makeFactory
                if (v.productOrder) {
                  v.value += '-' + v.productOrder
                }
                if (!v.makeFactory) {
                  v.name = '--'
                }
                var obj = {
                  id: v.id,
                  value: v.value,
                  name: v.name
                }
                return obj
              })
              cb(restaurants)
              // restaurants = data.data.map((v) => {
              //   let value = v.productNo
              //   if (v.productOrder) {
              //     value += '-' + v.productOrder
              //   }
              //   var obj = {
              //     id: v.id,
              //     value: value
              //   }
              //   return obj
              // })
              if (restaurants.length <= 0) {
                return this.$message.error(this.$t('no_data'))
              }
              // cb(restaurants)
            }
          })
        }, 500)
      }
    },

    getInfo(id) {
      infoColorimetricHistoryApi(id).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.id = id
          this.dataForm.colorimetricName = data.colorimetricName
          this.illuminant = data.info[0].illuminant
          this.observerAngle = data.info[0].observerAngle
          this.formList = data.info
          this.$set(this.formList)
          this.getE00value()
        }
      })
    },

    //清空input框数据
    clear(index) {
      //循环输入框的内容把数据清理掉
      this.pro[index].productNo = ''
      //遍历数据显示部分的数据，并清理掉
      this.$forceUpdate()
      // this.getE00value(index);
    },

    checkState(index) {
      this.testShow[index - 1] = this.formList[index].checkState
      this.getE00value()
    },

    setItemName(index) {
      if (index >= 1 && this.formList[index].checkState) {
        this.testList[index - 1].testName = this.formList[index].itemName
      }
    },
    //根据产品编号和LAB值进行计算（通过失去焦点事件触发）
    getE00value() {
      colorcomparison(this.formList).then(({ data }) => {
        if (data && data.code === 0) {
          this.formList = data.map.list
          if (data.map.compreList) {
            this.testList = data.map.compreList
          } else {
            this.testList = []
          }
          this.$forceUpdate()
          this.copydata = JSON.parse(JSON.stringify(this.formList))
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    readError(error) {
      this.formList[this.activeIndex].l1 = ''
      this.formList[this.activeIndex].a1 = ''
      this.formList[this.activeIndex].b1 = ''
      this.formList[this.activeIndex].l2 = ''
      this.formList[this.activeIndex].a2 = ''
      this.formList[this.activeIndex].b2 = ''
      this.formList[this.activeIndex].l3 = ''
      this.formList[this.activeIndex].a3 = ''
      this.formList[this.activeIndex].b3 = ''
      this.formList[this.activeIndex].picUrl = ''
      this.formList[this.activeIndex].hex = ''
      this.formList[this.activeIndex].spectral1 = {}
      this.formList[this.activeIndex].spectral2 = {}
      this.formList[this.activeIndex].spectral3 = {}
        ; (this.formList[this.activeIndex].illuminant = ''),
          (this.formList[this.activeIndex].observerAngle = '')
    },
    changeObserver() {
      this.formList.forEach((item) => {
        item.observerAngle = this.observerAngle
      })
      this.getE00value()
    },
    changeIlluminant() {
      this.formList.forEach((item) => {
        item.illuminant = this.illuminant
      })
      this.getE00value()
    },
    checkTypeChanged() {
      if (
        this.canChangeConditions &&
        (this.illuminant != 'D65' || this.observerAngle != 'ten_deg')
      ) {
        this.$message({
          message: this.$t('product_no_illumination_context_warning'),
          type: 'warning',
          duration: 8000
        })
        this.illuminant = 'D65'
        this.observerAngle = 'ten_deg'
        this.changeIlluminant()
        this.changeObserver()
      }
    },
    setIndex(index) {
      this.activeIndex = index
    },
    dataReceived(data) {
      // 收到了设备数据
      this.formList[this.activeIndex].l1 = data.l1
      this.formList[this.activeIndex].a1 = data.a1
      this.formList[this.activeIndex].b1 = data.b1
      this.formList[this.activeIndex].l2 = data.l2
      this.formList[this.activeIndex].a2 = data.a2
      this.formList[this.activeIndex].b2 = data.b2
      this.formList[this.activeIndex].l3 = data.l3
      this.formList[this.activeIndex].a3 = data.a3
      this.formList[this.activeIndex].b3 = data.b3
      this.formList[this.activeIndex].spectral1 = data.spectral1
      this.formList[this.activeIndex].spectral2 = data.spectral2
      this.formList[this.activeIndex].spectral3 = data.spectral3
      this.formList[this.activeIndex].illuminant = data.illuminant
        ? data.illuminant
        : 'D65'
      this.formList[this.activeIndex].observerAngle = data.observerAngle
        ? data.observerAngle
        : 'ten_deg'
      //在数据同步后赋值给dataForm时调用接口方法，把数据同步返回的数据给赋值到参数上

      this.getDataLAB(this.activeIndex)

      if (data.secondsAgo) {
        this.$message.success({
          message: this.$t('read_success', {
            x: getSecondsAgoMessage(data.secondsAgo)
          }),
          duration: 8000
        })
      }

      this.checkTypeChanged()
      this.$forceUpdate()
    },
    change(e) {
      console.log(e, '1234567890')
      this.$forceUpdate()
    },
    //选择设备显示在页面
    handleCommand(command) {
      //this.$message('click on item ' + command);
      this.device = command
    },
    //根据设备读取按钮传的数据计算色差值
    getDataLAB(index) {
      this.getE00value()
    },
    //导出比色结果excel
    exportExcelHandle() {
      axiosDownFile(`colorcompre/colorcompre/exportExcel`, this.formList)
      return
    }
  }
}
</script>
<style lang="scss">
.colorcompre {

  .el-button--mini,
  .el-button--small {
    height: 32px;
    border-radius: 7px;
  }

  .el-radio {
    margin-right: 8px;
  }

  .el-button--primary {
    font-size: 14px;
    line-height: 14px;
  }

  element.style {
    margin-left: 5px;
  }

  .el-checkbox__inner {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }

  .inputkuang {
    .el-input__inner {
      border: 2px solid #6f73ff;
      border-radius: 12px !important;
      color: #6f73ff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      height: 37px;
    }
  }

  .input12 {
    .el-input__inner {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 0px 3px;
    }

    input::-webkit-input-placeholder {
      color: rgba(86, 86, 86, 0.7) !important;
    }
  }

  .el-input__label {
    font-size: 16px;
  }

  .inputkuangtwo {
    .el-input__inner {
      border: 2px solid #b7b7b7;
      border-radius: 9px !important;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      height: 37px;
    }

    input::-webkit-input-placeholder {
      color: rgba(86, 86, 86, 0.7) !important;
    }
  }

  .el-checkbox__inner::after {
    height: 18px;
    width: 6px;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 3px;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    position: absolute;
    top: 3px;
    left: 10px;
  }

  .el-radio__inner {
    width: 15px;
    height: 15px;
  }

  .el-radio__label {
    font-size: 14px;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background: #ffffff;
    font-size: 16px;
  }

  .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #6f73ff;
  }
}
</style>
<style lang="scss" scoped>
.colorcompre {
  margin: 0 20px;

  .titleone {
    position: relative;
    width: auto;
    height: 75px;
    line-height: 75px;
    display: inline-block;
    text-align: center;

    >img {
      vertical-align: middle;
      margin: 20px 10px -10px 0px;
    }
  }

  .twotitle {
    height: 75px;
    position: relative;

    .daochu {
      display: flex;
      height: 10px;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 32px;
      font-size: 14px;

      .daochu_ {
        position: relative;

        >img {
          position: absolute;
          left: -20px;
          top: 0px;
        }
      }
    }

    .titletwo {
      position: relative;
      width: auto;
      height: 75px;
      line-height: 75px;
      display: inline-block;
      text-align: right;

      >img {
        margin: 10px;
      }
    }
  }

  .contentBox {
    border: 2px solid #d2d2d2;
    border-bottom: 0;
    width: 100%;
    border-radius: 2px;

    .row-text {
      width: 100%;
      height: 60px;
      line-height: 60px;
    }

    .el-col-3 {
      >p {
        margin: 0 auto;
        color: #000000;
        font-size: 16px;
        font-weight: 550;
      }

      >span {
        color: #333333;
        font-size: 16px;
        display: block;
        line-height: 60px;
        font-weight: 500;
        text-align: left;
        padding: 0 15px;
      }

      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
    }

    .el-col-7 {
      border-left: 2px solid #d2d2d2;

      >div:nth-child(2) {
        margin: 10px;
      }
    }

    .li10 {

      //   padding: 30px 18px 20px;
      p {
        height: 60px;
        border-bottom: 2px solid #d2d2d2;
        font-size: 16px;
        color: #666666;
        line-height: 60px;
        margin: 0;
      }

      >p:nth-last-child(1) {
        border-bottom: 0;
      }
    }

    .tit {
      text-align: right;

      p {
        padding: 0 15px;
        color: #7285e0;
        margin: 0;
      }
    }

    .pic {
      margin: 13px auto;
      width: 80%;
      height: 85%;
      border-radius: 12px;
    }

    .itemNameClass {
      text-align: center;
    }
  }

  .contentBoxtwo {
    border: 2px solid #d2d2d2;
    border-bottom: 0px;
    width: 100%;
    border-radius: 2px;

    .el-col-3 {
      >p {
        color: #707070;
        font-size: 15px;
      }

      >span {
        color: #333333;
        font-size: 16px;
        display: block;
        line-height: 60px;
        text-align: left;
        padding: 0 15px;
      }
    }

    .el-col-7 {
      border-left: 2px solid #d2d2d2;
    }

    .li10 {

      //   padding: 30px 18px 20px;
      p {
        height: 60px;
        border-bottom: 2px solid #d2d2d2;
        font-size: 16px;
        color: #666666;
        line-height: 60px;
        margin: 0;
      }

      >p:nth-last-child(1) {
        border-bottom: 0;
      }
    }

    .tit {
      text-align: right;

      p {
        padding: 0 15px;
        color: #7285e0;
        margin: 0;
      }
    }

    .itemNameClass {
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.b1 {
  height: 25px;
}

.el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.color-card-item {
  display: inline-block;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-bottom: -5px;
  margin-right: 10px;
}

.value-style {
  margin-left: 35px;
  margin-top: -47px;
}

.name-style {
  margin-left: 35px;
  margin-top: -19px;
}

.condition-form {
  width: 70px;
}
</style>
