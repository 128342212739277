<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="brandId" label="关联品牌" v-if="role == '0'">
        <el-select
          v-model="dataForm.brandId"
          size="small"
          @change="getAttrListOfBrandId()"
          placeholder="请选择品牌"
        >
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.brandName"
            :value="item.id"
            size="small"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="产品属性" prop="productAttrId">
        <el-select v-model="dataForm.productAttrId" placeholder="产品属性">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in attrList"
            :key="item.productAttrId"
            :label="item.attrName"
            :value="item.productAttrId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="valueNo" label="属性值编号">
        <el-input
          v-model="dataForm.valueNo"
          placeholder="属性值编号"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item prop="valueName" label="属性值名称">
        <el-input
          v-model="dataForm.valueName"
          placeholder="属性值名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item prop="valueAlias" label="属性值别名">
        <el-input
          v-model="dataForm.valueAlias"
          placeholder="属性值别名"
          size="small"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="显示状态">
        <el-select v-model="dataForm.isOpen" placeholder="显示状态">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in isOpenList"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button
          type="primary"
          @click="addOrUpdateHandle('', '', role)"
          size="small"
          >添加属性值</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="valueName"
        header-align="center"
        align="center"
        label="属性值名称"
      >
      </el-table-column>
      <el-table-column
        prop="valueNameEn"
        header-align="center"
        align="center"
        label="属性值英文名称"
      >
      </el-table-column>
      <el-table-column
        prop="valueAlias"
        header-align="center"
        align="center"
        label="属性值别名"
      >
      </el-table-column>
      <el-table-column
        prop="valueNo"
        header-align="center"
        align="center"
        label="属性值编号"
      >
      </el-table-column>

      <el-table-column
        prop="fatherAttrName"
        header-align="center"
        align="center"
        label="上级属性值"
      >
      </el-table-column>

      <el-table-column
        prop="attrName"
        header-align="center"
        align="center"
        label="关联属性"
      >
      </el-table-column>

      <el-table-column
        v-if="role == '0'"
        prop="brandEname"
        header-align="center"
        align="center"
        label="关联品牌"
      >
      </el-table-column>
      <el-table-column
        prop="relationAttrName"
        header-align="center"
        align="center"
        label="关联通用属性"
      >
      </el-table-column>
      <el-table-column
        prop="relationAttrValueName"
        header-align="center"
        align="center"
        label="关联通用属性值"
      >
      </el-table-column>

      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="属性说明"
      >
      </el-table-column>
      <el-table-column
        prop="sort"
        header-align="center"
        align="center"
        label="排序"
      >
      </el-table-column>
      <el-table-column
        prop="isOpen"
        header-align="center"
        align="center"
        label="是否显示"
      >
      </el-table-column>
      <el-table-column
        prop="isUniversal"
        header-align="center"
        align="center"
        label="是否通用"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.isUniversal == '1'">是</div>
          <div v-if="scope.row.isUniversal == '0'">否</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="copyHandle(scope.row.id, scope.row.brandId)"
            v-if="scope.row.isUniversal !== '1' || role == '0'"
            >复制</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="setsynProductAttr(scope.row.id)"
            v-if="scope.row.isUniversal == '1' && role == '0'"
            >同步数据</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, scope.row.brandId, role)"
            v-if="scope.row.isUniversal !== '1' || role == '0'"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="updateState(scope.row.id, scope.row.brandId)"
            v-if="scope.row.isUniversal !== '1' || role == '0'"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <copy v-if="copyVisible" ref="copy" @refreshDataList="getDataList"></copy>
  </div>
</template>

<script>
import {
  list,
  del,
  getAttrList,
  updateState,
  synProductAttrValue
} from '@/api/modules/productattrvalue'
import AddOrUpdate from './add-or-update'
import { getBrand } from '@/api/modules/product'
import Copy from './copy'
export default {
  data() {
    return {
      dataForm: {
        productAttrId: '',
        valueNo: '',
        valueName: '',
        valueAlias: '',
        brandId: '',
        isOpen: '',
        daterange: []
      },
      role: '',
      isOpenList: [
        {
          value: '0',
          label: '展示'
        },
        {
          value: '1',
          label: '隐藏'
        }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      copyVisible: false,
      attrList: [],
      brandList: []
    }
  },
  components: {
    AddOrUpdate,
    Copy
  },
  mounted() {
    const params = this.$route.query.params
    if (params === 'valAdd') {
      setTimeout(() => {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init('', '', this.role)
        })
      }, 1000)
    }
  },
  created() {
    this.getDataList('1')
  },
  methods: {
    getAttrListOfBrandId() {
      this.dataForm.productAttrId = ''
      this.getAttrList()
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
      this.getBrandList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      ;(this.dataForm.valueNo = ''),
        (this.dataForm.valueName = ''),
        (this.dataForm.valueAlias = ''),
        (this.dataForm.brandId = ''),
        this.getAttrListOfBrandId()
      ;(this.dataForm.isOpen = ''), (this.dataForm.productAttrId = '')
      this.restPage()
    },
    //获取品牌列表
    getBrandList(val) {
      let params = {}
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
        if (val == '1') {
          this.getAttrList(data)
        }
      })
    },
    // 获取数据列表
    getDataList(val) {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        productAttrId: this.dataForm.productAttrId,
        valueNo: this.dataForm.valueNo,
        valueName: this.dataForm.valueName,
        valueAlias: this.dataForm.valueAlias,
        brandId: this.dataForm.brandId,
        isOpen: this.dataForm.isOpen
      }
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records
          this.totalPage = data.page.total
          this.role = data.role
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
      if (val == '1') {
        this.getBrandList('1')
      }
    },
    // 获取属性数据列表
    getAttrList(data) {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        brandId:
          data && data.role == '1' ? data.list[0].id : this.dataForm.brandId
      }
      getAttrList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.attrList = data.page
          //this.totalPage = data.page.totalCount
        } else {
          this.attrList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    //同步数据
    setsynProductAttr(id) {
      let params = {
        id: id
      }
      synProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 复制
    copyHandle(id, brandId) {
      console.log('copy:' + id)
      this.copyVisible = true
      this.$nextTick(() => {
        this.$refs.copy.init(id, brandId)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(id, brandId, role) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, brandId, role)
      })
    },

    updateState(id, brandId) {
      var params = {
        id: id,
        brandId: brandId || ''
      }

      this.$confirm(`是否删除属性值?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateState(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
