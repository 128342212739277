<template>
  <el-dialog
    :title="!dataForm.productId ? '添加打样配方' : '添加打样配方'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body>
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="打样编号" prop="orderNo">
        <!--<el-input v-model="dataForm.orderNo" placeholder="打样编号"  size="small"></el-input>-->
        <el-select v-model="dataForm.orderNo" placeholder="请选择" @change="getSampleInfo">
          <el-option
                  v-for="item in this.sampleProductList"
                  :key="item.id"
                  :label="item.sampleNo"
                  :value="item.sampleNo"

          >
          </el-option>
        </el-select>
        <div v-show="this.sample">
          <div class="left">
            <p>
              打样订单号：
              <span class="fontNormal">{{this.sample.sampleOrderNo}}</span>
            </p>
            <p>
              交付时间：
              <span class="fontNormal">{{this.sample.deliverDate}}</span>
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {saveSampleProductByProduct,querySampleProductList} from '@/api/modules/sampleproduct'
  import {getSampleInfo} from '@/api/modules/sampleorder'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        pageIndex: 1,
        pageSize: 10,
        sample: '',
        sampleProductList:[],
        dataForm: {
          orderNo: '',
          productId: '',
          recipeId: ''
        },
        dataRule: {
          orderNo: [
            { required: true, message: '打样编号不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      close(){
        this.sample='';
        this.sampleProductList=[];
        this.dataForm.productId = 0;
        this.dataForm.orderNo = '';
        this.visible = false;
      },
      init (productId) {
        this.getSampleProductList(),
        this.visible = true
        this.dataForm.productId = productId;
      },
      //获取打样订单表数据
      getSampleInfo(value){
        var params = {
          sampleNo: value
        }
        getSampleInfo(params).then(({data}) => {
          if (data && data.code === 0) {
            this.sample = data.sampleOrder
          } else {
            this.sample = ''
          }
          this.dataListLoading = false
        })
      },
      //获取打样产品表数据
      getSampleProductList() {
        var params = {
          state: 0,
        }
        querySampleProductList(params).then(({ data }) => {
          if (data.code == 0 && data.sampleProducts.length > 0) {
            this.sampleProductList = data.sampleProducts
          }else{
            this.sampleProductList = [];
          }
        });
      },
      // 表单提交
      dataFormSubmit () {
        var params = {
          orderNo: this.dataForm.orderNo,
          productId: this.dataForm.productId
        }
        saveSampleProductByProduct(params).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('success'),
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.dataForm.productId = 0;
                this.dataForm.orderNo = '';
                this.visible = false;
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .productDirs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
    .left {
      margin-left: 20px;
    }
    .right {
      margin-right: 30px;
    }
  }
  .materialInfo {
    ul {
      margin: 0;
      padding: 10px 20px 0;
      border-bottom: 1px solid #d8d8d8;
    }
    ul li {
      list-style: none;
      margin-left: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .materialName {
        width: 200px;
      }
    }
  }
  .ml30 {
    margin-left: 30px;
  }
  .mr50 {
    margin-right: 50px;
  }
  .mr30 {
    margin-right: 30px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .fontNormal {
    font-weight: normal;
  }
</style>