<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" label-width="160px"
        v-loading="dataLoading" style="width: 100%" element-loading-spinner="el-icon-loading">
        <el-row>
          <el-col :span="18">
            <el-form-item label="订单号" prop="productNumber" style="width: 75%">
              <el-input v-model="dataForm.productNumber" :placeholder="$t('product_number')" size="small"
                style="width: 100%" maxlength="30" :disabled="isDisabled" @change="calculateType"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="20">
                <el-form-item>
                  <span slot="label"><label style="color: #0d0908">*</label> L*a*b*</span>
                  <el-row>
                    <el-col :span="6">
                      <el-form-item prop="lAve">
                        <el-input v-model="dataForm.lAve" placeholder="L*" class="w150 mr10" size="small"
                          @input="getHex()" clearable maxlength="30" :disabled="isDisabled"
                          @change="searchColor"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="aAve">
                        <el-input v-model="dataForm.aAve" placeholder="a*" class="w150 mr10" size="small"
                          @input="getHex()" clearable maxlength="30" :disabled="isDisabled"
                          @change="searchColor"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="bAve">
                        <el-input v-model="dataForm.bAve" placeholder="b*" class="w150 mr10" size="small"
                          @input="getHex()" clearable maxlength="30" :disabled="isDisabled"
                          @change="searchColor"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <read-device @read="dataReceived" @readError="readError" />
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <div :class="isDisabled ? 'disabled-color-preview' : 'color-preview'
                  " v-if="dataForm.picUrl || dataForm.hex">
                  <div v-if="dataForm.hex" :style="{
                    backgroundColor: dataForm.hex,
                    height: '100%',
                    minWidth: '100%'
                  }"></div>
                  <img v-else :src="dataForm.picUrl" width="100%" height="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="border: 1px solid #c0c0c0" v-if="
            this.dataForm.lAve != '' &&
            this.dataForm.aAve != '' &&
            this.dataForm.bAve != ''
          ">

            <el-form-item label="" label-width="10px">
              <el-row>
                <el-col :span="12">
                  <el-popover placement="top-start" title="最小00色差" width="250" trigger="hover"
                    content="ColorX色彩库存与目标颜色色差最小颜色的CIE2000色差. 必须在2.5以内才可行"><span slot="reference"
                      style="cursor: help;">最小00色差</span></el-popover></el-col>
                <el-col :span="6">{{ this.smallestdE }}</el-col>
                <el-col :span="6"><i :class="this.smallestdE <= this.smallestdEMax &&
                  this.smallestdE >= 0
                  ? 'el-icon-success'
                  : 'el-icon-error'
                  " v-if="this.smallestdE != ''"></i></el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-popover placement="top-start" title="Flop指标" width="250" trigger="hover"
                    content="Flop指标是代表颜色在不同角度的变化有多大。Flop值越大就越带有金属感。 大于0.5的时候会影响成功率，超过1会判定成功率过低。"><span slot="reference"
                      style="cursor: help;">Flop</span></el-popover></el-col>
                <el-col :span="6">{{
                  this.flopValue || this.flopValue == 0 ? this.flopValue : '无'
                }}</el-col><i :class="this.flopValue > this.flopError
                    ? 'el-icon-error'
                    : this.flopValue > this.flopWarning
                      ? 'el-icon-warning'
                      : !this.flopValue && this.flopValue != 0
                        ? 'el-icon-question'
                        : 'el-icon-success'
                    " v-if="this.smallestdE != ''"></i>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-popover placement="top-start" title="色彩散度" width="250" trigger="hover"
                    content="在dE00小于3的范围内，ColorX色库有多少颜色。少于5个颜色会影响成功率。没有就判定成功率过低。"><span slot="reference"
                      style="cursor: help;">色彩散度</span></el-popover></el-col>
                <el-col :span="6">{{
                  this.within3quantity ? this.within3quantity : '无'
                }}</el-col><i :class="this.within3quantity < this.within3error
                    ? 'el-icon-error'
                    : this.within3quantity < this.within3warning
                      ? 'el-icon-warning'
                      : !this.within3quantity
                        ? 'el-icon-question'
                        : 'el-icon-success'
                    " v-if="this.smallestdE != ''"></i>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-popover placement="top-start" title="色彩均度" width="250" trigger="hover"
                    content="在成功率较高的情况，色彩库的颜色分布通常比较均匀。不均匀的代表已经接近可达到的颜色的边缘。 大于75会影响成功率，大于150会判定为成功率过低。"><span
                      slot="reference" style="cursor: help;">色彩均度</span></el-popover></el-col>
                <el-col :span="6">{{
                  this.largestSumdL25.imbalanceValue
                    ? this.largestSumdL25.imbalanceValue
                    : '无'
                }}
                  ({{ this.largestSumdL25.mostImbalancedColor }}) </el-col><i :class="this.largestSumdL25.imbalanceValue > this.sum25diffError
                    ? 'el-icon-error'
                    : this.largestSumdL25.imbalanceValue >
                      this.sum25diffWarning
                      ? 'el-icon-warning'
                      : !this.largestSumdL25.imbalanceValue
                        ? 'el-icon-question'
                        : 'el-icon-success'
                    " v-if="this.smallestdE != ''"></i>
              </el-row>
              <el-button type="text" @click="showFeasibilityDetails = !showFeasibilityDetails">查看颜色可行性详情</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="showFeasibilityDetails">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="最小00色差">
              {{ this.smallestdE }}
            </el-table-column>
            <el-table-column label="最小ab色差">
              {{ this.smallestdEab }}
            </el-table-column>
            <el-table-column label="dE<1点数">
              {{ this.within1quantity }}
            </el-table-column>
            <el-table-column label="dE<3点数">
              {{ this.within3quantity }}
            </el-table-column>
            <el-table-column label="dE<5点数">
              {{ this.within5quantity }}
            </el-table-column>
            <el-table-column prop="tenthDE" label="10th dE"> </el-table-column>
            <el-table-column prop="sumdL" label="10L和"> </el-table-column>
            <el-table-column prop="sumda" label="10a和"> </el-table-column>
            <el-table-column prop="sumdb" label="10b和"> </el-table-column>
            <el-table-column prop="twentyfifthDE" label="25th dE">
            </el-table-column>
            <el-table-column prop="sumdL25" label="25L和"> </el-table-column>
            <el-table-column prop="sumda25" label="25a和"> </el-table-column>
            <el-table-column prop="sumdb25" label="25b和"> </el-table-column>
            <el-table-column label="色相 (h)">{{ this.lch[2] }}
            </el-table-column>
            <el-table-column label="饱和度 (c)">{{ this.lch[1] }}
            </el-table-column>
          </el-table>
        </div>

        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">{{
                $t('view_detailed_color')
              }}</span>
              <div>
                <ul class="productLAB">
                  <li>
                    <span class="title">{{ $t('multi-angle') }} L*A*B*</span>
                  </li>
                  <li>
                    <span class="title">{{ $t('angle') }}</span>
                    <span v-for="x in productLAB.title" class="w100">{{
                      x
                    }}</span>
                  </li>
                  <li v-for="(x, index) in productLAB.data">
                    <span class="title">{{ x }}</span>
                    <el-input v-model="dataForm[`l${index + 1}`]" placeholder="L*" class="w100"
                      :disabled="true"></el-input>
                    <el-input v-model="dataForm[`a${index + 1}`]" placeholder="a*" class="w100"
                      :disabled="true"></el-input>
                    <el-input v-model="dataForm[`b${index + 1}`]" placeholder="b*" class="w100"
                      :disabled="true"></el-input>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <div class="ces-main">
          <search-form ref="form" size="mini" labelWidth="150px" :searchData="searchData" :searchForm="searchForm"
            :viewdataVisible="isDisabled"></search-form>
        </div>
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item :label="$t('gloss_value')" prop="glossValue">
              <el-input v-model="dataForm.glossValue" :placeholder="$t('gloss_value')" size="small" style="width: 100%"
                :disabled="isDisabled" @change="calculateType"></el-input>
            </el-form-item>
          </div>
          <div style="width: 30%; padding-left: 50px">
            <el-form-item label="与原样不一致" prop="glossChange" label-width="100px">
              <el-checkbox v-model="dataForm.glossChange" size="small" placeholder="" :disabled="isDisabled"
                @change="calculateType">
              </el-checkbox>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex">
          <div style="width: 50%">
            <el-form-item label="表面" prop="surface">
              <el-radio-group v-model="dataForm.surface" @change="calculateType">
                <el-radio-button v-for="item in surfaces" :key="item.value" :label="item.value">{{ item.label
                  }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <div style="width: 50%">
            <el-form-item prop="surfaceReference" label="表面参考号或描述">
              <el-input @change="calculateType" v-model="dataForm.surfaceReference" :disabled="isDisabled"
                :placeholder="$t('sample_panel_quantity')" type="textarea" autosize></el-input>
            </el-form-item>
          </div>
        </div>

        <div style="display: flex">
          <div style="width: 62.5%">
            <el-form-item label="行业" prop="industry">
              <el-radio-group v-model="dataForm.industry" @change="calculateType">
                <el-radio-button v-for="item in industries" :key="item.value" :label="item.value">{{ item.label
                  }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <div style="width: 37.5%">
            <el-form-item prop="otherIndustry" label-width="60px" label="其他" v-if="dataForm.industry == 'other'">
              <el-input @change="calculateType" v-model="dataForm.otherIndustry" :disabled="isDisabled"
                placeholder="其他"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item prop="series" label="系列" style="width: 30%">
          <el-input v-model="dataForm.series" :disabled="isDisabled" placeholder="系列"></el-input>
        </el-form-item>
        <el-form-item :label="$t('other_remarks')" prop="description">
          <div style="margin: 5px 0"></div>
          <el-input type="textarea" :rows="5" :placeholder="$t('other_remarks')" v-model="dataForm.description"
            maxlength="150" :disabled="isDisabled" show-word-limit>
          </el-input>
        </el-form-item>

        <el-form-item label="远程打样分类">
          <el-tag :type="canRemoteMatch ? 'success' : 'danger'"><i
              :class="canRemoteMatch ? 'el-icon-success' : 'el-icon-error'"></i>&nbsp;{{ this.remoteMatchCategory
            }}</el-tag>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <span slot="footer">
        <el-button @click="close()" size="small">{{ $t('cancel') }}</el-button>
        <!-- <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{ $t('confirm') -->
        <!-- }}</el-button> -->
      </span>
    </div>
  </sides-lip>
</template>

<script>
import {
  getFactoryBrand,
  info,
  getFactory1,
  getBrand,
  getHex,
  serachList
} from '@/api/modules/product'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import { selectLabelList } from '@/api/modules/productlabel'
import { addRemoyeProofong } from '@/api'
import ReadDevice from '../../components/readDevice.vue'
import { getRemoyeProofongDetails } from '@/api'
import { getSecondsAgoMessage } from '../../util/i18n/time'

export default {
  components: {
    SidesLip,
    SearchForm,
    ReadDevice
  },
  data() {
    return {
      showFeasibilityDetails: false,
      smallestdE: '',
      smallestdEab: '',
      flopValue: null,
      smallestdEMax: 2.5,
      within3warning: 5,
      within3error: 1,
      sum25diffWarning: 75,
      sum25diffError: 150,
      flopWarning: 0.5,
      flopError: 1,
      within1quantity: 0,
      within3quantity: 0,
      within5quantity: 0,
      lch: [0, 0, 0],
      hsl: [0, 0, 0],
      remoteMatchCategory: '信息不足',
      canRemoteMatch: false,
      industries: [
        {
          label: '未知',
          value: 'unknown'
        },
        {
          label: '建筑',
          value: 'architecture'
        },
        {
          label: '汽车/IT',
          value: 'autoit'
        },
        {
          label: '通用工业',
          value: 'general_industry'
        },
        {
          label: '其他',
          value: 'other'
        }
      ],
      surfaces: [
        {
          label: '平面',
          value: 'smooth'
        },
        {
          label: '砂纹',
          value: 'fine'
        }
      ],
      addMethod: undefined,
      updateMethod: undefined,
      searchForm: [],
      searchData: {},
      dialogVisible: false,
      biaoZhun: false,
      showLAB: false,
      loading: false,
      olabShow: false,
      visible: false,
      dataLoading: false,
      isBaseCheck: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      productLAB: {
        title: ['L*', 'a*', 'b*'],
        data: ['25°', '45°', '75°']
      },
      FactoryBrand: {},
      factoryId: '',
      isProductNoValid: true,
      isCopy: false,
      isDisabled: false,
      tableData: [
        {
          tenthDE: 0,
          sumdL: 0,
          sumda: 0,
          sumdb: 0,
          twentyfifthDE: 0,
          sumdL25: 0,
          sumda25: 0,
          sumdb25: 0
        }
      ],
      dataForm: {
        id: 0,
        qrUrl: '',
        gloss: '',
        glossValue: '',
        hex: '',
        description: '',
        industry: 'unknown',
        otherIndustry: '',
        series: '',
        surface: 'smooth',
        surfaceReference: '',
        glossChange: false,
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        delFlag: '',
        createBy: '',
        createDate: '',
        updateBy: '',
        updateDate: '',
        deliverDate: '',
        productNumber: '',
        modelSum: '',
        materialSum: ''
      },
      colorSeriesList: [],
      productCategoryList: [],
      otherPerformanceList: [],
      productSeriesList: [],
      productSurfaceList: [],
      productHierarchyList: [],
      factoryList: [],
      labelList: [],
      brandList: [],
      dataRule: {
        // productNo: [
        //   {
        //     required: true,
        //     message: this.$t('e706', { x: this.$t('product_number') }),
        //     trigger: 'blur'
        //   }
        // ],
        lAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('l_measurement') }),
            trigger: 'blur'
          }
        ],
        aAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('a_measurement') }),
            trigger: 'blur'
          }
        ],
        bAve: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('b_measurement') }),
            trigger: 'blur'
          }
        ],
        // productCategory: [
        //   {
        //     required: true,
        //     message: this.$t('e706', { x: this.$t('product_category') }),
        //     trigger: 'blur'
        //   }
        // ],
        surface: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('product_surface') }),
            trigger: 'blur'
          }
        ],
        industry: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        glossValue: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('gloss') }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    largestSumdL25() {
      var mostImbalancedColor = 'L'
      var imbalanceValue = this.tableData[0].sumdL25

      if (this.tableData[0].sumda25 > imbalanceValue) {
        mostImbalancedColor = 'a'
        imbalanceValue = this.tableData[0].sumda25
      }
      if (this.tableData[0].sumdb25 > imbalanceValue) {
        mostImbalancedColor = 'b'
        imbalanceValue = this.tableData[0].sumdb25
      }
      return {
        mostImbalancedColor: mostImbalancedColor,
        imbalanceValue: Math.round(imbalanceValue)
      }
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
    this.timerNum = 0
  },
  methods: {

    // 行业切换触发事件函数
    calculateType() {
      var category = ''
      var canRemote = false
      const data = this.dataForm
      console.log(data)

      // 特殊颜色
      if (this.smallestdE >= this.smallestdEMax) {
        category = '特殊颜色'
        canRemote = false
      } else if (data.industry == 'autoit') {
        category = '汽车/IT'
        canRemote = false
      }
      // 平面，不知道行业并没有参考号
      else if (
        data.surface == 'smooth' &&
        (data.industry == 'unknown' || data.industry == 'general_industry') &&
        data.surfaceReference.trim() == ''
      ) {
        category = '平面，无有效参考'
        canRemote = false
      }

      // 平面，有表面参考系列或产品号
      else if (
        data.surface == 'smooth' &&
        (data.series.trim() != '' || data.surfaceReference.trim() != '')
      ) {
        category = '平面，有表面参考信息'
        canRemote = true
      }
      // 平面，非汽车IT通用工业
      else if (
        data.surface == 'smooth' &&
        data.industry != 'unknown' &&
        data.industry != 'general_industry'
      ) {
        category = '平面，行业信息足够'
        canRemote = true
        // 平面，信息不足
      } else if (data.surface == 'smooth') {
        category = '平面，信息不足'
        canRemote = false
      }

      // 砂纹改光泽
      else if (data.surface == 'fine' && data.glossChange == true) {
        category = '砂纹改光泽'
        canRemote = false
      }
      // 砂纹，有表面参考，光泽不改
      else if (data.surface == 'fine' && data.surfaceReference.trim() != '') {
        category = '砂纹，有表面参考信息'
        canRemote = true
      }
      //  砂纹，非汽车IT通用工业
      else if (
        data.surface == 'fine' &&
        data.industry != 'unknown' &&
        data.industry != 'general_industry'
      ) {
        category = '砂纹，行业信息足够'
        canRemote = true
      } else if (data.surface == 'fine') {
        category = '砂纹，参考信息不足'
        canRemote = false
      }

      // 砂纹，建筑行业，黑色，深灰色，深棕，光泽低于7度
      //   else if (
      //     data.surface == 'fine' &&
      //     (data.series.trim() != ''  || data.surfaceReference.trim() != '')
      //   ) {
      //     category = '平面，行业信息足够'
      //     canRemote = 1
      //   }

      // 没有合适的标签
      else {
        category = '信息不足'
        canRemote = false
      }

      this.remoteMatchCategory = category
      this.canRemoteMatch = canRemote
    },
    calculateFlop(l25, l45, l75) {
      console.log()
      return (3.47 * (Number(l25) - Number(l75))) / Number(l45)
    },
    dataReceived(data) {
      // 收到了设备数据
      this.dataForm.l1 = data.l1
      this.dataForm.a1 = data.a1
      this.dataForm.b1 = data.b1
      this.dataForm.l2 = data.l2
      this.dataForm.a2 = data.a2
      this.dataForm.b2 = data.b2
      this.dataForm.l3 = data.l3
      this.dataForm.a3 = data.a3
      this.dataForm.b3 = data.b3
      this.dataForm.lAve = data.l2
      this.dataForm.aAve = data.a2
      this.dataForm.bAve = data.b2
      this.dataForm.hex = data.hex
      //在数据同步后赋值给dataForm时调用接口方法，把数据同步返回的数据给赋值到参数上

      var rgb = this.hexToRgba(data.hex)

      if (data.secondsAgo) {
        this.$message.success({
          message: this.$t('read_success', {
            x: getSecondsAgoMessage(data.secondsAgo)
          }),
          duration: 8000
        })
      }

      this.searchColor()
    },

    searchColor() {
      this.lch = this.LabToLch(
        this.dataForm.lAve,
        this.dataForm.aAve,
        this.dataForm.bAve
      )
      var params = {
        type: '2',
        searchData: { 1: '', 3: '', 5: '', 9: '' },
        page: 1,
        limit: 100,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.Lvaule,
        a2: this.Avaule,
        b2: this.Bvaule,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        e00Value: '',
        inputType: '2',
        e00Type: '1'
      }

      serachList(params).then(({ data }) => {
        this.tableData[0].sumdL = null
        this.tableData[0].sumda = null
        this.tableData[0].sumdb = null
        this.tableData[0].sumdL25 = null
        this.tableData[0].sumda25 = null
        this.tableData[0].sumdb25 = null
        this.tableData[0].tenthDE = null
        this.tableData[0].twentyfifthDE = null
        this.smallestdEab = null
        console.log(data)

        if (data && data.code === 0) {
          if (data.page.list.length > 0) {
            this.smallestdE = data.page.list[0].e00Value
            this.smallestdEab = this.getDEab(data.page.list[0])

            if (data.page.list.length > 9) {
              this.tableData[0].tenthDE = data.page.list[9].e00Value
              if (data.page.list.length > 24) {
                this.tableData[0].twentyfifthDE = data.page.list[24].e00Value
              } else {
                this.tableData[0].twentyfifthDE = 99
              }
            } else {
              this.tableData[0].tenthDE = 99
            }
          } else {
            this.smallestdE = 10
          }
          var index = 0

          this.within1quantity = 0
          this.within3quantity = 0
          this.within5quantity = 0
          data.page.list.forEach((item) => {
            if (index < 10) {
              this.tableData[0].sumdL += item.del
              this.tableData[0].sumda += item.dea
              this.tableData[0].sumdb += item.deb
            }
            if (index < 25) {
              this.tableData[0].sumdL25 += item.del
              this.tableData[0].sumda25 += item.dea
              this.tableData[0].sumdb25 += item.deb
            }
            index++

            if (this.getDEab(item) < this.smallestdEab) {
              this.smallestdEab = this.getDEab(item)
            }

            if (Number(item.e00Value) < 1) {
              this.within1quantity++
            }
            if (Number(item.e00Value) < 3) {
              this.within3quantity++
            }
            if (Number(item.e00Value) < 5) {
              this.within5quantity++
            }
          })
        }
        this.tableData[0].sumdL = this.roundNumber(this.tableData[0].sumdL)
        this.tableData[0].sumda = this.roundNumber(this.tableData[0].sumda)
        this.tableData[0].sumdb = this.roundNumber(this.tableData[0].sumdb)
        this.tableData[0].sumdL25 = this.roundNumber(this.tableData[0].sumdL25)
        this.tableData[0].sumda25 = this.roundNumber(this.tableData[0].sumda25)
        this.tableData[0].sumdb25 = this.roundNumber(this.tableData[0].sumdb25)
        this.smallestdEab = this.roundNumber(this.smallestdEab)
        if (this.dataForm.l1 && this.dataForm.l2 && this.dataForm.l3) {
          this.flopValue = this.roundNumber(
            this.calculateFlop(
              this.dataForm.l1,
              this.dataForm.l2,
              this.dataForm.l3
            )
          )
        }

        console.log(this.tableData[0].tenthDE, '10th dE')
        console.log(this.tableData[0].sumdL, 'sum dL')
        console.log(this.tableData[0].sumda, 'sum da')
        console.log(this.tableData[0].sumdb, 'sum db')
      })

      this.$forceUpdate()
    },

    roundNumber(num) {
      return Math.round(num * 100) / 100
    },

    getDEab(item) {
      const dL = item.del
      const da = item.dea
      const db = item.deb

      return Math.sqrt(Math.pow(dL, 2) + Math.pow(da, 2) + Math.pow(db, 2))
    },

    readError(error) {
      this.dataForm.l1 = ''
      this.dataForm.a1 = ''
      this.dataForm.b1 = ''
      this.dataForm.l2 = ''
      this.dataForm.a2 = ''
      this.dataForm.b2 = ''
      this.dataForm.l3 = ''
      this.dataForm.a3 = ''
      this.dataForm.b3 = ''
    },
    getHex() {
      var params = {
        lab:
          this.dataForm.lAve +
          ',' +
          this.dataForm.aAve +
          ',' +
          this.dataForm.bAve
      }
      getHex(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.hex = data.hex
        }
      })
    },
    handleChange(val) {
      console.log(val)
    },
    //LAB值按钮隐藏显示
    isShowLAB() {
      this.showLAB = !this.showLAB
    },
    isBiaoZhun() {
      this.biaoZhun = !this.biaoZhun
    },

    init(id, state, isDisabled, isCopy, addUpdateMethods) {
      this.addMethod = addUpdateMethods[0]
      this.updateMethod = addUpdateMethods[1]
      this.getDataDetails(id + '')
      //进来就调用获取所有下拉列表
      this.isCopy = isCopy
      this.isDisabled = isDisabled
      this.dataForm.state = state
      this.dataForm.id = id || 0
      var titleMessage = this.$t('demand_list_details')
      this.$refs.SidesLip.init(titleMessage, '900px', 'block')
      this.getFactoryBrandList()
      this.getFactoryList()
      this.getBrandList()
      this.getLabelList()
      this.loading = false
      this.visible = true
      this.$nextTick(() => {
        //清除缓存数据
        this.$refs['dataForm'].resetFields()
        this.dataForm.lAve = ''
        this.dataForm.aAve = ''
        this.dataForm.bAve = ''
        this.dataForm.l1 = ''
        this.dataForm.a1 = ''
        this.dataForm.b1 = ''
        this.dataForm.l2 = ''
        this.dataForm.a2 = ''
        this.dataForm.b2 = ''
        this.dataForm.l3 = ''
        this.dataForm.a3 = ''
        this.dataForm.b3 = ''
        this.dataForm.hex = ''
        this.dataForm.productImages = []
        this.isBaseCheck = false
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.productOrder = data.product.productOrder
              this.dataForm.productNo = data.product.productNo
              this.dataForm.productName = data.product.productName
              this.dataForm.productImages = data.product.productImages
              if (isCopy) {
                // 如果在复制，必须把id去掉以免图片的关联产品被更新
                if (this.dataForm.productImages.length > 0) {
                  this.dataForm.productImages.forEach((image) => {
                    image.id = ''
                  })
                }
                this.dataForm.productNo += '-Copy'
              }
              this.dataForm.picUrl = data.product.picUrl
              this.dataForm.qrUrl = data.product.qrUrl
              this.dataForm.ralColorNo = data.product.ralColorNo
              this.dataForm.gloss = data.product.gloss
              this.dataForm.glossValue = data.product.glossValue
              this.dataForm.hex = data.product.hex
              this.dataForm.surface = data.product.surface
              this.dataForm.colorSeries = data.product.colorSeries
              this.dataForm.productSeries = data.product.productSeries
              this.dataForm.productPerformance = data.product.productPerformance
              this.dataForm.otherPerformance = data.product.otherPerformance
              this.dataForm.productCategory = data.product.productCategory
              this.dataForm.productHierarchy = data.product.productHierarchy
              //获取
              this.dataForm.factoryId = data.product.factoryId
              this.dataForm.brandId = data.product.brandId
              this.dataForm.description = data.product.description
              this.dataForm.l1 = data.product.l1
              this.dataForm.a1 = data.product.a1
              this.dataForm.b1 = data.product.b1
              this.dataForm.l2 = data.product.l2
              this.dataForm.a2 = data.product.a2
              this.dataForm.b2 = data.product.b2
              this.dataForm.l3 = data.product.l3
              this.dataForm.a3 = data.product.a3
              this.dataForm.b3 = data.product.b3
              this.dataForm.lAve = data.product.lAve
              this.dataForm.aAve = data.product.aAve
              this.dataForm.bAve = data.product.bAve
              if (null != data.productLab) {
                this.dataForm.lo = data.productLab.l
                this.dataForm.ao = data.productLab.a
                this.dataForm.bo = data.productLab.b
                this.olabShow = true
              } else {
                this.olabShow = false
              }
              this.dataForm.test = data.product.test
              this.dataForm.state = data.product.state
              this.dataForm.isBase = data.product.isBase
              if (data.product.isBase == 1) {
                this.isBaseCheck = true
              } else {
                this.isBaseCheck = false
              }
              // 获取属性
              this.searchData = data.resultMap
              if (this.dataForm.productNo && !this.dataForm.productMainNo) {
                this.dataForm.productMainNo = this.dataForm.productNo
              }
              this.setFactoryId()
            }
          })
        }
      })
    },
    async getDataDetails(id) {
      this.dataListLoading = false
      const res = await getRemoyeProofongDetails({ id: id })
      this.dataForm = Object.assign(this.dataForm, res.data.details)
      this.searchData = this.dataForm.searchData
    },
    close() {
      this.performanceList = []
      //清除缓存数据
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },
    //获取工厂和品牌
    getFactoryBrandList() {
      let params = {}
      getFactoryBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.FactoryBrand = data.factoryBrand
        } else {
          this.FactoryBrand = {}
        }
      })
    },
    //获取工厂列表
    getFactoryList() {
      let params = {}
      getFactory1(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.list
          if (
            data.factoryId != null &&
            data.factoryId != undefined &&
            this.factoryList.length > 0
          ) {
            this.dataForm.makeFactory = this.factoryList[0].factoryName
            this.setFactoryId()
          }
        } else {
          this.factoryList = []
        }
      })
    },
    //获取品牌列表
    getBrandList() {
      let params = {}
      getBrand(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
      })
    },
    setFactoryId() {
      this.factoryId = this.factoryList.find(
        (factory) => factory.factoryName == this.dataForm.makeFactory
      ).id
    },
    updateBaseCheck() {
      this.dataForm.isBase = this.isBaseCheck ? '1' : '0'
    },
    productNoChanged(data) {
      this.getResultMap()
      this.isProductNoValid = data.isProductNoValid
      this.dataForm.productNo = data.productNo
      this.dataForm.productMainNo = data.productMainNo
      this.dataForm.productComponentNo = data.productComponentNo
      this.dataForm.productVersionNo = data.productVersionNo
    },
    //hex转rgb
    hexToRgba(hex) {
      return [
        parseInt('0x' + hex.slice(1, 3)),
        parseInt('0x' + hex.slice(3, 5)),
        parseInt('0x' + hex.slice(5, 7))
      ]
    },
    rad2deg(radians) {
      return radians * (180 / Math.PI)
    },
    LabToLch(l, a, b) {
      const L = Number(l)
      const A = Number(a)
      const B = Number(b)

      var c = Math.sqrt(Math.pow(A, 2) + Math.pow(B, 2))
      var h = this.rad2deg(Math.atan2(B, A))
      if (h < 0) h += 360

      return [Math.round(L), Math.round(c), Math.round(h)]
    }
  }
}
</script>
<style lang="scss" scoped>
.color-preview {
  height: 80px;
  width: 80px;
  margin-top: 10%;
}

.disabled-color-preview {
  height: 60px;
  width: 60px;
  margin-top: 0px;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: -50px;

  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;

    .title {
      display: inline-block;
      width: 160px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }

    .ptitle {
      display: inline-block;
      width: 160px;
      text-align: center;
      font-size: 10px;
    }
  }
}

.w100 {
  width: 100px;
}

.w150 {
  width: 115px;
}

.mr10 {
  margin-right: 5px;
}

.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}

.el-form-item__label {
  width: auto;
}
</style>