// 前版本，不维护了
// 目前用 modelViewerScreen

<template>
  <div class="spacer">
    <div class="noData" v-if="
      (viewerId != null && viewerId != '' && !currentViewer) ||
      brandId == null
    ">
      无数据，请联系管理员
      <div style="font-size: 20px">
        错误：{{ !brandId ? '无品牌信息' : '模型项不存在或者已删除' }}
      </div>
    </div>
    <div v-else>
      <img v-if="viewerId == '' || viewerId == null || !currentViewer" class="brandLogoSelector" :src="brandLogoURL" />
      <img src="../../assets/color3D_logo.png" height="30" width="50"
        :class="mobileLayout && isViewer ? 'yituLogoMobile' : 'yituLogo'" />
      <div class="modelSelector" v-if="viewerId == '' || viewerId == null || !currentViewer">
        <!-- <div class="industrySelectorArea">
          <div class="industrySelector">
            <div
              v-for="(industry, index) in industries"
              v-bind:key="index"
              @click="selectedIndustry = industry.value"
              :class="
                industry.value == selectedIndustry
                  ? 'selectedIndustry'
                  : 'unselectedIndustry'
              "
            >
              {{ industry.label }}
            </div>
          </div>
        </div> -->

        <div :class="!mobileLayout ? 'modelSelectionArea' : 'modelSelectionAreaMobile'
          ">
          <el-card class="modelSelectCard" v-for="(model, index) in filteredViewers" v-bind:key="index">
            <div @click="showViewer(model)">
              <div class="modelTitle">{{ model.viewerTitle }}</div>
              <img class="modelSelectImage" :src="model.picUrl" />
            </div>
          </el-card>
          <div class="empty" v-if="filteredViewers.length == 0">
            暂无此类模型
          </div>
        </div>
      </div>

      <div class="modelViewer" v-else>
        <div class="mobileTopMenu" v-if="mobileLayout">
          <div @click="backToMenu()" v-if="type != 'preview'" class="titleText">
            <i class="el-icon-d-arrow-left"></i>
          </div>
          <span class="titleText">{{ currentViewer.viewerTitle }}</span>
          <div style="font-size: 30px" @click="sharingWindowOpen = true">
            <i class="el-icon-share"></i>
          </div>

          <!-- <img
            src="../../assets/download.png"
            alt="DOWNLOAD"
            @click="downloadImage"
            height="35"
            width="35"
          /> -->
        </div>
        <div class="topMenu" v-else>
          <div class="backButton" @click="backToMenu()" v-if="type != 'preview'">
            <div class="backArrow"><i class="el-icon-d-arrow-left"></i></div>
            <div class="backButtonText">选择其他模型</div>
          </div>
          <div class="rightMenu">
            <div class="modelTitle">
              <span class="titleText">{{ currentViewer.viewerTitle }}</span>
            </div>
            <img src="../../assets/download.png" alt="DOWNLOAD" class="downloadButton" @click="downloadImage" />
          </div>
        </div>

        <div v-if="sharingWindowOpen" class="sharingWindowContentBlocker"></div>
        <div v-if="sharingWindowOpen" class="sharingWindow">
          <div class="sharingTitle">
            <el-button icon="el-icon-close" class="sharingCloseButton" type="text"
              @click="sharingWindowOpen = false"></el-button>
            分享本设置

            <vue-qr :text="link" :size="150" />
            <!-- 微信不支持复制链接，暂时放弃
              <el-button
              @click="copyLink()"
              size="small"
              type="text"
              class="sharingCopyLink"
              style="display: block; margin-left: 45px; margin-top: -10px"
              >{{ $t('copy_link') }}</el-button
            > -->
          </div>
        </div>

        <iframe ref="emersyaIframe" :src="viewerUrl" allow="camera; gyroscope; accelerometer; magnetometer;"
          webkitallowfullscreen="" allowfullscreen="" @load="initFrame" :style="{ height: iframeHeight }"></iframe>
        <div v-if="!hasInitiatedIframe" :style="{ height: iframeHeight }" class="iframeLoadingScreen">
          <div>Loading...</div>
        </div>
        <div class="productInfo" v-if="!mobileLayout && brandId == 'dcf7bb23ebeb4509ac6905c6d29b24e6'">
          <div class="productInfoMinimized" @click="productInfoExpanded = true" v-if="!productInfoExpanded">
            <div style="font-weight: bold" class="productInfoKey">产品号</div>
            <div style="width: 200px; text-align: center">
              {{ this.currentProductNo }}
            </div>
            <i class="el-icon-arrow-up"></i>
          </div>
          <div class="productInfoBox" v-else>
            <div class="productInfoHeader" @click="productInfoExpanded = false">
              产品信息<i class="el-icon-arrow-down"></i>
            </div>
            <el-row class="productInfo">
              <el-col :span="9" class="productInfoKey">产品号</el-col>
              <el-col :span="15" class="productInfoValue">{{
                this.currentProductNo
                }}</el-col>
            </el-row>
            <el-row class="productInfo">
              <el-col :span="9" class="productInfoKey">分类</el-col>
              <el-col :span="15" class="productInfoValue">{{
                this.currentProductInfo.productCategory
                }}</el-col>
            </el-row>
            <el-row class="productInfo">
              <el-col :span="9" class="productInfoKey">表面</el-col>
              <el-col :span="15" class="productInfoValue">{{
                this.currentProductInfo.surface
                }}</el-col>
            </el-row>
            <el-row class="productInfo">
              <el-col :span="9" class="productInfoKey">光泽</el-col>
              <el-col :span="15" class="productInfoValue">{{
                this.currentProductInfo.gloss
                }}</el-col>
            </el-row>
            <el-row class="productInfo">
              <el-col :span="9" class="productInfoKey">性能标准</el-col>
              <el-col :span="15" class="productInfoValue">{{
                this.currentProductInfo.productPerformance
                }}</el-col>
            </el-row>

            <div class="colorPreview" :style="{ backgroundColor: currentProductInfo.hex }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  getBrandLogoApi,
  getViewerInfoApi,
  getProductInfoApi,
  addClick
} from '@/api/modules/modelViewer'
import VueQr from 'vue-qr'
export default {
  data() {
    return {
      mobileLayout: false,
      presetConfigurationName: '',
      presetGroupName: '',
      emersyaViewerUrl: 'https://emersya.com/designGroupsConfigurator/',
      brandId: '',
      previousViewerId: '',
      viewerId: '',
      type: '',
      industries: [
        { value: '0', label: '交通运输' },
        { value: '1', label: '建筑建材' },
        { value: '2', label: '通用工业' },
        { value: '3', label: '绿色生活' },
        { value: '4', label: '其他' }
      ],
      selectedIndustry: '0',
      iframeHeight: 0,
      hasInitiatedIframe: false,
      viewerActive: false,
      previousMaterialGroupInfo: null,
      brandLogoURL: '',
      viewers: [],
      currentProductNo: 'Loading...',
      currentProductInfo: {
        productCategory: '',
        surface: '',
        gloss: '',
        productPerformance: '',
        hex: ''
      },
      productInfoExpanded: false,
      sharingWindowOpen: false
    }
  },
  components: {
    VueQr
  },
  computed: {
    filteredViewers() {
      if (this.viewers.length > 0) {
        // return this.viewers.filter(
        //   (viewer) =>
        //     viewer.industry == this.selectedIndustry && viewer.status == '1'
        // )
        return this.viewers
          .filter((viewer) => viewer.status == '1')
          .sort(function (a, b) {
            let x = a.industry.toLowerCase()
            let y = b.industry.toLowerCase()
            if (x < y) {
              return -1
            }
            if (x > y) {
              return 1
            }
            return 0
          })
      } else {
        return []
      }
    },
    currentViewer() {
      return this.viewers.filter(
        (viewer) => viewer.viewerId == this.viewerId
      )[0]
    },
    isViewer() {
      return !(
        this.viewerId == '' ||
        this.viewerId == null ||
        !this.currentViewer
      )
    },
    viewerUrl() {
      if (
        this.currentViewer.length > 0 &&
        this.currentViewer.emersyaId == 'GF3I5WQ2IG'
      )
        return 'https://tiger.emersya.com/designGroupsConfigurator/GF3I5WQ2IG'
      return this.emersyaViewerUrl + this.currentViewer.emersyaId
    },
    topBarHeight() {
      return this.mobileLayout ? '36' : '0'
    },
    link() {
      const currentURL = window.location.href

      const link =
        currentURL.replace(this.$route.fullPath, '') +
        `/modelViewerScreen?brandId=${this.brandId}&viewerId=${this.viewerId
        }&configurationName=${encodeURI(
          this.presetConfigurationName
        )}&groupName=${encodeURI(this.presetGroupName)}`
      console.log(link)
      return link
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.brandId = this.$route.query.brandId
    this.viewerId = this.$route.query.viewerId
    this.type = this.$route.query.type

    this.presetConfigurationName = this.$route.query.configurationName
    this.presetGroupName = this.$route.query.groupName
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    document.title = "在线渲染"
    this.getBrandLogo()
    this.getViewerInfo()
  },
  methods: {
    handleResize() {
      this.iframeHeight = window.innerHeight - this.topBarHeight + 'px'
      this.mobileLayout = window.innerWidth <= 818
    },
    getBrandLogo() {
      this.$nextTick(() => {
        getBrandLogoApi({ brandId: this.brandId }).then(({ data }) => {
          if (data && data.code === 0) {
            this.brandLogoURL = data.logoPath
          }
        })
      })
    },
    getViewerInfo() {
      this.$nextTick(() => {
        getViewerInfoApi({ brandId: this.brandId }).then(({ data }) => {
          if (data && data.code === 0) {
            this.viewers = data.viewers
          }
        })
      })
    },
    addClick(viewerId) {
      this.$nextTick(() => {
        addClick({ viewerId: viewerId }).then(({ data }) => {
          if (data && data.code === 0) {
            // Good
          } else {
            console.log('Error recording click')
          }
        })
      })
    },
    showViewer(model) {
      this.previousViewerId = this.viewerId
      this.viewerId = model.viewerId
    },
    backToMenu() {
      this.viewerId = ''
      this.currentProductNo = 'Loading...'
      this.currentProductInfo = {
        productCategory: '',
        surface: '',
        gloss: '',
        productPerformance: '',
        hex: ''
      }
      this.hasInitiatedIframe = false
    },
    initFrame() {
      console.log('INITFRAME')


      this.addClick(this.viewerId)

      // 没有导航兰的话，要改啊
      this.iframeHeight = window.innerHeight - this.topBarHeight + 'px'

      window.removeEventListener('message', this.viewerEventListener, false)
      this.$refs.emersyaIframe.contentWindow.postMessage(
        {
          action: 'registerCallback'
        },
        '*'
      )
      window.addEventListener('message', this.viewerEventListener, false)
      this.$refs.emersyaIframe.contentWindow.postMessage(
        {
          action: 'getViewerState'
        },
        '*'
      )
      this.hasInitiatedIframe = true
    },
    viewerEventListener(event) {
      if (event.data && event.data.action == 'onStateChange') {
        if (
          event.data.state.viewerState == 'loaded' ||
          event.data.state.viewerState == 'fallbackloaded'
        ) {
          console.log('ONSTATECHANGE')
          this.viewerActive = true
          this.launchGetMaterialGroups()
        }
      }
      if (event.data && event.data.action == 'onCurrentMaterialsGroupGet') {
        this.processMaterialGroups(event.data.groupsConfiguration)
      }
      if (event.data && event.data.action == 'onScreenshot') {
        this.processScreenshot(event.data.screenshot)
      }

      if (event.data && event.data.action == 'onError') {
        console.log(event)
      }
    },
    launchGetMaterialGroups() {
      var iframeContentWindow = this.$refs.emersyaIframe.contentWindow
      setInterval(function () {
        iframeContentWindow.postMessage(
          {
            action: 'getCurrentMaterialsGroup'
          },
          '*'
        )
      }, 200)

      if (this.presetConfigurationName && this.presetGroupName) {
        console.log(this.presetConfigurationName)
        console.log(this.presetGroupName)

        this.$refs.emersyaIframe.contentWindow.postMessage(
          {
            action: 'setMaterialsGroup',
            configurationName: this.presetConfigurationName,
            groupName: this.presetGroupName
          },
          '*'
        )
      }
    },
    getMaterialGroups() {
      this.$refs.emersyaIframe.contentWindow.postMessage(
        {
          action: 'getCurrentMaterialsGroup'
        },
        '*'
      )
    },
    processMaterialGroups(groups) {
      // console.log(groups)
      if (groups && groups.length) {
        if (
          this.previousMaterialGroupInfo &&
          this.previousMaterialGroupInfo.length
        ) {
          groups.forEach((currentElement) => {
            this.previousMaterialGroupInfo.forEach((prevElement) => {
              if (
                (currentElement.groupName == prevElement.groupName &&
                  currentElement.configurationName !=
                  prevElement.configurationName) ||
                (this.viewerId != this.previousViewerId && this.viewerId != '')
              ) {
                // 有材质变更

                // 设置preset
                this.presetConfigurationName = currentElement.configurationName
                this.presetGroupName = currentElement.groupName

                let extractedNo = this.extractFirst8Digits(
                  currentElement.configurationName
                )
                if (extractedNo.length >= 8) {
                  this.currentProductNo = extractedNo
                  this.getProductInfo()
                  // 获取产品信息
                  this.previousViewerId = this.viewerId
                }
              }
            })
          })
        } else {
          for (let i = 0; i < groups.length; i++) {
            // 刚开始，设置默认值
            if (this.presetConfigurationName == '') {
              this.presetConfigurationName = currentElement.configurationName
              this.presetGroupName = currentElement.groupName
            }

            let extractedNo = this.extractFirst8Digits(
              groups[i].configurationName
            )
            if (extractedNo >= 8) {
              this.currentProductNo = extractedNo
              this.getProductInfo()
              break
            }
          }
        }
      }

      this.previousMaterialGroupInfo = groups
    },
    extractFirst8Digits(str) {
      let count = 0
      let result = ''
      for (let i = 0; i < str.length; i++) {
        if (!isNaN(parseInt(str[i]))) {
          result += str[i]
          count++
          if (count === 8) {
            break
          }
        }
      }
      return result
    },
    getProductInfo() {
      this.$nextTick(() => {
        getProductInfoApi({
          productNo: this.currentProductNo,
          brandId: this.brandId
        }).then(({ data }) => {
          if (data && data.code == 'C0') {
            this.currentProductInfo = data.viewer
          }
        })
      })
    },
    downloadImage() {
      this.$refs.emersyaIframe.contentWindow.postMessage(
        {
          action: 'getScreenshot',
          width: 512,
          height: 512,
          transparentBackground: false
        },
        '*'
      )
    },
    processScreenshot(screenshot) {
      const a = document.createElement('a')
      a.href = `data:${screenshot}`
      a.download = `${this.currentViewer.viewerTitle}.png`
      document.body.appendChild(a)
      a.click()
    },
    copyLink() {
      navigator.clipboard.writeText(this.link)
      this.$message({
        message: this.$t('copy_success'),
        type: 'success',
        duration: 1500
      })
    }
  }
}
</script>

<style lang="scss">
.spacer {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 1px;

  .noData {
    padding: 50px;
    font-size: 40px;
    text-align: center;
  }

  .brandLogoSelector {
    width: 125px;
    height: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-top: 4%;
  }

  .yituLogo {
    position: fixed;
    left: 50px;
    bottom: 60px;
    z-index: 10;
  }

  .yituLogoMobile {
    position: fixed;
    left: 10px;
    bottom: 110px;
    z-index: 10;
  }

  .industrySelectorArea {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    cursor: pointer;

    .industrySelector {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      box-shadow: 4px 4px 10px #c0c0c0;
      border-radius: 15px;

      div {
        padding: 10px 20px 10px 20px;
        border: 1px solid #c0c0c0;
      }

      div:nth-child(-n + 1) {
        border-radius: 15px 0px 0px 15px;
      }

      div:last-child {
        border-radius: 0px 15px 15px 0px;
      }
    }
  }

  .selectedIndustry {
    background-color: #97c11c;
    color: #ffffff;
  }

  .unselectedIndustry {
    color: #000000;
    background-color: white;
  }

  .modelSelectionArea {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;

    .modelSelectCard {
      height: 190px;
      width: 180px;
      margin: 10px;
      border-radius: 8px;
      cursor: pointer;
      opacity: 1;

      .modelTitle {
        margin-bottom: 8px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }

      .modelSelectImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 120px;
        height: 120px;
      }
    }

    .modelSelectCard:hover {
      opacity: 0.7;
    }
  }

  .modelSelectionAreaMobile {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;

    .el-card__body {
      padding: 5% !important;
    }

    .modelSelectCard {
      width: 30%;
      margin: 1.5%;
      border-radius: 5px;
      cursor: pointer;
      opacity: 1;

      .modelTitle {
        margin-bottom: 3px;
        font-size: 4vw;
        text-align: center;
        font-weight: bold;
        width: 100%;
      }

      .modelSelectImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      }
    }

    .modelSelectCard:hover {
      opacity: 0.7;
    }
  }

  .empty {
    height: 200px;
    padding: 100px;
    display: block;
    margin: auto;
    text-align: center;
    color: #7d7d7d;
  }

  .modelViewer {
    position: relative;
  }

  .mobileTopMenu {
    display: flex;
    justify-content: space-between;
    height: 35px;
    width: 100%;
    color: #97c11c;
    font-weight: bold;
    z-index: 11;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    .titleText {
      font-size: 22px;
      margin: 2px;
      padding: 4px;
      background-color: #ffffff;
      border-radius: 5px;
    }
  }

  .topMenu {
    .backButton {
      background-color: #ffffff;
      border: 1px solid #97c11c;
      font-weight: bold;
      border-radius: 8px;
      color: #97c11c;
      font-size: 20px;
      margin: 20px;
      position: absolute;
      padding: 0px;
      cursor: pointer;
      height: 56px;
      z-index: 11;

      .backArrow {
        background-color: #dedede;
        border-radius: 8px 0px 0px 8px;
        height: 100%;
        padding: 15px 3px 15px 3px;
        display: inline-block;
      }

      .backButtonText {
        display: inline-block;
        padding: 15px;
        height: 100%;
      }
    }

    .backButton:hover {
      background-color: #97c11c;
      color: #ffffff;

      .backArrow {
        background-color: #799a17;
      }
    }

    .rightMenu {
      width: 350px;
      right: 350px;
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 10px;

      .modelTitle {
        font-size: 20px;
        width: 80%;
        margin: 20px;
        text-align: right;
        color: #799a17;
        font-weight: bold;
      }

      .titleText {
        padding: 5px;
        background-color: #ffffff;
        border-radius: 5px;
      }

      .downloadButton {
        margin-right: 15px;
        margin-top: 5px;
        height: 60px;
        width: 60px;
        cursor: pointer;
      }
    }
  }

  .sharingWindowContentBlocker {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 12;
    opacity: 0.5;
    top: 0;
    left: 0;
  }

  .sharingWindow {
    z-index: 13;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 176px;
    transform: translate(-50%, -80%);
    display: block;
    background-color: #ffffff;
    opacity: 1;
    border: 1px solid #97c11c;
    border-radius: 15px;
    padding: 10px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    .sharingTitle {
      position: relative;
      text-align: center;
      padding-top: 5px;
      color: #799a17;
    }

    .sharingCloseButton {
      position: absolute;
      right: 5px;
      top: 5px;
      margin: 0px;
      padding: 0px;
    }
  }

  iframe {
    border: none;
    display: block;
    width: 100%;
  }

  .iframeLoadingScreen {
    border: none;
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    opacity: 0.5;
    padding-top: 10%;
    cursor: wait;

    div {
      font-size: 4vw;
      display: block;
      position: absolute;
      left: 40%;
      top: 20%;
      transform: translateX(-50%);
    }
  }

  .productInfoMinimized {
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 300px;
    padding: 15px;
    background-color: #97c11c;
    color: #ffffff;
    display: flex;
    border-radius: 8px;
    cursor: pointer;

    .el-icon-arrow-up {
      position: absolute;
      font-size: 20px;
      right: 20px;
    }
  }

  .productInfoBox {
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 300px;
    background-color: #ffffff;
    padding: 15px;
    border-right: 1px solid #97c11c;
    border-left: 1px solid #97c11c;
    border-bottom: 1px solid #97c11c;
    display: block;
  }

  .productInfoHeader {
    background-color: #97c11c;
    color: #ffffff;
    font-weight: bold;
    border-radius: 8px;
    font-size: 20px;
    text-align: center;
    padding: 10px 0px 10px 0px;
    display: block;
    width: calc(100% + 2px);
    position: absolute;
    bottom: 265px;
    right: -1px;
    cursor: pointer;

    .el-icon-arrow-down {
      position: absolute;
      font-size: 20px;
      right: 20px;
    }
  }

  .productInfoValue {
    text-align: right;
    font-size: 20px;
  }

  .productInfoKey {
    text-align: left;
    font-size: 18px;
  }

  .productInfo {
    margin: 10px;
  }

  .colorPreview {
    width: 100%;
    height: 70px;
    display: block;
    border: 1px dashed #97c11c;
  }
}
</style>