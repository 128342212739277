
<template>
  <!-- 设备引导 -->
  <el-dialog
    v-if="dialogVisible"
    :title="$t('device_user_guide')"
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <el-row :gutter="10">
      <!-- <el-col :span="13">
        <video src="@/assets/1.mp4" width="100%" controls="controls">
          {{ $t('video_unsupported') }}
        </video>
      </el-col> -->

      <el-col :span="24">
        <div style="word-break: normal">
          <div class="connect-device">
            <el-button
              @click="changeConnectionType"
              class="tab-button"
              :type="isUsb ? 'primary' : ''"
            >
              {{ $t('usb_device') }}
              <img
                v-if="!isUsb"
                src="../assets/usb_dark.png"
                height="34"
                width="34"
              />
              <img
                v-if="isUsb"
                src="../assets/usb_white.png"
                height="34"
                width="34"
              />
            </el-button>
            <el-button
              @click="changeConnectionType"
              class="tab-button"
              :type="!isUsb ? 'primary' : ''"
            >
              {{ $t('wifi_device') }}
              <img
                v-if="isUsb"
                src="../assets/wireless_connection_dark.png"
                height="40"
                width="40"
                style="margin: -3px"
              />
              <img
                v-if="!isUsb"
                src="../assets/wireless_connection_white.png"
                height="40"
                width="40"
                style="margin: -3px"
              />
            </el-button>
            <el-row :gutter="50">
              <el-col :span="24">
                <div style="font-weight: bold; margin: 40px 10px 10px 10px">
                  {{ $t('operation_guide') }}
                </div>
                <p v-if="isUsb" v-html="$t('operation_guide_usb_details')" />
                <p v-else v-html="$t('operation_guide_wifi_details')" />
                <el-button @click="showVideo" type="text" v-if="isUsb">{{
                  $t('view_device_video')
                }}</el-button>
                <el-form
                  :inline="true"
                  :model="formInline"
                  class="demo-form-inline"
                  v-if="isUsb"
                >
                  <el-form-item :label="$t('device_name')">
                    <el-select
                      v-model="formInline.id"
                      :placeholder="$t('select_usb_device')"
                      @change="handleChange"
                      :disabled="isDisabled"
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      size="small"
                      type="primary"
                      @click="checkTest"
                      v-if="deviceStatus == '0'"
                      :loading="loading"
                      >{{ $t('test_connection') }}</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      @click="handleChange"
                      v-if="deviceStatus == '2'"
                      :loading="loading"
                      >{{ $t('refresh_device') }}</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-form
                  :inline="true"
                  :model="formInline"
                  class="demo-form-inline"
                  v-else
                >
                  <el-form-item :label="$t('device_name')">
                    <el-select
                      v-model="formInline.id"
                      :placeholder="$t('select_wifi_device')"
                      @change="handleChange"
                      :disabled="isDisabled"
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-button
                    class="btn"
                    v-if="!isUsb && deviceStatus != 1"
                    :disabled="!isUsb && deviceStatus != 99"
                    @click="confirmSelectDevice"
                    >{{ $t('confirm') }}</el-button
                  >
                  <el-button
                    v-if="!isUsb && deviceStatus == 1"
                    class="btn"
                    @click="breakConnectDevice"
                    >{{ $t('clear') }}</el-button
                  >
                </el-form>
              </el-col>
            </el-row>
            <el-row :gutter="50" v-if="isUsb">
              <el-col :span="24" class="deviceStatus">
                <span>{{ $t('device_status') }}</span>
                <span v-if="deviceStatus === '0'">
                  {{ $t('not_connected') }}
                </span>
                <span v-if="deviceStatus === '1'" style="color: green">
                  {{ $t('connected') }}
                </span>
                <span v-if="deviceStatus === '2'" style="color: red">
                  {{ $t('cannot_connect') }}
                </span>
                <span v-if="deviceStatus === '99'" style="color: green">
                  {{ $t('connection_successful') }}
                </span>
              </el-col>
            </el-row>
            <el-row :gutter="50" style="margin-top: 30px">
              <el-col :span="12">
                <el-button
                  v-if="deviceStatus == 99 && isUsb"
                  class="btn"
                  size="small"
                  @click="confirmSelectDevice"
                  >{{ $t('confirm_selection') }}</el-button
                >
                <el-button
                  v-if="deviceStatus == 1 && isUsb"
                  class="btn"
                  size="small"
                  @click="breakConnectDevice"
                  >{{ $t('disconnect') }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="videoVisible"
      :title="$t('usb_instructional_video')"
      :visible.sync="videoVisible"
      width="60%"
      :append-to-body="true"
    >
      <video src="@/assets/1.mp4" width="100%" controls="controls">
        {{ $t('video_unsupported') }}
      </video><el-button @click="showVideo(false)" style="margin-top: 15px;margin-bottom: 0px;">{{$t('back')}}</el-button></el-dialog
    >
  </el-dialog>
</template>

<script>
import {
  selectDeviceList,
  checkDeviceState,
  confirmSelectDevice,
  breakConnectDevice
} from '@/api'
import { syncDevice } from '@/api/modules/product'
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      websock: null,
      loading: false,
      isDisabled: false,
      deviceStatus: '',
      deviceStatusStr: '',
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      dataList: [],
      dataList_: [],
      selestData: [],
      formInline: {
        id: ''
      },
      dialogVisible: false,
      isUsb: true,
      videoVisible: false
    }
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    init(isUsb = true) {
      console.log(isUsb)
      const userDevice = this.$store.getters.userDevice
      console.log(userDevice)
      if (userDevice && userDevice.id) {
        // 有一连接的设备
        if (userDevice.connectionType == 1) {
          this.isUsb = false
          this.deviceStatus = '1'
          this.isDisabled = true
        } else {
          this.isUsb = true
        }
      } else {
        this.isUsb = isUsb
      }

      this.dialogVisible = true

      // console.log(this.$store.getters.userDevice)
      this.selectDeviceList()
    },
    checkTest() {
      this.loading = true
      this.isDisabled = true
      this.getSetmessage()
      //this.initWebSocket();
      //this.checkDeviceState()
    },
    handleChange() {
      this.selestData = this.dataList.filter(
        (item) => item.value === this.formInline.id
      )
      if (this.isUsb) {
        //判断设备状态
        this.checkDeviceState(this.formInline.id)
      } else {
        // Wifi设备不用获取状态：没有插件所以没有状态信息
        this.deviceStatus = '99'
        this.loading = false

        // const userDevice = this.$store.getters.userDevice
        // userDevice.id = this.selestData[0].value
        // userDevice.deviceName = this.selestData[0].label
        // userDevice.isWifiConnected = true
        // this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        //   // console.log(this.$store.getters.userDevice)
        // })
        // Cookies.set('userDevice', userDevice)
      }

      // var data = this.dataList_.find( item =>{//假的，测试时用
      //   if (item.id == this.formInline.id ) {
      //     return item
      //   }
      // })
      // this.$store.commit('SET_DEVICEDATA', data)
      // Cookies.set('devicedata', data);
    },
    handleClose() {
      this.formInline.id = ''
      this.deviceStatus = '0'
      this.loading = false
      this.isDisabled = false
      this.dialogVisible = false
      // this.$emit('dataSynchronization')//假的，测试时用
      if (this.websock) {
        this.websock.close()
      }
    },
    //获取设备
    getSetmessage() {
      if (!this.formInline.id) {
        this.loading = false
        this.isDisabled = false
        this.$message.error(this.$t('choose_device'))
        return false
      }
      syncDevice({ deviceId: this.formInline.id }).then(({ data }) => {
        if (data && data.code === 0) {
          this.initWebSocket()
          this.timer = setInterval(() => {
            //如果已经同步就关闭定时任务
            this.$message.warning(this.$t('device_sync_in_progress'))
            this.timerNum = this.timerNum + 1
            if (this.timerNum == 5) {
              this.clearTimer() // 关闭定时器
              this.loading = false
              this.isDisabled = false
            }
          }, 5000)
        } else {
          this.loading = false
          this.isDisabled = false
          this.$message.error(data.msg)
        }
      })
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    async selectDeviceList() {
      const params = {}
      const { data } = await selectDeviceList(params)
      if (data && data.code === 0) {
        this.dataList_ = data.devices
        let filteredData = this.isUsb
          ? data.devices
          : this.dataList_.filter((device) => {
              return device.isWifi == '0'
            })
        this.dataList = filteredData.map((item) => {
          return {
            value: item.id,
            label: item.deviceName,
            isWifi: item.isWifi
          }
        })
        if (
          this.$store.getters.userDevice &&
          this.$store.getters.userDevice.id
        ) {
          this.formInline.id = this.$store.getters.userDevice.id
          if (this.isUsb) {
            this.checkDeviceState(this.$store.getters.userDevice.id)
          }
        } else {
          this.formInline.id = ''
          this.deviceStatus = ''
        }
      }
    },
    async checkDeviceState(id) {
      if (!id) {
        this.loading = false
        this.$message.error(this.$t('choose_device'))
        return false
      }
      this.formInline.id = id
      const params = {
        deviceId: id
      }
      const { data } = await checkDeviceState(params)
      if (data && data.code === 500) {
        this.$message.error(data.msg)
        this.deviceStatus = '2'
        this.loading = false
        this.isDisabled = false
        this.deviceStatusStr = data.stateData.stateStr
      } else if (data && data.code === 0) {
        if (data.stateData.state === '0') {
          this.deviceStatus = '0'
          this.loading = false
          this.isDisabled = false
          //this.getSetmessage()
        } else if (data.stateData.state === '1') {
          this.deviceStatus = '1'
          this.loading = false
          this.isDisabled = true
        } else if (data.stateData.state === '2') {
          this.deviceStatus = '2'
          this.deviceStatusStr = data.stateData.stateStr
        }
      }
    },
    async confirmSelectDevice() {
      if (!this.formInline.id) {
        this.$message.error(this.$t('choose_device'))
        return false
      }
      if (this.deviceStatus === '99') {
        if (!this.isUsb) {
          this.selectDevice()
        } else {
          const params = {
            deviceId: this.formInline.id,
            connectionType: this.isUsb ? '0' : '1'
          }
          const { data } = await confirmSelectDevice(params)
          if (data && data.code === 0) {
            this.selectDevice()
            // this.$emit('dataSynchronization',)
          } else {
            this.$message.error(data.msg)
            this.isDisabled = false
          }
        }
      } else {
        this.$message.error(this.$t('device_error'))
        this.isDisabled = false
      }
    },
    async selectDevice() {
      const userDevice = this.$store.getters.userDevice
      userDevice.id = this.selestData[0].value
      userDevice.deviceName = this.selestData[0].label
      userDevice.connectionType = this.isUsb ? '0' : '1'

      this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        // console.log(this.$store.getters.userDevice)
      })
      Cookies.set('userDevice', userDevice)

      this.loading = false
      this.isDisabled = this.isUsb ? false : true
      if (!this.isUsb) {
        this.deviceStatus = '1'
      }
      this.dialogVisible = false
    },

    async breakConnectDevice() {
      if (!this.formInline.id) {
        this.$message.error('设备已断开 - Device already disconnected')
        this.selectDeviceList()
        return false
      }
      if (!this.isUsb) {
        this.disconnectDevice()
      }
      const params = {
        deviceId: this.formInline.id
      }
      const { data } = await breakConnectDevice(params)
      if (data && data.code === 0) {
        this.disconnectDevice()
      }
    },
    async disconnectDevice() {
      const userDevice = this.$store.getters.userDevice
      userDevice.id = ''
      userDevice.deviceName = ''
      this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        // console.log(this.$store.getters.userDevice)
      })
      this.selectDeviceList()
      this.formInline.id = ''
      this.deviceStatus = '0'
      this.loading = false
      this.isDisabled = false
      Cookies.remove('userDevice')
      if (this.websock) {
        this.websock.close()
      }
    },
    initWebSocket() {
      //初始化weosocket
      //ws地址
      var id = this.formInline.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      // console.log(e);
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      // console.log(code);
      var msg = retData.msg
      // console.log(msg);
      var data = retData.data
      // console.log(data);
      if (code == 0) {
        this.deviceStatus = '99'
        this.$message.success('连接成功 - Connection successful')

        var data = this.dataList_.find((item) => {
          if (item.id == this.formInline.id) {
            return item
          }
        })

        this.$store.commit('SET_DEVICEDATA', data)
        Cookies.set('devicedata', data)
        this.clearTimer() // 关闭定时器
        this.loading = false
        this.isDisabled = false
        this.websock.close()
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error(this.$t('websocket_connection_failed'))
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      // console.log("connection closed (" + e.code + ")");
    },
    changeConnectionType() {
      if (this.deviceStatus != '') {
        this.$confirm(
          this.$t('switching_connection_reset_device'),
          this.$t('attention'),
          {
            type: 'warning'
          }
        ).then(() => {
          this.breakConnectDevice().then(() => {
            this.isUsb = !this.isUsb
            this.selectDeviceList()
          })
        })
      } else {
        this.isUsb = !this.isUsb
        this.selectDeviceList()
      }
    },
    showVideo(show = true) {
      this.videoVisible = show
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  color: #666666;
  font-size: 14px;
  line-height: 2;
}
.deviceStatus {
  span {
    &:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-right: 12px;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
  }
}
.connect-device {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 15px;
  margin-left: 10px;
}
.el-dialog__footer {
  position: relative;
  padding: 0;
}
</style>
<style lang="scss" >
.demo-form-inline {
  margin-top: 40px;
  .el-form-item__label {
    width: auto !important;
  }
}
.tab-button {
  font-size: 18px;
}

img {
  vertical-align: middle;
}
</style>
