<template>
  <el-dialog
    :title="
      show ? this.$t('view_import_changes') : this.$t('view_import_details')
    "
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      size="small"
      ref="dataForm"
      label-width="120px"
      v-loading="dataLoading"
      style="width: 100%; margin-top: -10px"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <div style="padding: 0 20px; margin-bottom: 20px">
        <div style="margin-bottom: 20px">
          {{ $t('valid_template') }}：
          <span style="color: #20a082">{{ $t('passed') }}</span>
        </div>
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>
            {{ $t('new_products') }}：<span>{{
              productUploadInfo.productAdd
            }}</span>
          </div>
          <div style="margin-right: 10%">
            {{ $t('updated_products') }}：<span>{{
              productUploadInfo.productUpdate
            }}</span>
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="24"
          ><div class="grid-content borderLeft">
            {{ $t('product_details') }}
          </div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div class="grid-content borderLeft">
            {{ $t('properties_added') }}
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content">{{ $t('properties_edited') }}</div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content">
            {{ $t('properties_removed') }}
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content">
            {{ $t('incorrect_property') }}
          </div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div class="grid-content borderLeft borderBottom">
            {{ productUploadInfo.attrAdd }}
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content borderBottom">
            {{ productUploadInfo.attrUpdate }}
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content borderBottom">
            {{ productUploadInfo.attrDel }}
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content borderBottom">
            {{ productUploadInfo.attrMisMatch }}
          </div></el-col
        >
      </el-row>
    </el-form>

    <div
      class="warning-message"
      v-for="(messageListItem, index) in messageList"
      :key="index"
    >
      <i class="el-icon-warning"></i>
      <strong> {{ messageListItem.warningMessage }}</strong>
      <div
        v-if="
          !messageListItem.seeMoreVisible && messageListItem.items.length > 0
        "
      >
        <el-button @click="seeMore(messageListItem)" type="text">{{
          $t('view_details')
        }}</el-button>
      </div>
      <div v-else class="details-box">
        <span v-for="(item, index) in messageListItem.items" :key="index"
          >{{ item
          }}<span v-if="index != messageListItem.items.length - 1">, </span>
        </span>
      </div>
    </div>

    <span slot="footer" class="dialog-footer flex" v-if="show">
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >{{ $t('confirm_import') }}</el-button
      >
      <el-button @click="updateImport()" size="small" :disabled="dataLoading">{{
        $t('cancel_import')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  info,
  update,
  saveOrUpdateProductAndValue
} from '@/api/modules/productImportRecord'

export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: 0
      },
      productUploadInfo: {
        productAdd: '-',
        productUpdate: '-',
        attrAdd: '-',
        attrUpdate: '-',
        attrDel: '-',
        attrMisMatch: '-'
      },
      show: ''
    }
  },
  methods: {
    init(id, show) {
      console.log(id, show)
      this.dataForm.id = id
      this.show = show
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.dataLoading = true
          var params = {
            uploadInfoId: this.dataForm.id
          }
          info(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.productUploadInfo = data.productUploadInfo
                ? data.productUploadInfo
                : this.productUploadInfo
              if (data.messageList) {
                this.messageList = data.messageList
                this.messageList.forEach(
                  (warningItem) => (warningItem.seeMoreVisible = false)
                )
              }
              this.dataLoading = false
            }
          })
        }
      })
    },
    updateImport() {
      this.dataLoading = true
      var params = {
        id: this.dataForm.id,
        uploadState: '4',
        productAdd: this.productUploadInfo.productAdd,
        productUpdate: this.productUploadInfo.productUpdate,
        attrAdd: this.productUploadInfo.attrAdd,
        attrUpdate: this.productUploadInfo.attrUpdate,
        attrDel: this.productUploadInfo.attrDel,
        attrMisMatch: this.productUploadInfo.attrMisMatch
      }
      update(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.dataLoading = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error(data.msg)
          this.dataLoading = false
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.dataLoading = true
      var params = {
        productAdd: this.productUploadInfo.productAdd,
        productUpdate: this.productUploadInfo.productUpdate,
        attrAdd: this.productUploadInfo.attrAdd,
        attrUpdate: this.productUploadInfo.attrUpdate,
        attrDel: this.productUploadInfo.attrDel,
        attrMisMatch: this.productUploadInfo.attrMisMatch,
        id: this.dataForm.id
      }
      console.log(params)
      saveOrUpdateProductAndValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.dataLoading = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error(data.msg)
          this.dataLoading = false
        }
      })
    },
    seeMore(item) {
      item.seeMoreVisible = true
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.grid-content {
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  border-right: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  word-break: normal;
}
.borderLeft {
  border-left: 1px solid #ebebeb;
}
.borderBottom {
  border-bottom: 1px solid #ebebeb;
}
.warning-message {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 20px;
  width: 95%;
  word-break: normal;
}
.details-box {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 10px;
  height: 100px;
  margin: 5px;
  overflow-y: scroll;
  scrollbar-width: thin;
  word-break: normal;
  text-align: left;
}
</style>
