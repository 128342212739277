import request from "@/utils/request";

// 获取列表
export function list(params) {
  return request({
    url: "/member/colorbook/list",
    method: "get",
    params: params,
  });
}

// 获取信息
export function info(params) {
  return request({
    url: "/member/colorbook/info",
    method: "get",
    params: params,
  });
}

// 添加
export function add(params) {
  return request({
    url: "/member/colorbook/save",
    method: "post",
    data: params,
  });
}

// 修改
export function update(params) {
  return request({
    url: "/member/colorbook/update",
    method: "post",
    data: params,
  });
}

// 删除
export function del(params) {
  return request({
    url: "/member/colorbook/delete",
    method: "post",
    data: params,
  });
}
// 获取类别下拉列表
export function colorBookDropDown(params) {
  return request({
    url: "/member/colorbook/colorBookDropDown",
    method: "get",
    params: params,
  });
}
// 导入
export function importExcel(params) {
  return request({
    url: "/member/colorbook/importExcel",
    method: "post",
    data: params,
  });
}

// 导入产品
export function importProductExcel(params) {
  return request({
    url: "/product/product/importProductExcel",
    method: "post",
    data: params,
  });
}
// 获取分类下拉列表
export function getColorBookCategory(params) {
  return request({
    url: "/member/colorbook/getColorBookCategory",
    method: "get",
    params: params,
  });
}
// 获取根据色号类型匹配色号分类数据
export function getCategoryList(params) {
  return request({
    url: "/member/colorbook/getCategoryList",
    method: "get",
    params: params,
  });
}
// 根据色号分类选择的色号列表
export function getColorBookList(params) {
  return request({
    url: "/member/colorbook/getColorBookList",
    method: "get",
    params: params,
  });
}


export function colorBookCategoryListApi() {
  return request({
    url: "/colorx-tigerApi/product/colorbook/getAllCategory",
    method: "get",
  });
}
