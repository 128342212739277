<template >
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
      >-->
    <sides-lip ref="SidesLip" @click="close()" >
      <div slot="soltForm">
        <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
         v-loading="dataLoading" style="width:65%;margin: auto"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >
          <el-form-item label="设备名称" prop="deviceName">
            <el-input v-model="dataForm.deviceName" placeholder="设备名称"  size="small" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNo">
            <el-input v-model="dataForm.deviceNo" placeholder="设备编号"  size="small" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="设备图片" prop="picUrl">
            <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
              <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
            </el-upload>
    <!--        <el-input v-model="dataForm.picUrl" placeholder="设备图片"  size="small"></el-input>-->
          </el-form-item>
          <el-form-item label="设备型号" prop="deviceModel">
            <el-select v-model="dataForm.deviceModel" placeholder="设备型号">
              <el-option label="全部" value=""></el-option>
                <el-option
                        v-for="item in dictList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属工厂" prop="factoryId">
            <el-select v-model="this.dataFactoryBrand.factoryId" placeholder="所属工厂" disabled >
              <el-option label="全部" value=""></el-option>
              <el-option
                      v-for="item in factoryList"
                      :key="item.id"
                      :label="item.factoryName"
                      :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="设备状态" prop="state">
            <template>
              <el-radio v-model="dataForm.state" label="1">正常</el-radio>
              <el-radio v-model="dataForm.state" label="0">停用</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="设备信息" prop="remarks">
            <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入设备信息"
                    maxlength="150"
                    show-word-limit
                    v-model="dataForm.remarks">
            </el-input>
        </el-form-item>
        </el-form>
      </div>
        <div slot="soltFooter">
          <span slot="footer" class="dialog-footer">
            <el-button @click="sideClose()" size="small">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
          </span>
        </div>
    </sides-lip>
  <!--</el-dialog>-->
</template>

<script>
  import {info,add,update,dictList,factoryList} from '@/api/modules/device';
  import {getFactoryBrand} from '@/api/modules/product';
  import {getToken} from '@/utils/auth';
  import Tinymce from '@/components/Tinymce';
  import SidesLip from "@/components/SidesLip";

  export default {
    components: {
      SidesLip,
      Tinymce

    },
    data () {
      return {
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        visible: false,
        dataLoading: false,
        dictList:[],
        factoryList:[],
        dataFactoryBrand:[],
        dataForm: {
          id: 0,
          deviceNo: '',
          deviceName: '',
          picUrl: '',
          deviceModel: '',
          factoryId: '',
          state:'1',
          remarks: ''
        },
        dataRule: {
          deviceNo: [
            { required: true, message: '设备编号不能为空', trigger: 'blur' }
          ],
          deviceName: [
            { required: true, message: '设备名称不能为空', trigger: 'blur' }
          ],
          // picUrl: [
          //   { required: true, message: '设备图片不能为空', trigger: 'blur' }
          // ],
          deviceModel: [
            { required: true, message: '设备型号不能为空', trigger: 'blur' }
          ],
          /*factoryId: [
            { required: true, message: '工厂id不能为空', trigger: 'blur' }
          ],*/
          state: [
            { required: true, message: '设备状态不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      init (id) {
        console.log(id+"11111111111111111111111111111111111111111111111111");
        this.$refs.SidesLip.init(
                id ? "修改" : "新增",
                "700px",
                "block"
        );
        this.getDataFactoryBrand(),
        this.getDataDictList(),
        this.getDataFactoryList(),
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.deviceNo = data.device.deviceNo
                this.dataForm.deviceName = data.device.deviceName
                this.dataForm.picUrl = data.device.picUrl
                this.dataForm.deviceModel = data.device.deviceModel
                this.dataForm.factoryId = data.device.factoryId
                this.dataForm.state = data.device.state
                this.dataForm.remarks = data.device.remarks
              }
            })
          }
        })
      },
      //获取下拉型号列表
      getDataDictList(){
        dictList().then(({ data })=>{
          if(data && data.code === 0 ){
            this.dictList = data.dictList;
          }
        })
      },
      //获取工厂下拉列表
      getDataFactoryList(){
        factoryList().then(({ data })=>{
          if(data && data.code === 0 ){
            this.factoryList = data.factorylist;
          }
        })
      },
      //获取当前登录人的工厂信息
      getDataFactoryBrand(){
        getFactoryBrand().then(({data})=>{
          if(data && data.code === 0){
            this.dataFactoryBrand = data.factoryBrand;
          }
        })
      },
      //图片上传
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.picUrl = res.data.fileUrl;
        } else {
          this.$message.error(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        const isJPG =
                file.type === "image/jpeg" ||
                file.type === "image/bmp" ||
                file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'deviceNo': this.dataForm.deviceNo,
              'deviceName': this.dataForm.deviceName,
              'picUrl': this.dataForm.picUrl,
              'deviceModel': this.dataForm.deviceModel,
              'factoryId': this.dataFactoryBrand.factoryId,
              'state': this.dataForm.state,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    /*this.visible = false;*/
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                    this.$refs.SidesLip.sideClose();
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      sideClose() {
        this.$refs.SidesLip.sideClose();
      },
    }
  }
</script>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    max-height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    max-height: 178px;
    display: block;
  }
</style>
<!--
<style>
  .el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    /*height:600px;*/
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  .el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
  }
</style>-->
