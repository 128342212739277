<template>
    <el-dialog
            :title="!dataForm.id ? '模糊库存' : '模糊库存'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            class="all-location-dialog">
      <el-form :model="dataForm"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
               v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
      >
            小程序模糊库存下限:
            <el-tooltip effect="dark" content="当产品库存为0时，库存显示“无”；大于0且小于输入值时，显示实际库存;大于或等于输入值时，显示“有”" placement="top">
                <i class="el-icon-info"></i>
            </el-tooltip>
            <el-input-number class="input_number_1" v-model="num" :step="1" max="1000000" min="0"></el-input-number>
            kg
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="small">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
    import { userInfo, passwordById } from "@/api/sys";
    import { setInventorySize, getInventorySize } from '../../api/modules/alllcationInventory'
  
    export default {
      data () {
        return {
            num: 25,
            visible: false,
            dataLoading: false,
            dataForm: {
            id: '',
          },
        }
      },
      methods: {
        initData() {
            getInventorySize({
                brandId: this.dataForm.id
            }).then(res => {
                this.num = res.data.value || 0
                this.visible = true
            })
        },
        init(id) {
          this.dataForm.id = id;
          this.initData()
        },
        // 表单提交
        dataFormSubmit () {
            // if(this.num<0 || this.num>1000){
            //     this.$message({
            //         message: '请输入0-1000之间的正整数！',
            //         type: 'warning'
            //     })
            //     this.dataLoading = false
            //     this.visible = false
            //     return
            // }
          this.$refs['dataForm'].validate((valid) => {
            if (valid) {
              this.dataLoading = true;
              setInventorySize({
                number: this.num,
                brandId: this.dataForm.id
              }).then(res => {
                if(res.data.code===500){
                    this.$message({
                    message: res.data.msg,
                    type: 'warning'
                })
                this.dataLoading = false
                this.visible = false
                }else{
                    this.$message({
                    message: res.data.msg,
                    type: 'success'
                })
                this.dataLoading = false
                this.visible = false
                }
              })
            
            }
          })
        }
      }
    }
  </script>
  <style lang="scss" scoped>
.all-location-dialog /deep/ .el-dialog__body {
    text-align: center;
    .input_number_1{
        margin-left: 90px;
    }

}


</style>
  