<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="colorimetricName">
        <el-input
          v-model="dataForm.colorimetricName"
          :placeholder="$t('record_name')"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <el-date-picker
          type="daterange"
          v-model="dataForm.daterange"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :editable="false"
          :start-placeholder="$t('search_from')"
          :end-placeholder="$t('search_to')"
          style="width: 300px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.createUserId"
          filterable
          :placeholder="$t('employee_name')"
          clearable
        >
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{ $t('search') }}</el-button>
        <el-button @click="reset('dataForm')" size="small">{{ $t('clear') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="colorimetricName"
        header-align="center"
        align="center"
        :label="$t('record_name')"
      >
      </el-table-column>
      <el-table-column
        prop="productNo"
        header-align="center"
        align="center"
        :label="$t('sample_number')"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="210"
        :label="$t('sample_LAB_values')"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in setsplit(scope.row.prototypeLab)"
            :key="index"
          >
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sampleQuantity"
        header-align="center"
        align="center"
        :label="$t('sample_counts')"
      >
      </el-table-column>
      <el-table-column
        prop="createUserName"
        header-align="center"
        align="center"
        :label="$t('employee_name')"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        width="150"
        align="center"
        :label="$t('date_created')"
      >
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        width="150"
        align="center"
        :label="$t('date_updated')"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        :label="$t('action')"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >{{ $t('details') }}</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >{{ $t('delete') }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { userList } from '@/api/sys'
import {
  listColorimetricHistoryApi,
  delColorimetricHistoryApi
} from '@/api/modules/colorcomprehistory'
export default {
  data() {
    return {
      dataForm: {
        colorimetricName: '',
        daterange: [],
        createUserId: '',
        createUserName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      userList: []
    }
  },
  activated() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      var params = {
        page: 1,
        limit: 100
      }
      userList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.userList = data.page.list
        } else {
          this.userList = []
        }
        this.getDataList()
      })
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var endDate = ''
      var startDate = ''
      if (this.dataForm.daterange && this.dataForm.daterange.length == 2) {
        startDate = this.dataForm.daterange[0] + ' 00:00:00'
        endDate = this.dataForm.daterange[1] + ' 23:59:59'
      }
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        colorimetricName: this.dataForm.colorimetricName,
        createUserId: this.dataForm.createUserId,
        createTimeFrom: startDate,
        createTimeTo: endDate
      }
      listColorimetricHistoryApi(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 详情
    addOrUpdateHandle(id) {
      this.$router.push({ path: '/colorcompre', query: { id: id } })
    },
    setsplit(val) {
      if (val) {
        let arr = val.split(';')
        return arr
      } else {
        return ''
      }
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('confirm_delete_record'), this.$t('attention'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        delColorimetricHistoryApi(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('success'),
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
