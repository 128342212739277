<template>
  <el-dialog
    :title="$t('edit_match')"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="210px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
      class="feedback-form"
    >
      <el-form-item :label="$t('match_result')" prop="operation">
        <el-radio-group v-model="dataForm.operation">
          <el-radio v-model="dataForm.operation" label="2">{{
            $t('perfect_match')
          }}</el-radio>
          <el-radio v-model="dataForm.operation" label="4">{{
            $t('fair_match')
          }}</el-radio>
          <el-radio v-model="dataForm.operation" label="3">{{
            $t('poor_match')
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="remarks" :label="$t('notes')">
        <el-input
          size="small"
          :placeholder="$t('enter_search_note')"
          v-model="dataForm.remarks"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="sampleOrderNo" :label="$t('sample_order_number')">
        <el-input
          size="small"
          :placeholder="$t('enter_sample_number')"
          v-model="dataForm.sampleOrderNo"
        >
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="dataForm.operation == '3'"
        prop="noMatch"
        :label="$t('reason')"
      >
        <el-autocomplete
          v-model="dataForm.noMatch"
          :fetch-suggestions="querySearch"
          :placeholder="$t('enter_reason')"
          style="width: 100%"
        ></el-autocomplete>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{
        $t('cancel')
      }}</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >{{ $t('confirm') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { info, add, update, getValueId } from '@/api/modules/searchhistoryList'
import { getProductAttrValue } from '@/api/modules/productattrvalue'
import SearchForm from '@/components/form.vue'
import { getAttrAndValueListInfo } from '@/api/modules/productattr'
import ElFormItem from '../../../node_modules/element-ui/packages/form/src/form-item.vue'
export default {
  components: { ElFormItem, SearchForm },
  data() {
    return {
      testList: [
        {
          value: this.$t('visual_difference'),
          label: this.$t('visual_difference')
        },
        {
          value: this.$t('gloss_not_match'),
          label: this.$t('gloss_not_match')
        },
        {
          value: this.$t('surface_not_match'),
          label: this.$t('surface_not_match')
        },
        {
          value: this.$t('series_not_match'),
          label: this.$t('series_not_match')
        }
      ],
      noMatchList: [
        this.$t('(visual_difference'),
        this.$t('gloss_not_match'),
        this.$t('surface_not_match'),
        this.$t('series_not_match')
      ],
      visible: false,
      dataLoading: false,
      colorSeriesList: [],
      ProductCategoryList: [],
      dataForm: {
        id: 0,
        productId: '',
        l: '',
        a: '',
        b: '',
        ralColorNo: '',
        gloss: '',
        searchDate: '',
        diviceId: '',
        brandId: '',
        factoryId: '',
        surface: '',
        productNo: '',
        productCategory: '',
        state: '',
        operation: '',
        userId: '',
        searchType: '',
        de: '',
        searchProductNo: '',
        reason: '',
        powderCategory: '',
        noMatch: '',
        valueId: '',
        colorSeries: '',
        sampleOrderNo: '',
        remarks: ''
      },
      dataRule: {
        noMatch: [
          {
            required: true,
            message: this.$t('reason_blank'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var results = queryString
        ? this.testList.filter(
            (item) =>
              item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        : this.testList
      cb(results)
    },
    //产品属性下拉数据
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.ProductCategoryList = data.list[i].productAttrValue
            }
          }
        } else {
        }
      })
    },
    //获取金属粉id
    getValueId() {
      let params = {}
      getValueId(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm.valueId = data.ValueId
        } else {
        }
      })
    },
    init(id) {
      this.getAttrAndValueListInfo(),
        //this.getValueId(),
        (this.dataForm.id = id || 0)
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.productId = data.searchHistory.productId
              this.dataForm.l = data.searchHistory.l
              this.dataForm.a = data.searchHistory.a
              this.dataForm.b = data.searchHistory.b
              this.dataForm.ralColorNo = data.searchHistory.ralColorNo
              this.dataForm.gloss = data.searchHistory.gloss
              this.dataForm.searchDate = data.searchHistory.searchDate
              this.dataForm.diviceId = data.searchHistory.diviceId
              this.dataForm.brandId = data.searchHistory.brandId
              this.dataForm.factoryId = data.searchHistory.factoryId
              this.dataForm.surface = data.searchHistory.surface
              this.dataForm.productNo = data.searchHistory.productNo
              this.dataForm.productCategory = data.searchHistory.productCategory
              this.dataForm.state = data.searchHistory.state
              if (data.searchHistory.operation == '1') {
                this.dataForm.operation = ''
              } else {
                this.dataForm.operation = data.searchHistory.operation
              }
              this.dataForm.userId = data.searchHistory.userId
              this.dataForm.searchType = data.searchHistory.searchType
              this.dataForm.de = data.searchHistory.de
              this.dataForm.searchProductNo = data.searchHistory.searchProductNo
              this.dataForm.colorSeries = data.searchHistory.colorSeries
              this.dataForm.reason = data.searchHistory.reason
              this.dataForm.noMatch = data.searchHistory.noMatch
              this.dataForm.sampleOrderNo = data.searchHistory.sampleOrderNo
              this.dataForm.remarks = data.searchHistory.remarks

              // 获取属性
              this.searchData = data.resultMap
            }
          })
        }
      })
    },
    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      getAttrAndValueListInfo({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '100%' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
            }
            obj.label = e.label
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      /*  console.log("操作结果："+this.dataForm.operation)
          if (this.dataForm.operation==""||this.dataForm.operation==null) {
              this.$message.error("请选择操作处理");
          }*/
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var params = {
            searchData: this.searchData,
            id: this.dataForm.id || undefined,
            operation: this.dataForm.operation,
            // 'productCategory':this.dataForm.productCategory,
            // 'colorSeries':this.dataForm.colorSeries,
            noMatch: this.dataForm.noMatch,
            matchType: 2,
            sampleOrderNo: this.dataForm.sampleOrderNo,
            reason: this.dataForm.reason,
            remarks: this.dataForm.remarks
          }
          var tick = !this.dataForm.id ? update(params) : update(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.feedback-form {
  .el-form-item__label {
    width: 200px !important;
  }
}
</style>
