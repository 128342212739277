<template>
  <div style="height: 22px; display:inline-block">
    <span class="read-message" v-if="msg && msg.length > 0"
      ><i class="el-icon-success"></i>
      {{ msg }}
      <i class="el-icon-circle-close" @click="close" style="cursor: pointer"></i
    ></span>
  </div>
</template>

<script>
export default {
  data: () => ({
    msg: '',
    timeMessage: ''
  }),
  methods: {
    show(msg) {
      clearTimeout(this.msgTimer)
      this.msg = msg
      this.msgTimer = setTimeout(() => {
        this.msg = ''
      }, 10000)
    },
    close() {
      clearTimeout(this.msgTimer)
      this.msg = ''
    }
  }
}
</script>
<style scoped>
.read-message {
  font-size: 14px;
  background-color: #e1f8d8;
  border: 1px solid #bbdaab;
  padding: 8px;
  color: #67c23a;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  animation: reveal 0.2s;
  transform: translate3d(0, 0, 0);
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>