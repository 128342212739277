<template>
  <div class="image-uploader">
    <i
      class="el-icon-delete fab-button"
      @click="deleteImage"
      v-if="!isDisabled && mainImage"
    ></i>
    <el-image
      v-if="mainImage"
      :src="previewUrl"
      class="main-image"
      :preview-src-list="fullUrls"
    />
    <el-upload
      :action="actionPreview"
      :show-file-list="false"
      :on-success="handleImageUploadSuccess"
      :before-upload="beforeUpload"
      :disabled="isDisabled"
      multiple
      :style="{ height: mainImage ? '0px' : undefined }"
    >
      <span v-if="!mainImage && !isDisabled">
        <i
          class="el-icon-plus image-uploader-icon"
          :title="$t('click_to_upload')"
        ></i>
        <span class="upload-image-text">
          {{ $t('upload_product_image') }}
        </span>
      </span>
      <i class="upload-image-text empty-uploader" v-if="isDisabled && !mainImage">
        {{$t('no_image_uploaded')}}
      </i>
    </el-upload>
    <div
      v-for="(image, index) in otherImages"
      :key="index"
      class="preview-image"
    >
      <img
        :src="image.previewUrl"
        @click="isDisabled ? undefined : makeMain(image)"
        :preview-src-list="isDisabled ? fullUrls : undefined"
        style="width: 100%; height: 100%"
      />
    </div>

    <div v-if="uploadImages.length > 0 && !isDisabled" class="preview-image">
      <el-upload
        :action="actionPreview"
        :show-file-list="false"
        :on-success="handleImageUploadSuccess"
        :before-upload="beforeUpload"
        multiple
      >
        <i class="el-icon-circle-plus-outline add-image"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
/**
 * props: 
 * uploadImages: [...{
 *      isMain: 是否主图（String; '0', '1'）
 *      fullUrl: 原图URL（String）
 *      previewUrl: 缩小图URL（String）
 *  }...]
 */
import { getToken } from '@/utils/auth'
export default {
  props: ['uploadImages', 'isDisabled'],
  data: () => ({
    // actionPreview: `http://localhost:8080/api/sys/oss/uploadProductImage?token=${getToken()}&needImagePreview=true`
    actionPreview: `${process.env.VUE_APP_BASE_API}/sys/oss/uploadProductImage?token=${getToken()}&needImagePreview=true`,
  }),
  computed: {
    fullUrls() {
      if (this.mainImage) {
        let images = [this.mainImage]
        this.otherImages.forEach((otherImage) => images.push(otherImage))

        // 主要的图片该放在第一位
        return images.map((image) => image.fullUrl)
      } else {
        return []
      }
    },
    mainImage() {
      if (this.uploadImages && this.uploadImages.length > 0) {
        let mainImages = this.uploadImages.filter(
          (image) => image.isMain == '1'
        )
        if (mainImages.length > 0) {
          return mainImages[0]
        } else {
          return this.uploadImages[0]
        }
      } else {
        return undefined
      }
    },
    otherImages() {
      if (this.mainImage) {
        return this.uploadImages.filter(
          (image) => image.fullUrl != this.mainImage.fullUrl
        )
      } else {
        return []
      }
    },
    previewUrl() {
      if (this.mainImage) {
        return this.mainImage.previewUrl
      } else {
        return undefined
      }
    },
    fullUrl() {
      if (this.mainImage) {
        return this.mainImage.fullUrl
      } else {
        return undefined
      }
    }
  },
  methods: {
    //上传产品效果图处理
    handleImageUploadSuccess(res, file, type) {
      const productImage = {
        isMain: this.mainImage ? '0' : '1',
        fullUrl: res.data.fileUrl,
        previewUrl: res.data.previewFileUrl
      }
      this.uploadImages.push(productImage)
    },
    //上传前对图片大小及格式进行判断
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt8M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        this.$message.error(this.$t('unsupported_image_format'))
      }
      if (!isLt8M) {
        this.$message.error(this.$t('uploaded_images_must_not_exceed_x', {x: '8MB'}))
      }
      return isJPG && isLt8M
    },
    // 删除（主）图片
    deleteImage() {
      this.$confirm(
        this.$t('confirm_delete_record'),
        this.$t('attention'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }
      ).then(() => {
        let mainImageIndex = this.uploadImages.findIndex(
          (image) => image == this.mainImage
        )
        this.uploadImages.splice(mainImageIndex, 1)

        if (this.uploadImages.length > 0) {
          // 指定新的主图片
          this.uploadImages[0].isMain = '1'
        }
        this.$forceUpdate()
      })
    },
    makeMain(imageToMakeMain) {
      this.uploadImages.forEach((productImage) => (productImage.isMain = '0'))
      this.uploadImages.find(
        (productImage) => productImage == imageToMakeMain
      ).isMain = '1'
    }
  }
}
</script>
<style lang="scss" scoped>
.image-uploader {
  width: 192px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  padding: 5px;
  .el-icon-delete {
    top: 12px;
    right: 12px;
  }
  .fab-button {
    font-weight: bold;
    color: white;
    position: absolute;
    display: block;
    width: 26px;
    height: 25px;
    border-radius: 20px;
    padding: 5px 5px 5px 6px;
    background: #303133;
    opacity: 0.5;
    z-index: 2;
    cursor: pointer;
  }
  .fab-button:hover {
    opacity: 1;
  }
}

.image-uploader:hover {
  border-color: #409eff;
}

.image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 110px;
  line-height: 130px;
  text-align: center;
  display: block;
}

.empty-uploader {
  color: #8c939d;
  width: 180px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  display: block;
  text-decoration: italic;
}

.main-image {
  width: 180px;
  max-height: 180x;
  display: block;
  cursor: zoom-in;
}

.upload-image-text {
  color: #8c939d;
  font-size: 12px;
}

.preview-image {
  cursor: pointer;
  display: inline-block;
  opacity: 0.7;
  margin: 1px;
  width: 32%;
  max-height: 200px;
}

.preview-image:hover {
  opacity: 1;
}

.add-image {
  color: #8c939d;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
  vertical-align: middle;
  height: 100%;
  width: 58px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}

.add-image:hover {
  border-color: #409eff;
}
</style>