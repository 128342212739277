<template>
  <el-dialog
    :title="$t('import_product_images')"
    :close-on-click-modal="false"
    @close="closeHandle"
    :visible.sync="visible"
    :append-to-body="true"
    top="2%"
  >
    <el-form
      size="small"
      label-width="150px"
      v-loading="dataLoading"
      element-loading-spinner="el-icon-loading"
    >
      <div style="margin-left: 5%; width: 80%">
        <el-form-item :label="$t('instructions')">
          <el-button
            type="text"
            @click="instructionsVisible = true"
            v-if="!instructionsVisible"
          >
            {{ $t('view_x', { x: $t('instructions').toLowerCase() }) }}
          </el-button>
          <div class="instructions" v-else>
            <div v-html="$t('import_image_instructions_content')"></div>

            <div
              @click="instructionsVisible = false"
              class="el-icon-close close-expanded-element"
            ></div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('factory')">
          <el-select
            v-model="factoryId"
            size="small"
            :placeholder="$t('select_factory')"
          >
            <el-option
              v-for="item in factoryList"
              :key="item.id"
              :label="item.factoryName"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('import_mode')">
          <el-radio v-model="importMode" label="1">{{
            $t('replace_product_existing_images')
          }}</el-radio>
          <el-radio v-model="importMode" label="2">{{
            $t('add_product_images')
          }}</el-radio>
        </el-form-item>
      </div>
      <el-upload
        drag
        :action="actionPreview"
        :before-upload="beforeUploadHandle"
        multiple
        :file-list="fileList"
        :show-file-list="true"
        :on-success="handleUploadSuccess"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        style="text-align: center"
        :disabled="!factoryId"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{ $t('drag_or_click_to_upload') }}</div>
        <div class="el-upload__tip">
          {{ $t('only_image_files_supported') }}
        </div>
        <div slot="tip">
          <el-button
            type="primary"
            style="margin-top: 10px"
            @click="importImages()"
            :loading="dataLoading"
            :disabled="uploadComplete"
            >{{ $t('confirm_import_images') }}</el-button
          >
        </div>
      </el-upload>
    </el-form>

    <el-dialog :visible.sync="previewVisible" append-to-body>
      <img width="100%" :src="previewImageUrl" />
    </el-dialog>

    <div style="width: 80%; margin-left: 10%">
      <message-list :messageList="messageList" />
    </div>
  </el-dialog>
</template>
<script>
import { getFactory } from '@/api/modules/product'
import { getToken } from '@/utils/auth'
import { importProductImages } from '../../api/modules/product'
import messageList from '../../components/messageList.vue'

export default {
  components: { messageList },
  data() {
    return {
      dataLoading: false,
      visible: false,
      importMode: '1',
      successNum: 0,
      uploadImages: [],
      fileList: [],
      factoryList: [],
      factoryId: '',
      uploadComplete: true,
      seeMoreVisible: false,
      messageList: [],
      previewVisible: false,
      previewImageUrl: '',
      instructionsVisible: false
    }
  },
  computed: {
    actionPreview() {
      // return `http://localhost:8080/api/sys/oss/uploadProductImage?token=${getToken()}&factoryId=${this.factoryId}`
      return `${process.env.VUE_APP_BASE_API}/sys/oss/uploadProductImage?token=${getToken()}&factoryId=${this.factoryId}`
    }
  },
  methods: {
    init() {
      this.uploadComplete = true
      this.visible = true
      this.getFactoryList()
      this.warningMessage = ''
      this.messageList = []
      this.seeMoreVisible = false
      this.instructionsVisible = false
    },
    //获取工厂列表
    getFactoryList() {
      let params = {
        brandId: ''
      }
      getFactory(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.list
          this.factoryId = data.factoryId
        } else {
          this.factoryList = []
        }
      })
    },
    // 上传之前
    beforeUploadHandle(file) {
      const isSupportedImage =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt8M = file.size / 1024 / 1024 < 8
      const hasFactory = this.factoryId && this.factoryId != ''

      if (!isSupportedImage) {
        this.$message.error(this.$t('unsupported_image_format'))
      }
      if (!isLt8M) {
        this.$message.error(
          this.$t('uploaded_images_must_not_exceed_x', { x: '8MB' })
        )
      }
      if (!hasFactory) {
        this.$message.error(this.$t('e706', { x: this.$t('factory') }))
      }

      return isSupportedImage && isLt8M && hasFactory
    },
    //上传产品效果图处理
    handleUploadSuccess(res, file, type) {
      const productImage = {
        fileName: res.data.fileName,
        fullUrl: res.data.fileUrl,
        previewUrl: res.data.previewFileUrl
      }
      this.uploadImages.push(productImage)
      this.uploadComplete = false
      this.messageList = []
    },
    handlePreview(file) {
      this.previewVisible = true
      this.previewImageUrl = file.response.data.fileUrl
    },
    handleRemove(file) {
      console.log(this.uploadImages)
      let deleteFileUrl = file.response.data.fileUrl
      let currentImageIndex = this.uploadImages.findIndex(
        (image) => image.fullUrl == deleteFileUrl
      )
      this.uploadImages.splice(currentImageIndex, 1)
      console.log(this.uploadImages)
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = []
      this.uploadImages = []
      this.$emit('refreshDataList')
    },
    importImages() {
      if (
        this.factoryId == '' ||
        this.factoryId == null ||
        this.factoryId == undefined
      ) {
        this.$message.error(this.$t('e706', { x: this.$t('factory') }))
        return
      }

      this.dataLoading = true

      let params = {
        importMode: this.importMode,
        factoryId: this.factoryId,
        productImages: this.uploadImages
      }

      importProductImages(params).then(({ data }) => {
        console.log(data)
        if (data && data.code === 0) {
          this.$message({
            message: this.$t('success'),
            type: 'success',
            duration: 2000
          })
          this.dataLoading = false
          this.uploadComplete = true
          this.uploadImages = []
          this.fileList = []

          if (data.messageList) {
            this.messageList = data.messageList
            this.messageList.forEach(
              (warningItem) => (warningItem.seeMoreVisible = false)
            )
          }
        } else {
          this.dataLoading = false
          return this.$alert(data.msg)
        }
      })
    },
    jumpPage() {
      this.visible = false
      this.$router.push('/productImportRecord')
    }
  }
}
</script>
<style lang="scss" scoped>
.productall-uploaded {
  font-size: 14px;
  background-color: #e1f8d8;
  border: 1px solid #bbdaab;
  padding: 12px;
  margin-top: 20px;
  color: #67c23a;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
}
upload-tip {
  margin-top: 10px;
  font-size: 10px;
  color: #8c939d;
}
.instructions {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 5px 10px 5px 10px;
  word-break: normal;
}
.close-expanded-element {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  cursor: pointer;
}
</style>
