import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/announcement/announcement/list',
    method: 'get',
    params: params
  })
}

// 获取生效的公告
export function getEnabledAnnouncement () {
  return request({
    url: '/announcement/announcement/getEnabledAnnouncement',
    method: 'get'
  })
}

// 获取当天激活的公告
export function getActiveAnnouncement () {
  return request({
    url: '/announcement/announcement/getActiveAnnouncement',
    method: 'get'
  })
}

// 添加点击次数
export function addClick (params) {
  return request({
    url: '/announcement/announcement/addClick',
    method: 'get',
    params: params
  })
}

// 添加点击次数
export function info (params) {
  return request({
    url: '/announcement/announcement/info',
    method: 'get',
    params: params
  })
}

// 完成
export function disableAnnouncement (params) {
  return request({
    url: '/announcement/announcement/disableAnnouncement',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/announcement/announcement/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/announcement/announcement/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/announcement/announcement/delete',
    method: 'post',
    data: params
  })
}
