<template>
  <div class="basic-component">
    <div>{{$t('search_color_wechat')}}</div>
    <div>
      <img v-if="qrImg" :src="qrImg" />
    </div>
  </div>
</template>
<script>
import { selectNavigationList } from '@/api'
import Vue from 'vue'
export default {
  computed: {
    qrImg() {
      const brandQr = localStorage.getItem('brandWxqr')
      if (brandQr === 'null') {
        return null
      }
      return brandQr
    },
    isInfoAuth(key) {
      return (key) => (this.list || '[]').indexOf(key) !== -1 || false
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.selectNavigationList()
  },
  methods: {
    async selectNavigationList() {
      const { data } = await selectNavigationList()
      if (data && data.code === 0) {
        this.list = data.data
        // cosole.log(Vue)
        // Vue.prototype.isInfoAuth = (key) => {
        //   return (data.data || '[]').indexOf(key) !== -1 || false
        // }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.basic-component {
  position: relative;
  height: 260px;
  background: #fff;
  border: 2px solid #eaeff8;
  border-radius: 3px;
  opacity: 1;
  flex-direction: column;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 150px;
  }
  div {
    text-align: center;
    font-weight: bold;
    margin: 10px 10px 5px 10px;
  }
}
.wrapper-bottom {
  height: 142px;
  // justify-content: space-around;
  justify-content: space-between;
  margin-top: 22px;
  div {
    height: 142px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.add-per {
  > a {
    position: relative;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    border-radius: 13px;
    // display: block;
    transition: all 0.3;
    bottom: 4px;
    opacity: 0;
    button {
      width: 128px;
      height: 40px;
      background: #07cadc;
      opacity: 1;
      border-radius: 3px;
      border: 0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 8px;
    }
  }
  &:hover {
    .mask {
      // display: block;
      opacity: 1;
    }
  }
}
</style>