<template>
  <el-dialog
    :title="pageTitle"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="100px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          style="width: 90%"
          v-model="dataForm.title"
          placeholder="标题"
          size="small"
          maxlength="150"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="英文标题" prop="title">
        <el-input
          style="width: 90%"
          v-model="dataForm.titleEn"
          placeholder="英文标题"
          size="small"
          maxlength="150"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input
          style="width: 90%"
          type="textarea"
          :rows="5"
          v-model="dataForm.content"
          placeholder="内容"
          size="small"
          maxlength="800"
          show-word-limit
          :disabled="disabled"
        ></el-input>
      </el-form-item>
            <el-form-item label="英文内容" prop="content">
        <el-input
          style="width: 90%"
          type="textarea"
          :rows="5"
          v-model="dataForm.contentEn"
          placeholder="英文内容"
          size="small"
          maxlength="800"
          show-word-limit
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="显示期限" prop="timeRange">
        <template>
          <div class="block">
            <el-date-picker
              style="width: 90%"
              v-model="dataForm.timeRange"
              type="datetimerange"
              align="right"
              range-separator="——"
              start-placeholder="显示开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :disabled="disabled"
            >
            </el-date-picker>
          </div>
        </template>
      </el-form-item>
      <!-- 修改的时候才显示状态 -->
      <el-form-item label="状态" v-if="dataForm.id">
        <template>
          <el-radio v-model="dataForm.isEnabled" label="0" :disabled="disabled"
            >失效</el-radio
          >
          <el-radio v-model="dataForm.isEnabled" label="1" :disabled="disabled"
            >生效</el-radio
          >
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { info, add, update } from '@/api/modules/announcement'
export default {
  data() {
    return {
      visible: false,
      disabled: false,
      dataLoading: false,
      types: [
        {
          value: '0',
          label: '生效'
        },
        {
          value: '1',
          label: '失效'
        }
      ],
      dataForm: {
        id: 0,
        isCopy: false,
        pageTitle: '',
        title: '',
        titleEn: '',
        content: '',
        contentEn: '',
        timeRange: [],
        isEnabled: ''
      },
      dataRule: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        timeRange: [
          { required: true, message: '显示期限不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init(id, disabled, isCopy) {
      this.dataForm.id = id || 0
      this.disabled = disabled
      this.visible = true

      if (!id) {
        this.pageTitle = '新增'
      } else if (disabled) {
        this.pageTitle = '查看'
      } else if (isCopy) {
        this.pageTitle = this.$t('copy')
        this.isCopy = true
      } else {
        this.pageTitle = '编辑'
      }

      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.title = data.announcement.title
              this.dataForm.titleEn = data.announcement.titleEn
              this.dataForm.content = data.announcement.content
              this.dataForm.contentEn = data.announcement.contentEn
              this.dataForm.isEnabled = data.announcement.isEnabled

              this.dataForm.timeRange = [
                data.announcement.startTime,
                data.announcement.endTime
              ]
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          var startTime = null
          var endTime = null
          if (this.dataForm.timeRange && this.dataForm.timeRange.length > 0) {
            startTime = this.dataForm.timeRange[0]
            endTime = this.dataForm.timeRange[1]
          }
          if (this.isCopy) {
            this.dataForm.id = undefined
          }
          var params = {
            id: this.dataForm.id || undefined,
            title: this.dataForm.title,
            titleEn: this.dataForm.titleEn,
            content: this.dataForm.content,
            contentEn: this.dataForm.contentEn,
            isEnabled: this.dataForm.isEnabled || undefined,
            startTime: startTime,
            endTime: endTime
          }
          var tick = !this.dataForm.id ? add(params) : update(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    }
  }
}
</script>
