import request from "@/utils/request";

// 获取基础产品信息列表
export function colorcomparison(data) {
  return request({
    url: "/colorcompre/colorcompre/colorcomparison",
    method: "post",
    data,
  });
}

export function colorcomparisonNew(params) {
  return request({
    url: "/colorcompre/colorcompre/colorcomparisonNew",
    method: "post",
    params: params,
  });
}
