<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="品牌名称" prop="brandName">
        <el-input v-model="dataForm.brandName" placeholder="品牌名称"  size="small" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="英文名称" prop="brandEname">
        <el-input v-model="dataForm.brandEname" placeholder="英文名称"  size="small" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="品牌LOGO" prop="brandLogo">
        <el-upload
                class="avatar-uploader"
                v-bind:action="action"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
          <img v-if="dataForm.brandLogo" :src="dataForm.brandLogo" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="品牌信息" prop="description">
        <el-input
                type="textarea"
                :rows="5"
                v-model="dataForm.description"
                placeholder="品牌信息"
                maxlength="100"
                size="small">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/brand'
  import {getToken} from '@/utils/auth'
  import Tinymce from '@/components/Tinymce'

  export default {
    components: {Tinymce},
    data () {
      return {
        visible: false,
        action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
        dataLoading: false,
        dataForm: {
          id: 0,
          brandName: '',
          brandEname: '',
          brandLogo: '',
          description: '',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: '',
          imageUrl: ''
        },
        dataRule: {
          brandName: [
            { required: true, message: '品牌名称不能为空', trigger: 'blur'}
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.brandName = data.brand.brandName
                this.dataForm.brandEname = data.brand.brandEname
                this.dataForm.brandLogo = data.brand.brandLogo
                this.dataForm.description = data.brand.description
                this.dataForm.delFlag = data.brand.delFlag
                this.dataForm.createBy = data.brand.createBy
                this.dataForm.createDate = data.brand.createDate
                this.dataForm.updateBy = data.brand.updateBy
                this.dataForm.updateDate = data.brand.updateDate
                this.dataForm.remarks = data.brand.remarks
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': this.dataForm.id || undefined,
              'brandName': this.dataForm.brandName,
              'brandEname': this.dataForm.brandEname,
              'brandLogo': this.dataForm.brandLogo,
              'description': this.dataForm.description,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      },
      //图片上传
      handleAvatarSuccess(res, file, type) {
        if (res && res.code === 0) {
          this.dataForm.brandLogo = res.data.fileUrl;
        } else {
          this.$message.error(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        const isJPG =
                file.type === "image/jpeg" ||
                file.type === "image/bmp" ||
                file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isJPG) {
          this.$message.error("上传图片格式不支持!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 8MB!");
        }
        return isJPG && isLt2M;
      }
    }
  }
</script>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    max-height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    max-height: 178px;
    display: block;
  }
</style>