<template>
  <div class="login-container">
    <img src="../../assets/loginlogo.png" class="logo" />
    <div class="des" ref="des">
      <img src="../../assets/kouhao.png" style="width: 100%; height: auto" />
    </div>
    <div class="login-form-layout">
      <el-form
        ref="forgetForm"
        :model="forgetForm"
        :rules="forgetRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">{{ $t('forgot_password_title') }}</h3>
          <div class="line"></div>
        </div>

        <div class="landing-content">
          <el-form-item prop="email">
            <span class="svg-container" style="font-size: 24px">
              <img src="../../assets/组 6555.png" alt="" />
            </span>
            <el-input
              v-model="forgetForm.email"
              :placeholder="$t('email')"
              name="email"
              type="text"
              auto-complete="on"
            />
          </el-form-item>

          <el-form-item prop="Code" style="width: 220px">
            <span class="svg-container">
              <img src="../../assets/组 6556.png" alt="" />
            </span>
            <el-input
              style="width: 80%"
              v-model="forgetForm.code"
              type="text"
              :placeholder="$t('verification_code')"
              name="code"
            />
          </el-form-item>
          <button
            type="button"
            class="code"
            @click="getCode"
            :disabled="value"
            style="cursor: pointer"
          >
            {{ typeof text == 'number' ? text + second : text }}
          </button>
          <el-button
            :loading="loading"
            type="primary"
            style="width: 320px"
            @click.native.prevent="goReset"
            >{{ $t('confirm') }}</el-button
          >
        </div>

        <div class="login-footer">
          <div class="locale-switcher">
            <LocaleSwitcher />
          </div>
          <div class="forget" @click="goLogin">{{ $t('go_to_login') }}</div>
        </div>
      </el-form>
    </div>
    <div
      style="
        display: flex;
        justify-content: space-around;
        color: #ffffff;
        font-size: 14px;
        width: 100%;
        bottom: 20px;
        position: fixed;
      "
    >
      <p>{{ $t('email_contact') }}</p>
      <p>
        © 2022 亿涂（苏州）互联网科技有限公司 版权所有 |
        <a href="http://beian.miit.gov.cn/">苏ICP备2022011139号</a>
      </p>
    </div>
  </div>
</template>
<script>
import { setToken } from '@/utils/auth'
import { gainCode, Proving } from '@/api/forget'
import LocaleSwitcher from '@/components/LocaleSwitcher'
export default {
  name: 'forget',
  data() {
    //邮箱的表单验证规则
    const validateEmail = (rule, value, callback) => {
      if (
        /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(value) ||
        !value
      ) {
        callback()
      } else {
        callback(new Error(this.$t('enter_valid_email')))
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error(this.$t('verification_code_length')))
      } else {
        callback()
      }
    }
    return {
      text: this.$t('send_code'),
      value: false,
      second: 'S',
      forgetForm: {
        email: '',
        code: ''
      },
      forgetRules: {
        email: [
          { required: true, trigger: 'blur', validator: validateEmail } //校验邮箱
        ],
        code: [
          { required: true, trigger: 'blur', validator: validateCode } //校验验证码
        ]
      },
      loading: false,
      showDialog: false,
      imgUrl: ''
    }
  },
  components: {
    LocaleSwitcher
  },
  created() {
    //   this.loginForm.uuid = guid();
    //   this.imgUrl =
    //     process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${this.loginForm.uuid}`;
  },
  methods: {
    //    open4() {
    //   this.$message({
    //     showClose: true,
    //     message: '获取邮箱失败！',
    //     type: 'error'
    //   });
    // },
    // 跳转页面
    goLogin() {
      this.$router.push('/login')
    },
    //验证用户输入的验证码是否正确，然后跳转重置密码页面
    goReset() {
      // this.$router.push("/reset");
      // setToken(data.token);
      // if (this.forgetForm.email && this.forgetForm.code != "") {
      // this.$confirm(`确定前往重置密码页面?`, "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //     this.forgetForm.email = this.forgetForm.code = "";
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消前往'
      //   });
      // });
      // }
      if (this.forgetForm.email && this.forgetForm.code != '') {
        this.loading = true
        const email = this.forgetForm.email
        const verificationCode = this.forgetForm.code
        const params = {
          email,
          verificationCode
        }
        Proving(params).then(({ data }) => {
          console.log(data.token)
          this.loading = false
          if (data.code === 0) {
            this.$router.push('/reset')
            setToken(data.token)
            this.$store.commit('SET_TOKEN', data.token)
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: this.$t('e706', {
            x: `${this.$t('email')}, ${this.$t(
              'verification_code'
            ).toLowerCase()}`
          }),
          type: 'error'
        })
      }
    },
    //根据用户邮箱发送验证码
    getCode() {
      localStorage.setItem('email', this.forgetForm.email)
      const email = this.forgetForm.email
      const params = 'email=' + email
      gainCode(params).then(({ data }) => {
        console.log(data)
        let codeData = data
        if (codeData.code === 0) {
          this.$message({
            showClose: true,
            message: this.$t('success'),
            type: 'success'
          })
          this.value = true
          var counter = 60
          this.text = counter
          let _this = this
          let time = setInterval(function () {
            counter--
            _this.text = counter
            if (counter == 0) {
              clearInterval(time)
              _this.value = false
              _this.text = _this.$t('get_verification_code')
            }
          }, 1000)
        } else if (codeData.msg === this.$t('verification_code_still_valid')) {
          this.$message({
            showClose: true,
            message: this.$t('verification_code_still_valid'),
            type: 'warning'
          })
        } else {
          this.$message({
            showClose: true,
            message: this.$t('email_does_not_exist'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #eee;
$cursor: #fff;
/* reset element-ui css */
.login-container {
  background: rgba(13, 78, 230, 0.06) url('../../assets/Background.png') center
    center no-repeat;
  background-size: cover;
  .logo {
    width: 187px;
    height: auto;
    position: fixed;
    top: 5%;
    left: 3.9%;
  }
  .des {
    position: fixed;
    top: 50%;
    margin-top: -140px;
    left: 10%;
    max-width: 731px;
  }
  .login-form-layout {
    position: absolute;
    top: 50%;
    left: 58%;
    margin-top: -270px;
    background: #fefdfd;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.18);
    opacity: 1;
    border-radius: 10px;
    .login-footer {
      justify-content: space-between;
      width: 320px;
      margin-top: 30px;
      margin-left: 50px;
    }
    .forget {
      width: 80px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      opacity: 1;
      cursor: pointer;
      margin-right: 10px;
      float: right;
      text-align: right;
    }
    .locale-switcher {
      margin-right: 10px;
      float: left;
    }
  }
  .landing-content {
    width: 320px;
    height: 249px;
    margin: 0 auto;
    position: relative;
    .code {
      width: 96px;
      height: 32px;
      border: 1px solid #407cf3;
      opacity: 1;
      border-radius: 4px;
      position: absolute;
      top: 84px;
      left: 228px;
      background-color: #fefdfd;
      color: #407cf3;
      font-size: 14px;
    }
    & button:focus {
      border-style: solid;
      // border-color: #03a9f4;
      // box-shadow: 0 0 15px #03a9f4;
      outline: none;
    }
  }

  .landing-content ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 0px;
      height: 47px;
      // caret-color: $cursor;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .el-form-item {
    width: 320px;
    border-bottom: 1px solid #cfcedb;
  }
  .el-button--primary {
    width: 320px;
    height: 50px;
    background: #42d6d7;
    opacity: 1;
    border-radius: 5px;
    margin-top: 36px;
    border-color: white;
  }
  .el-input:-webkit-autofill:focus {
    // -webkit-box-shadow: none!important;
    // -webkit-text-fill-color: white;
  }
}
.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f0f3fa inset !important;
  -webkit-text-fill-color: #000 !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
// $bg:#2d3a4b;
$bg: transparent;
$dark_gray: #2e414e;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  .vcode {
    position: absolute;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .login-form {
    position: relative;
    width: 420px;
    height: 430px;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 1px;
    color: $dark_gray;
    vertical-align: middle;
    width: 40px;
    display: inline-block;
    font-size: 21px;
  }
  .title-container {
    position: relative;
    .title {
      height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 28px;
      color: #000000;
      opacity: 1;
      margin-left: 54px;
    }
    .line {
      position: absolute;
      width: 80px;
      height: 3px;
      background: #42d6d7;
      opacity: 1;
      // margin: 0 auto;
      top: 37px;
      left: 54px;
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
@media screen and (min-width: 1024px) {
  .des {
    width: 450px;
  }
} /*>=1024的设备屏幕*/

@media screen and (min-width: 1100px) {
  .des {
    width: 450px;
  }
} /*>=1100的设备屏幕*/
@media (min-width: 1280px) {
  .des {
    width: 500px;
  }
} /*>=1280的设备屏幕*/

@media screen and (min-width: 1366px) {
  .des {
    width: 531px;
  }
} /*>=1366的设备屏幕*/

@media screen and (min-width: 1440px) {
  .des {
    width: 631px;
  }
} /*>=1440的设备屏幕*/

@media screen and (min-width: 1680px) {
  .des {
    width: 731px;
  }
} /*>=1680的设备屏幕*/
@media screen and (min-width: 1920px) {
  .des {
    width: 731px;
  }
} /*>=1920的设备屏幕*/
</style>
