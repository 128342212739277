<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
      size="small"
    >
      <el-form-item label="操作时间" prop="dateRange">
        <div class="block">
          <el-date-picker
            v-model="dataForm.dateRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      tooltip-effect="dark"
      size="small"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="用户"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="行为类型"
      >
        <template slot-scope="scope">
          <label v-if="scope.row.behaviorType == 'login'">登录</label>
          <label v-else-if="scope.row.behaviorType == 'logout'">退出</label>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="终端"
      >
        <template slot-scope="scope">
          <label v-if="scope.row.loginType == '1'">颜色管理系统</label>
          <label v-else-if="scope.row.loginType == '7'">称量客户端</label>
        </template>
      </el-table-column>
      <el-table-column
        prop="loginIp"
        header-align="center"
        align="center"
        label="操作IP"
      >
      </el-table-column>
      <el-table-column
        prop="loginTime"
        header-align="center"
        align="center"
        width="180"
        label="操作时间"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { userLoginListApi } from "@/api/sys";

export default {
  data() {
    return {
      dataForm: {
        dateRange: [],
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
    };
  },
  components: {},
  methods: {
    // 查询清空
    reset(dataForm) {
      this.$refs[dataForm].resetFields();
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },

    // 获取数据列表 查询清空
    getDataList() {
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
      };
      if (this.dataForm.dateRange && this.dataForm.dateRange.length > 0) {
        params.startTime = this.dataForm.dateRange[0] + " 00:00:00";
        params.endTime = this.dataForm.dateRange[1] + " 23:59:59";
      }
      userLoginListApi(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>
