<template>
  <div class="app-container">
    <div class="linkArea">
      <el-form label-width="120px" :inline="true" size="small">
        <el-form-item prop="link" label="前端展示页面">
          <el-input v-model="link" :placeholder="$t('model_viewer_name')" clearable maxlength="200"
            style="width: 300px"></el-input>
        </el-form-item>
        <el-button @click="copyLink()" size="small">{{
          $t('copy_link')
          }}</el-button>
      </el-form>
    </div>
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="viewerTitle">
        <el-input v-model="dataForm.viewerTitle" :placeholder="$t('model_viewer_name')" size="small" clearable
          maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="industry">
        <el-select v-model="dataForm.industry" clearable :placeholder="$t('industry_category')">
          <el-option v-for="item in industries" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="status">
        <el-select v-model="dataForm.status" clearable :placeholder="$t('status')">
          <el-option :key="0" :label="$t('unlisted')" :value="0"> </el-option>
          <el-option :key="1" :label="$t('public')" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{
          $t('search')
          }}</el-button>
        <el-button @click="reset('dataForm')" size="small">{{
          $t('clear')
          }}</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small">{{
          $t('create_new')
          }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="viewerTitle" header-align="center" align="center" :label="$t('model_viewer_name')">
      </el-table-column>
      <el-table-column prop="viewerTitle" header-align="center" align="center" :label="$t('industry_category')">
        <template slot-scope="scope">
          <span>{{
            industries.filter(
              (industry) => industry.value == scope.row.industry
            )[0].label
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="emersyaId" header-align="center" align="center" label="Emersya ID">
      </el-table-column>
      <el-table-column prop="projectCode" header-align="center" align="center" label="Project Code">
      </el-table-column>
      <el-table-column prop="picUrl" header-align="center" align="center" :label="$t('model_image')">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="status" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == '0'"><el-tag type="info">{{ $t('unlisted') }}</el-tag></span>
          <span v-if="scope.row.status == '1'"><el-tag type="success">{{ $t('public') }}</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column prop="clickNum" header-align="center" align="center" label="点击数次">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.viewerId, false)">{{ $t('edit')
            }}</el-button>
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.viewerId, false, true)">{{ $t('copy')
            }}</el-button>
          <el-button type="text" size="small" @click="openPreview(scope.row.viewerId)">{{ $t('view_preview')
            }}</el-button>
          <el-button type="text" size="small" style="color: green"
            @click="disableHandle(scope.row.viewerId, scope.row.status)">{{
              scope.row.status == '0' ? $t('publish') : $t('unpublish')
            }}</el-button>
          <el-button type="text" size="small" style="color: red"
            @click="deleteHandle(scope.row.viewerId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import { list, del, updateStatus } from '@/api/modules/modelViewerManagement'
import AddOrUpdate from './add-or-update'
export default {
  data() {
    return {
      dataForm: {
        industry: '',
        viewerTitle: '',
        status: ''
      },
      industries: [
        { value: '0', label: '交通运输' },
        { value: '1', label: '建筑建材' },
        { value: '2', label: '通用工业' },
        { value: '3', label: '绿色生活' },
        { value: '4', label: '其他' }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false
    }
  },
  computed: {
    link() {
      const currentURL = window.location.href
      const link =
        currentURL.replace(this.$route.fullPath, '') +
        `/modelViewerScreen?brandId=${sessionStorage.getItem('BrandInfo')}`
      return link
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        viewerTitle: this.dataForm.viewerTitle,
        status: this.dataForm.status,
        industry: this.dataForm.industry
      }
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.ListData.list
          this.totalPage = data.ListData.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id, disabled, isCopy = false) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, disabled, isCopy)
      })
    },
    disableHandle(id, status) {
      updateStatus({ viewerId: id, status: status == '0' ? '1' : '0' }).then(
        ({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
            this.$nextTick(() => {
              this.restPage()
            })
          } else {
            this.$message.error(data.msg)
          }
          this.dataListLoading = false
        }
      )
    },
    // 删除
    deleteHandle(id) {
      const params = {
        viewerId: id
      }

      this.$confirm(`是否确认删除公告?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    copyLink() {
      navigator.clipboard.writeText(this.link)
      this.$message({
        message: this.$t('copy_success'),
        type: 'success',
        duration: 1500
      })
    },
    openPreview(viewerId) {
      window.open(`${this.link}&viewerId=${viewerId}&type=preview`)
    }
  }
}
</script>
<style scoped>
.linkArea {
  border: 1px solid #c0c0c0;
  display: block;
  margin-bottom: 10px;
  padding: 15px 15px 0px 0px;
  width: fit-content;
}
</style>
