<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()" size="small">
      <el-form-item prop="userName">
        <el-input v-model="dataForm.userName" :placeholder="$t('username')" clearable></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <el-input v-model="dataForm.name" :placeholder="$t('full_name')" clearable></el-input>
      </el-form-item>

      <el-form-item prop="userNo">
        <el-input v-model="dataForm.userNo" :placeholder="$t('employee_number')" clearable></el-input>
      </el-form-item>
      <el-form-item prop="roleId">
        <el-select v-model="dataForm.roleId" size="small" :placeholder="$t('choose_role')">
          <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"
            size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">{{ $t('search') }}</el-button>
        <el-button @click="reset('dataForm')">{{ $t('clear') }}</el-button>
        <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()">{{ $t('create_new')
          }}</el-button>
        <el-button v-if="isAuth('sys:user:delete')" type="danger" @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0">{{ $t('batch_delete') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" tooltip-effect="dark"
      @selection-change="selectionChangeHandle" size="small" style="width: 100%">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>
      <el-table-column prop="username" header-align="center" align="center" :label="$t('username')">
      </el-table-column>
      <el-table-column prop="name" header-align="center" align="center" :label="$t('full_name')">
      </el-table-column>
      <el-table-column prop="userNo" header-align="center" align="center" :label="$t('employee_number')">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" :label="$t('email')">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" :label="$t('phone_number')">
      </el-table-column>
      <el-table-column prop="brandName" header-align="center" align="center" :label="$t('assigned_brand')">
      </el-table-column>
      <el-table-column prop="factoryName" header-align="center" align="center" :label="$t('assigned_factory')">
      </el-table-column>
      <el-table-column prop="roleName" header-align="center" align="center" :label="$t('role')">
      </el-table-column>
      <el-table-column prop="isFreeze" header-align="center" align="center" :label="$t('status')">
        <template slot-scope="scope">
          {{ scope.row.isFreeze == '1' ? $t('frozen') : $t('normal') }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" width="180" :label="$t('date_created')">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" :label="$t('action')">
        <template slot-scope="scope">
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
            @click="addOrUpdateHandle(scope.row.userId)">{{ $t('edit') }}</el-button>
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
            @click="updatepasswordHandle(scope.row.userId)">
            {{ $t('change_password') }}</el-button>
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
            @click="updatepemailHandle(scope.row.userId)">
            {{ $t('edit_email') }}</el-button>
          <el-button v-if="isAuth('sys:user:update') && scope.row.userNo" type="text" size="small" style="color: green"
            @click="printPreviewHandle(scope.row.userNo)">{{ $t('print') }}</el-button>
          <el-button v-if="isAuth('sys:user:delete')" type="text" size="small"
            @click="deleteHandle(scope.row.userId)">{{ $t('delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="restPage"></add-or-update>
    <print-preview v-if="printPreviewVisible" ref="printPreviewPerson" @refreshDataList="getDataList"></print-preview>
    <update-password v-if="updatePasswordVisible" ref="updatePassword" @refreshDataList="getDataList"></update-password>
    <update-email v-if="updateEmailVisible" ref="updateEmail" @refreshDataList="getDataList"></update-email>
  </div>
</template>

<script>
import { userList, userDelete, queryRole } from '@/api/sys'
import AddOrUpdate from './components/user-add-or-update'
import PrintPreview from './components/printPreviewPerson'
import UpdatePassword from '@/components/updatepassword'
import UpdateEmail from '@/components/updateEmail'

export default {
  data() {
    return {
      dataForm: {
        loginname: '',
        name: '',
        userNo: '',
        roleId: ''
      },
      dataList: [],
      roleList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      printPreviewVisible: false,
      updatePasswordVisible: false,
      updateEmailVisible: false,
      addOrUpdateVisible: true
    }
  },
  components: {
    PrintPreview,
    AddOrUpdate,
    UpdatePassword,
    UpdateEmail
  },
  // activated() {
  //   this.getDataList();
  // },
  /*mounted() {
    this.getRole();
  },*/
  /* activated() {
     this.getRole();
   },*/
  created() {
    this.getRole()
  },
  methods: {
    //角色名称下拉
    getRole() {
      let params = {
        roleType: 2
      }
      queryRole(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.roleList = data.RoleList
        } else {
          this.roleList = []
        }
      })
    },
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.$refs[dataForm].resetFields()
      this.restPage()
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },

    // 获取数据列表 查询清空 修改  by葛海军
    getDataList() {
      this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        username: this.dataForm.userName,
        name: this.dataForm.name,
        userNo: this.dataForm.userNo,
        roleId: this.dataForm.roleId,
        userType: 'employee'
      }
      userList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    //打印
    printPreviewHandle(userNo) {
      this.printPreviewVisible = true
      this.$nextTick(() => {
        this.$refs.printPreviewPerson.init(userNo)
      })
    },
    //修改密码
    updatepasswordHandle(id) {
      this.updatePasswordVisible = true
      console.log(id)
      if (id == null) {
        this.$message.error(this.$t('account_error'))
        return
      }
      this.$nextTick(() => {
        this.$refs.updatePassword.init(id)
      })
    },
    //修改邮箱
    updatepemailHandle(id) {
      this.updateEmailVisible = true
      console.log(id)
      if (id == null) {
        this.$message.error(this.$t('account_error'))
        return
      }
      this.$nextTick(() => {
        this.$refs.updateEmail.init(id)
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
          return item.userId
        })
      this.$confirm(
        this.$t('confirm_intention') + id
          ? this.$t('delete')
          : this.$t('batch_delete'),
        this.$t('attention'),
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          // const params = {
          //   userIds: userIds
          // };
          const { data } = await userDelete(userIds)
          if (data && data.code === 0) {
            this.$message.success({
              message: this.$t('success'),
              onClose: () => {
                this.restPage()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => { })
    }
  },
  mounted() {
    this.getDataList()
    const params = this.$route.query.params
    if (params === 'proPer') {
      setTimeout(() => {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(undefined)
        })
      }, 1000)
    }
  }
}
</script>
