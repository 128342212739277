import request from '@/utils/request'


export function setInventorySize (data) {
    return request({
        url: '/small/program/setInventorySize',
        method: 'post',
        data: data
    })
}

export function getInventorySize (params) {
    return request({
        url: '/small/program/getInventorySize',
        method: 'post',
        data: params
    })
}