<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item>
        <el-button type="primary" @click="addOrUpdateHandle()" size="small">
          {{ $t('create_new') }}</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="factoryName"
        header-align="center"
        align="center"
        :label="$t('factory_name')"
      >
      </el-table-column>
      <el-table-column
        prop="productMainNoRegex"
        header-align="center"
        align="center"
        :label="$t('product_main_no_regex')"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.productMainNoRegex"> {{ scope.row.productMainNoRegex }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="productComponentNoRegex"
        header-align="center"
        align="center"
        :label="$t('product_component_no_regex')"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.productComponentNoRegex"> {{ scope.row.productComponentNoRegex }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="productVersionNoRegex"
        header-align="center"
        align="center"
        :label="$t('product_version_no_regex')"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.productVersionNoRegex"> {{ scope.row.productVersionNoRegex }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="baseSuffix"
        header-align="center"
        align="center"
        :label="$t('base_suffix')"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.baseSuffix"> {{ scope.row.baseSuffix }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        :label="$t('action')"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, true)"
            >{{ $t('view') }}</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, false)"
            >{{ $t('edit') }}</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, false, true)"
            >{{ $t('copy') }}</el-button
          >
          <el-button
            type="text"
            size="small"
            style="color: red"
            @click="deleteHandle(scope.row.id)"
            >{{ $t('delete') }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import { list, del } from '@/api/modules/productNoValidation'
import AddOrUpdate from './add-or-update'
export default {
  data() {
    return {
      dataForm: {
        brandId: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var params = {
        brandId: this.dataForm.brandId
      }
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id, disabled, isCopy = false) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, disabled, isCopy)
      })
    },
    disableHandle(id) {
      disableAnnouncement({ id: id }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
          this.$nextTick(() => {
            this.restPage()
          })
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = [id]

      this.$confirm(`是否确认删除公告?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
