<template>
    <sides-lip ref="SidesLip" @click="close()">
        <div slot="soltForm">
            <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" label-width="160px"
                v-loading="dataLoading" style="width: 100%" element-loading-spinner="el-icon-loading">
                <el-row>
                    <el-col :span="18">
                        <el-form-item :label="$t('product_number')" prop="productNumber">
                            <el-input v-model="dataForm.productNumber" :placeholder="$t('product_number')" size="small"
                                style="width: 100%" maxlength="30" :disabled="isDisabled"></el-input>
                        </el-form-item>
                        <el-row>
                            <el-col :span="20">
                                <el-form-item>
                                    <span slot="label"><label style="color: #0d0908">*</label> L*a*b*</span>
                                    <el-row>
                                        <el-col :span="6">
                                            <el-form-item prop="lAve">
                                                <el-input v-model="dataForm.lAve" placeholder="L*" class="w150 mr10"
                                                    size="small" @input="getHex()" clearable maxlength="30"
                                                    :disabled="isDisabled"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="aAve">
                                                <el-input v-model="dataForm.aAve" placeholder="a*" class="w150 mr10"
                                                    size="small" @input="getHex()" clearable maxlength="30"
                                                    :disabled="isDisabled"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item prop="bAve">
                                                <el-input v-model="dataForm.bAve" placeholder="b*" class="w150 mr10"
                                                    size="small" @input="getHex()" clearable maxlength="30"
                                                    :disabled="isDisabled"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <div :class="isDisabled ? 'disabled-color-preview' : 'color-preview'
                                    " v-if="dataForm.picUrl || dataForm.hex">
                                    <div v-if="dataForm.hex" :style="{
                                        backgroundColor: dataForm.hex,
                                        height: '100%',
                                        minWidth: '100%'
                                    }"></div>
                                    <img v-else :src="dataForm.picUrl" width="100%" height="100%" />
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="" label-width="10px">
                            <image-upload :uploadImages="dataForm.productImages" :isDisabled="isDisabled">
                            </image-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="">
                    <el-collapse @change="handleChange">
                        <el-collapse-item>
                            <span class="collapse-title" slot="title">{{
                                $t('view_detailed_color')
                                }}</span>
                            <div>
                                <ul class="productLAB">
                                    <li>
                                        <span class="title">{{ $t('multi-angle') }} L*A*B*</span>
                                    </li>
                                    <li>
                                        <span class="title">{{ $t('angle') }}</span>
                                        <span v-for="x in productLAB.title" class="w100">{{
                                            x
                                            }}</span>
                                    </li>
                                    <li v-for="(x, index) in productLAB.data">
                                        <span class="title">{{ x }}</span>
                                        <el-input v-model="dataForm[`l${index + 1}`]" placeholder="L*" class="w100"
                                            :disabled="true"></el-input>
                                        <el-input v-model="dataForm[`a${index + 1}`]" placeholder="a*" class="w100"
                                            :disabled="true"></el-input>
                                        <el-input v-model="dataForm[`b${index + 1}`]" placeholder="b*" class="w100"
                                            :disabled="true"></el-input>
                                    </li>
                                    <li v-if="olabShow">
                                        <span class="title">{{ $t('integrating_sphere') }}L*A*B*</span>
                                    </li>
                                    <li v-if="olabShow">
                                        <span class="title">{{ $t('angle') }}</span>
                                        <span v-for="x in productLAB.title" class="w100">{{
                                            x
                                            }}</span>
                                    </li>
                                    <li v-if="olabShow">
                                        <span class="title" style="line-height: 1.2">
                                            D65/10°
                                            <br />
                                            <span class="ptitle">SPIN({{ $t('includes_mirror_reflection') }})</span>
                                        </span>
                                        <el-input v-model="dataForm[`lo`]" placeholder="L*" class="w100"
                                            :disabled="true"></el-input>
                                        <el-input v-model="dataForm[`ao`]" placeholder="a*" class="w100"
                                            :disabled="true"></el-input>
                                        <el-input v-model="dataForm[`bo`]" placeholder="b*" class="w100"
                                            :disabled="true"></el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <div class="ces-main">
                    <search-form ref="form" size="mini" labelWidth="150px" :searchData="searchData"
                        :searchForm="searchForm" :viewdataVisible="isDisabled"></search-form>
                </div>
                <div style="display: flex">
                    <div style="width: 70%">
                        <el-form-item :label="$t('gloss_value')" prop="glossValue">
                            <el-input v-model="dataForm.glossValue" :placeholder="$t('gloss_value')" size="small"
                                @input="selectGlossInfo()" style="width: 100%" :disabled="isDisabled"></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 30%">
                        <el-form-item label="" prop="gloss" label-width="20px">
                            <el-select v-model="dataForm.gloss" size="small" placeholder="" style="width: 100%"
                                disabled="disabled">
                                <el-option v-for="item in productGlossList" :key="item.id" :label="item.name"
                                    :value="item.id" size="small"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item label="">
                    <el-collapse @change="handleChange">
                        <el-collapse-item>
                            <span class="collapse-title" slot="title">{{
                                $t('view_standards')
                                }}</span>
                            <div style="display: flex">
                                <div style="width: 60%">
                                    <template v-for="(item, index) in glossList">
                                        <p v-if="index % 2 == 0" class="ziti">
                                            {{ localizeParam(item, 'label') }}
                                        </p>
                                    </template>
                                </div>
                                <div style="width: 60%">
                                    <template v-for="(item, index) in glossList">
                                        <p v-if="index % 2 == 1" class="ziti">
                                            {{ localizeParam(item, 'label') }}
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <div style="display: flex">
                    <div style="width: 50%">
                        <el-form-item :label="$t('sample_panel_quantity')" prop="modelSum">
                            <el-input v-model="dataForm.modelSum" :disabled="isDisabled"
                                :placeholder="$t('sample_panel_quantity')" size="small"><template slot="append">{{
                                    $t('number_panels') }}</template></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('sample_poweder_quantity')" prop="materialSum">
                            <el-input v-model="dataForm.materialSum" :disabled="isDisabled"
                                :placeholder="$t('sample_poweder_quantity')" size="small"><template slot="append">{{
                                    $t('kg') }}</template></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%">
                        <el-form-item :label="$t('expected_completion_date')" prop="deliverDate">
                            <el-date-picker @change="handleChange" v-model="dataForm.deliverDate" type="date"
                                :placeholder="$t('expected_completion_date')" :disabled="isDisabled"
                                value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                        <el-form-item label=""> </el-form-item>
                    </div>
                </div>
                <el-form-item :label="$t('other_remarks')" prop="description">
                    <div style="margin: 5px 0"></div>
                    <el-input type="textarea" :rows="5" :placeholder="$t('other_remarks')"
                        v-model="dataForm.description" maxlength="150" :disabled="isDisabled" show-word-limit>
                    </el-input>
                </el-form-item>

            </el-form>
        </div>
        <div slot="soltFooter">
            <span slot="footer">
                <el-button @click="close()" size="small">{{ $t('cancel') }}</el-button>
                <!-- <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{ $t('confirm') -->
                <!-- }}</el-button> -->
            </span>
        </div>
    </sides-lip>
</template>

<script>
import {
    getFactoryBrand,
    info,
    getFactory1,
    getBrand,
    getHex
} from '@/api/modules/product'
import Tinymce from '@/components/Tinymce'
import { getToken } from '@/utils/auth'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import {
    getAttrAndValueListInfo,
    getGlossInfo,
    selectGlossInfo
} from '@/api/modules/productattr'
import {
    getProductAttrValue,
    selectChildAttr
} from '@/api/modules/productattrvalue'
import {
    selectProductAttrInfo,
    selectProductLabelInfo
} from '@/api/modules/productnorule'
import { selectLabelList } from '@/api/modules/productlabel'
import { addRemoyeProofong } from '@/api'
import { getSecondsAgoMessage } from '../../util/i18n/time'
import InlineMessage from '../../components/inlineMessage.vue'
import ReadDevice from '../../components/readDevice.vue'
import ProductNoValidation from '../../components/productNoValidation.vue'
import ImageUpload from '../../components/imageUpload.vue'
import { getRemoyeProofongDetails } from '@/api'


export default {
    components: {
        SidesLip,
        SearchForm,
        ImageUpload
    },
    data() {
        return {
            addMethod: undefined,
            updateMethod: undefined,
            searchForm: [
            ],
            searchData: {
            },
            dialogVisible: false,
            biaoZhun: false,
            showLAB: false,
            loading: false,
            olabShow: false,
            visible: false,
            dataLoading: false,
            isBaseCheck: false,
            timer: null, //首先我在data函数里面进行定义定时器名称：
            timerNum: 0, // 设置定时器时间
            productLAB: {
                title: ['L*', 'a*', 'b*'],
                data: ['25°', '45°', '75°']
            },
            FactoryBrand: {},
            factoryId: '',
            isProductNoValid: true,
            isCopy: false,
            isDisabled: false,
            dataForm: {
                id: 0,
                picUrl: '',
                productImages: [],
                qrUrl: '',
                gloss: '',
                glossValue: '',
                hex: '',
                description: '',
                l1: '',
                a1: '',
                b1: '',
                l2: '',
                a2: '',
                b2: '',
                l3: '',
                a3: '',
                b3: '',
                lo: '',
                ao: '',
                bo: '',
                lAve: '',
                aAve: '',
                bAve: '',
                delFlag: '',
                createBy: '',
                createDate: '',
                updateBy: '',
                updateDate: '',
                deliverDate: '',
                productNumber: '',
                modelSum: '',
                materialSum: ''

            },
            performanceList: [],
            industryList: [],
            industryList_child: [],
            colorSeriesList: [],
            productCategoryList: [],
            otherPerformanceList: [],
            productSeriesList: [],
            productGlossList: [],
            productSurfaceList: [],
            productHierarchyList: [],
            factoryList: [],
            labelList: [],
            brandList: [],
            glossList: [],
            dataRule: {
                productNo: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('product_number') }),
                        trigger: 'blur'
                    }
                ],
                lAve: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('l_measurement') }),
                        trigger: 'blur'
                    }
                ],
                aAve: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('a_measurement') }),
                        trigger: 'blur'
                    }
                ],
                bAve: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('b_measurement') }),
                        trigger: 'blur'
                    }
                ],
                productCategory: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('product_category') }),
                        trigger: 'blur'
                    }
                ],
                surface: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('product_surface') }),
                        trigger: 'blur'
                    }
                ],
                glossValue: [
                    {
                        required: true,
                        message: this.$t('e706', { x: this.$t('gloss') }),
                        trigger: 'blur'
                    }
                ]
            },
            productAttrId: '',
            attrValue: '',
            imageurl: ''
        }
    },
    beforeDestroy() {
        window.clearInterval(this.timer)
        this.timer = null
        this.timerNum = 0
    },
    methods: {
        hanndlepImage(file) {
            this.imageurl = file.url
        },

        getProductAttrValue() {
            let params = {}
            getProductAttrValue(params).then(({ data }) => {
                if (data && data.code === 0) {
                    for (var i = 0; i < data.list.length; i++) {
                        if (data.list[i].productAttrType === '1') {
                            //表面
                            this.productSurfaceList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '3') {
                            //系列
                            this.productSeriesList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '6') {
                            //主题
                            this.productHierarchyList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '2') {
                            //色系
                            this.colorSeriesList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '7') {
                            //性能
                            this.performanceList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '4') {
                            //应用
                            this.industryList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '8') {
                            //功能
                            this.otherPerformanceList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '5') {
                            //分类
                            this.productCategoryList = data.list[i].productAttrValue
                        } else if (data.list[i].productAttrType === '9') {
                            //光泽
                            this.productGlossList = data.list[i].productAttrValue
                        }
                    }
                }
            })
        },
        getHex() {
            var params = {
                lab:
                    this.dataForm.lAve +
                    ',' +
                    this.dataForm.aAve +
                    ',' +
                    this.dataForm.bAve
            }
            getHex(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataForm.hex = data.hex
                }
            })
        },
        selectChildAttr() {
            let params = {
                attrValueId: this.dataForm.industryId
            }
            selectChildAttr(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.industryList_child = data.list
                } else {
                    this.industryList_child = []
                }
                this.dataForm.industryId_child = ''
            })
        },
        handleChange(val) {
            console.log(val)
        },
        //LAB值按钮隐藏显示
        isShowLAB() {
            this.showLAB = !this.showLAB
        },
        isBiaoZhun() {
            this.biaoZhun = !this.biaoZhun
        },

        //根据光泽测试值显示光泽范围名称
        selectGlossInfo() {
            this.dataForm.glossValue = this.dataForm.glossValue.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
            this.dataForm.glossValue = this.dataForm.glossValue.replace(
                /\.{2,}/g,
                '.'
            ) //只保留第一个. 清除多余的
            this.dataForm.glossValue = this.dataForm.glossValue
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
            this.dataForm.glossValue = this.dataForm.glossValue.replace(
                /^(\-)*(\d+)\.(\d).*$/,
                '$1$2.$3'
            ) //只能输入两个小数
            if (
                this.dataForm.glossValue.indexOf('.') < 0 &&
                this.dataForm.glossValue != ''
            ) {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                this.dataForm.glossValue = parseFloat(this.dataForm.glossValue)
            }
            // this.$set(this.dataForm.glossValue,e)
            this.$set(this.searchData, '1-' + this.productAttrId, '')
            let params = {
                glossValue: this.dataForm.glossValue
                    ? parseInt(this.dataForm.glossValue)
                    : ''
            }
            selectGlossInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataForm.gloss = this.localizeParam(data.data, 'name')
                    this.productAttrId = data.data.productAttrId
                    this.attrValue = data.data.id
                    this.$set(this.searchData, '1-' + this.productAttrId, this.attrValue)
                } else {
                    this.dataForm.gloss = ''
                }
            })
        },

        // 查询光泽值范围
        getGlossInfo() {
            let params = {}
            getGlossInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.glossList = data.data[0].options
                } else {
                    this.glossList = ''
                }
            })
        },
        getResultMap() {
            this.getAttrResultMap()
            this.getLabelResultMap()
        },
        getLabelResultMap() {
            let params = {
                brandId: this.dataForm.brandId,
                productNo: this.dataForm.productNo,
                productLabel: this.dataForm.productLabel
            }
            selectProductLabelInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataForm.productLabel = data.labelList
                }
            })
        },
        getAttrResultMap() {
            let params = {
                brandId: this.dataForm.brandId,
                productNo: this.dataForm.productNo,
                searchData: this.searchData
            }
            selectProductAttrInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.searchData = data.resultMap
                }
            })
        },
        getAttrAndValueListInfo: function () {
            this.searchData = {}
            this.searchForm = []
            this.tableCols = []
            var params = {
                type: 'detail',
                isUniversal: '0'
            }
            getAttrAndValueListInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    data.data.forEach((e) => {
                        var obj = { type: '', label: '', width: '180px' }
                        if (e.type == '1') {
                            // 如果是单选
                            obj.type = 'Select'
                            obj.options = e.options
                            obj.prop = e.type + '-' + e.productAttrId
                            obj.label = e.value
                            obj.isUniversal = e.isUniversal
                        }
                        if (e.type == '2') {
                            obj.type = 'SelectMultiple'
                            obj.options = e.options
                            obj.prop = e.type + '-' + e.productAttrId
                            obj.value = e.value
                            obj.isUniversal = e.isUniversal
                        }
                        if (e.type == '3') {
                            obj.type = 'Cascader'
                            obj.options = e.options
                            obj.prop = e.type + '-' + e.productAttrId
                            obj.value = e.value
                            obj.isUniversal = e.isUniversal
                        }
                        obj.label = this.localizeParam(e, 'label')
                        this.$set(this.searchData, e.productAttrId, e.value)
                        this.searchForm.push(obj)
                    })
                } else {
                    this.$message.error(data.msg)
                    this.loading2 = false
                }
            })
        },

        init(id, state, isDisabled, isCopy, addUpdateMethods) {
            this.addMethod = addUpdateMethods[0]
            this.updateMethod = addUpdateMethods[1]
            this.getAttrAndValueListInfo()
            this.getGlossInfo()
            this.getDataDetails(id + '')
            //进来就调用获取所有下拉列表
            this.isCopy = isCopy
            this.isDisabled = isDisabled
            this.dataForm.state = state
            this.dataForm.id = id || 0
            var titleMessage =
                this.$t('demand_list_details')
            this.$refs.SidesLip.init(titleMessage, '900px', 'block')
            this.getFactoryBrandList()
            this.getProductAttrValue()
            this.getFactoryList()
            this.getBrandList()
            this.getLabelList()
            this.loading = false
            this.visible = true
            this.$nextTick(() => {
                //清除缓存数据
                this.$refs['dataForm'].resetFields()
                this.dataForm.lAve = ''
                this.dataForm.aAve = ''
                this.dataForm.bAve = ''
                this.dataForm.l1 = ''
                this.dataForm.a1 = ''
                this.dataForm.b1 = ''
                this.dataForm.l2 = ''
                this.dataForm.a2 = ''
                this.dataForm.b2 = ''
                this.dataForm.l3 = ''
                this.dataForm.a3 = ''
                this.dataForm.b3 = ''
                this.dataForm.hex = ''
                this.dataForm.productNo = ''
                this.dataForm.productMainNo = ''
                this.dataForm.productComponentNo = ''
                this.dataForm.productVersionNo = ''
                this.dataForm.isBase = '0'
                this.dataForm.productImages = []
                this.isBaseCheck = false
                if (this.dataForm.id) {
                    info({ id: this.dataForm.id }).then(({ data }) => {
                        if (data && data.code === 0) {
                            this.dataForm.productOrder = data.product.productOrder
                            this.dataForm.productNo = data.product.productNo
                            this.dataForm.productMainNo = data.product.productMainNo
                            this.dataForm.productComponentNo = data.product.productComponentNo
                            this.dataForm.productVersionNo = data.product.productVersionNo
                            this.dataForm.productName = data.product.productName
                            this.dataForm.productImages = data.product.productImages
                            if (isCopy) {
                                // 如果在复制，必须把id去掉以免图片的关联产品被更新
                                if (this.dataForm.productImages.length > 0) {
                                    this.dataForm.productImages.forEach(image => {
                                        image.id = ''
                                    })
                                }
                                this.dataForm.productNo += '-Copy'
                            }
                            this.dataForm.picUrl = data.product.picUrl
                            this.dataForm.qrUrl = data.product.qrUrl
                            this.dataForm.ralColorNo = data.product.ralColorNo
                            this.dataForm.gloss = data.product.gloss
                            this.dataForm.glossValue = data.product.glossValue
                            this.dataForm.hex = data.product.hex
                            this.dataForm.surface = data.product.surface
                            this.dataForm.colorSeries = data.product.colorSeries
                            this.dataForm.productSeries = data.product.productSeries
                            this.dataForm.productPerformance = data.product.productPerformance
                            this.dataForm.otherPerformance = data.product.otherPerformance
                            this.dataForm.productCategory = data.product.productCategory
                            this.dataForm.productHierarchy = data.product.productHierarchy
                            this.dataForm.industryId = data.product.industryId
                            //获取
                            this.selectChildAttr()
                            this.dataForm.industryId_child = data.product.industryChildId
                            this.dataForm.factoryId = data.product.factoryId
                            this.dataForm.brandId = data.product.brandId
                            this.dataForm.description = data.product.description
                            this.dataForm.l1 = data.product.l1
                            this.dataForm.a1 = data.product.a1
                            this.dataForm.b1 = data.product.b1
                            this.dataForm.l2 = data.product.l2
                            this.dataForm.a2 = data.product.a2
                            this.dataForm.b2 = data.product.b2
                            this.dataForm.l3 = data.product.l3
                            this.dataForm.a3 = data.product.a3
                            this.dataForm.b3 = data.product.b3
                            this.dataForm.lAve = data.product.lAve
                            this.dataForm.aAve = data.product.aAve
                            this.dataForm.bAve = data.product.bAve
                            if (null != data.productLab) {
                                this.dataForm.lo = data.productLab.l
                                this.dataForm.ao = data.productLab.a
                                this.dataForm.bo = data.productLab.b
                                this.olabShow = true
                            } else {
                                this.olabShow = false
                            }
                            this.dataForm.test = data.product.test
                            this.dataForm.state = data.product.state
                            this.dataForm.productLabel = data.product.labelList
                            this.dataForm.isBase = data.product.isBase
                            if (data.product.isBase == 1) {
                                this.isBaseCheck = true
                            } else {
                                this.isBaseCheck = false
                            }
                            // 获取属性
                            this.searchData = data.resultMap
                            if (this.dataForm.productNo && !this.dataForm.productMainNo) {
                                this.dataForm.productMainNo = this.dataForm.productNo
                            }
                            this.setFactoryId()
                        }
                    })
                }
            })
        },
        async getDataDetails(id) {
            this.dataListLoading = false
            const res = await getRemoyeProofongDetails({ id: id })
            this.dataForm = Object.assign(this.dataForm, res.data.details)
            this.searchData = this.dataForm.searchData
        },
        close() {
            this.performanceList = []
            //清除缓存数据
            this.$refs['dataForm'].resetFields()
            this.$refs.SidesLip.sideClose()
        },
        //获取工厂和品牌
        getFactoryBrandList() {
            let params = {}
            getFactoryBrand(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.FactoryBrand = data.factoryBrand
                } else {
                    this.FactoryBrand = {}
                }
            })
        },
        //获取工厂列表
        getFactoryList() {
            let params = {}
            getFactory1(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.factoryList = data.list
                    if (
                        data.factoryId != null &&
                        data.factoryId != undefined &&
                        this.factoryList.length > 0
                    ) {
                        this.dataForm.makeFactory = this.factoryList[0].factoryName
                        this.setFactoryId()
                    }
                } else {
                    this.factoryList = []
                }
            })
        },
        //获取品牌列表
        getBrandList() {
            let params = {}
            getBrand(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.brandList = data.list
                } else {
                    this.brandList = []
                }
            })
        },
        //获取标签列表
        getLabelList() {
            let params = {
                all: '1'
            }
            selectLabelList(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.labelList = data.labelList
                } else {
                    this.labelList = []
                }
            })
        },
        setFactoryId() {
            this.factoryId = this.factoryList.find(
                (factory) => factory.factoryName == this.dataForm.makeFactory
            ).id
        },
        updateBaseCheck() {
            this.dataForm.isBase = this.isBaseCheck ? '1' : '0'
        },
        productNoChanged(data) {
            this.getResultMap()
            this.isProductNoValid = data.isProductNoValid
            this.dataForm.productNo = data.productNo
            this.dataForm.productMainNo = data.productMainNo
            this.dataForm.productComponentNo = data.productComponentNo
            this.dataForm.productVersionNo = data.productVersionNo
        }
    }
}
</script>
<style lang="scss" scoped>
.color-preview {
    height: 80px;
    width: 80px;
    margin-top: 10%;
}

.disabled-color-preview {
    height: 60px;
    width: 60px;
    margin-top: 0px;
}

.ziti {
    font-size: 12px;
    margin: 0px;
    line-height: 25px;
}

.productLAB {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: -50px;

    li {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 10px;

        .title {
            display: inline-block;
            width: 160px;
            text-align: center;
            color: #606266;
            font-size: 700;
        }

        .ptitle {
            display: inline-block;
            width: 160px;
            text-align: center;
            font-size: 10px;
        }
    }
}

.w100 {
    width: 100px;
}

.w150 {
    width: 115px;
}

.mr10 {
    margin-right: 5px;
}

.collapse-title {
    flex: 1 0 90%;
    color: #4ab7bd;
    font-weight: bolder;
    text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 18px;
}

.el-form-item__label {
    width: auto;
}
</style>