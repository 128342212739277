<template>
    <el-dialog
            :title="!dataForm.id ? '新增' : '修改'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            :append-to-body='true'>
        <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="80px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
            <el-form-item label="文章标题" prop="title">
                <el-input v-model="dataForm.title" placeholder="文章标题" size="small"></el-input>
            </el-form-item>
            <el-form-item label="显示位置" prop="application">
                <el-select v-model="dataForm.application" placeholder="广告类型" disabled>
                    <el-option label="小程序" value="0"></el-option>
                    <el-option label="门户网站" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="文章类型" prop="type">
                <el-select v-model="dataForm.type" size="small" placeholder="文章类型">
                    <el-option
                            v-for="item in ArticleCategoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="文章图片" prop="picUrl">
                <el-upload
                        class="avatar-uploader"
                        :action="action"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="简介" prop="summary">
                <el-input v-model="dataForm.summary" placeholder="简介" size="small"></el-input>
            </el-form-item>

            <el-form-item label="文章内容" prop="content">
                <tinymce ref="tinymce" style="width: 100%" v-model="dataForm.content" v-if="visible"/>
            </el-form-item>


        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
    </span>
    </el-dialog>
</template>
<script>
    import {info, add, update,fetchArticleCategory} from '@/api/modules/article'
    import {getToken} from '@/utils/auth';
    import Tinymce from "@/components/Tinymce";
    export default {
        components: {
            Tinymce//富文本编辑器
        },
        data() {
            return {
                action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
                visible: false,
                dataLoading: false,
                ArticleCategoryList:[],
                dataForm: {
                    id: 0,
                    title: '',
                    author: '',
                    summary: '',
                    content: '',
                    picUrl: '',
                    type: '',
                    application: '0',
                    pageviews: '',
                    state: '',
                    delFlag: '',
                    createBy: '',
                    createDate: '',
                    updateBy: '',
                    updateDate: '',
                    remarks: '',
                    brandId:'',
                },
                dataRule: {
                    title: [
                        {required: true, message: '标题不能为空', trigger: 'blur'}
                    ],
                    summary: [
                        {required: true, message: '简介不能为空', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '文章内容不能为空', trigger: 'blur'}
                    ],
                    picUrl: [
                        {required: true, message: '展示图片不能为空', trigger: 'blur'}
                    ],
                    type: [
                        {required: true, message: '文章类型不能为空', trigger: 'blur'}
                    ],
                    application: [
                        {required: true, message: '应用（0.小程序，1.网站，2.两者）不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            //文章类别下拉
            getCategory(){
                fetchArticleCategory().then(({data}) => {
                    if (data && data.code === 0) {
                        this.ArticleCategoryList = data.ArticleCategory
                    } else {
                        this.ArticleCategoryList = []
                    }
                })
            },

            init(id) {
                this.getCategory();
                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (this.dataForm.id) {
                        info({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.title = data.article.title
                                this.dataForm.author = data.article.author
                                this.dataForm.summary = data.article.summary
                                this.dataForm.content = data.article.content
                                this.dataForm.picUrl = data.article.picUrl
                                this.dataForm.type = data.article.type
                                this.dataForm.application = data.article.application
                                this.dataForm.pageviews = data.article.pageviews
                                this.dataForm.state = data.article.state
                                this.dataForm.delFlag = data.article.delFlag
                                this.dataForm.createBy = data.article.createBy
                                this.dataForm.createDate = data.article.createDate
                                this.dataForm.updateBy = data.article.updateBy
                                this.dataForm.updateDate = data.article.updateDate
                                this.dataForm.remarks = data.article.remarks
                                this.dataForm.brandId = data.article.brandId
                            }
                        })
                    }
                })
            },

            //图片上传
            handleAvatarSuccess(res, file, type) {
                if (res && res.code === 0) {
                    this.dataForm.picUrl = res.data.fileUrl;
                } else {
                    this.$message.error(res.msg);
                }
            },
            beforeAvatarUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/bmp" ||
                    file.type === "image/png";
                const isLt2M = file.size / 1024 / 1024 < 8;
                if (!isJPG) {
                    this.$message.error("上传图片格式不支持!");
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 8MB!");
                }
                return isJPG && isLt2M;
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.dataForm.id || undefined,
                            'articleOperationType': this.dataForm.id ? '1' : null,
                            'title': this.dataForm.title,
                            'author': this.dataForm.author,
                            'summary': this.dataForm.summary,
                            'content': this.dataForm.content,
                            'picUrl': this.dataForm.picUrl,
                            'type': this.dataForm.type,
                            'application': this.dataForm.application,
                            'pageviews': this.dataForm.pageviews,
                            'state': this.dataForm.id ? '0' : this.dataForm.state,
                            'delFlag': this.dataForm.delFlag,
                            'createBy': this.dataForm.createBy,
                            'createDate': this.dataForm.createDate,
                            'updateBy': this.dataForm.updateBy,
                            'updateDate': this.dataForm.updateDate,
                            'remarks': this.dataForm.remarks,
                            'brandId': this.dataForm.brandId,
                        }
                        var tick = !this.dataForm.id ? add(params) : update(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        max-height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        max-height: 178px;
        display: block;
    }
</style>
