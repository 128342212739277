import { render, staticRenderFns } from "./cpdetails.vue?vue&type=template&id=f14dd048&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./cpdetails.vue?vue&type=script&lang=js&"
export * from "./cpdetails.vue?vue&type=script&lang=js&"
import style0 from "./cpdetails.vue?vue&type=style&index=0&id=f14dd048&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f14dd048",
  null
  
)

export default component.exports