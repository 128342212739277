<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        label-width="230px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item :label="$t('factory')" prop="factoryId">
          <el-select
            v-model="dataForm.factoryId"
            size="small"
            :placeholder="$t('select_factory')"
            @change="handleFactoryChange"
            :disabled="disabled"
          >
            <el-option
              v-for="item in factoryList"
              :key="item.id"
              :label="item.factoryName"
              :value="item.id"
              size="small"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('product_no_regex')" prop="productNoRegex">
          <el-input
            type="textarea"
            :rows="4"
            v-model="dataForm.productNoRegex"
            :placeholder="$t('product_no_regex')"
            size="small"
            maxlength="2000"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_main_no_description_chinese')"
          prop="productMainNoDescription"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productMainNoDescription"
            :placeholder="$t('product_main_no_description_chinese')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_main_no_description_english')"
          prop="productMainNoDescriptionEn"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productMainNoDescriptionEn"
            :placeholder="$t('product_main_no_description_english')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_main_no_regex')"
          prop="productMainNoRegex"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productMainNoRegex"
            :placeholder="$t('product_main_no_regex')"
            size="small"
            maxlength="2000"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('include_x', { x: $t('product_component_no') })"
        >
          <template>
            <el-checkbox
              v-model="dataForm.includeComponentNo"
              size="small"
              :disabled="disabled"
            ></el-checkbox>
          </template>
        </el-form-item>
        <el-form-item
          :label="$t('product_component_no_description_chinese')"
          prop="productComponentNoDescription"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productComponentNoDescription"
            :placeholder="$t('product_component_no_description_chinese')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled || !dataForm.includeComponentNo"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_component_no_description_english')"
          prop="productComponentNoDescriptionEn"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productComponentNoDescriptionEn"
            :placeholder="$t('product_component_no_description_english')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled || !dataForm.includeComponentNo"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_component_no_regex')"
          prop="productComponentNoRegex"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productComponentNoRegex"
            :placeholder="$t('product_component_no_regex')"
            size="small"
            maxlength="255"
            show-word-limit
            :disabled="disabled || !dataForm.includeComponentNo"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('include_x', { x: $t('product_version_no') })">
          <el-checkbox
            v-model="dataForm.includeVersionNo"
            size="small"
            :disabled="disabled"
          ></el-checkbox>
        </el-form-item>
        <el-form-item
          :label="$t('product_version_no_description_chinese')"
          prop="productVersionNoDescription"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productVersionNoDescription"
            :placeholder="$t('product_version_no_description_chinese')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled || !dataForm.includeVersionNo"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_version_no_description_english')"
          prop="productVersionNoDescriptionEn"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productVersionNoDescriptionEn"
            :placeholder="$t('product_version_no_description_english')"
            size="small"
            maxlength="100"
            show-word-limit
            :disabled="disabled || !dataForm.includeVersionNo"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_version_no_regex')"
          prop="productVersionNoRegex"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="dataForm.productVersionNoRegex"
            :placeholder="$t('product_version_no_regex')"
            size="small"
            maxlength="255"
            show-word-limit
            :disabled="disabled || !dataForm.includeVersionNo"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('base_suffix')" prop="baseSuffix">
          <el-input
            v-model="dataForm.baseSuffix"
            :placeholder="$t('base_suffix')"
            size="small"
            maxlength="30"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_no_validation_instructions_chinese')"
          prop="instructions"
        >
          <el-input
            type="textarea"
            :rows="8"
            v-model="dataForm.instructions"
            :placeholder="$t('product_no_validation_instructions_chinese')"
            size="small"
            maxlength="2000"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('product_no_validation_instructions_english')"
          prop="instructionsEn"
        >
          <el-input
            type="textarea"
            :rows="8"
            v-model="dataForm.instructionsEn"
            :placeholder="$t('product_no_validation_instructions_english')"
            size="small"
            maxlength="2000"
            show-word-limit
            :disabled="disabled"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">{{ $t('cancel') }}</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >{{ $t('confirm') }}</el-button
        >
      </span>
    </div>
    <!-- </el-dialog> -->
  </sides-lip>
</template>

<script>
import { info, add, update } from '@/api/modules/productNoValidation'

import SidesLip from '@/components/SidesLip'
import { getFactory } from '../../api/modules/product'
export default {
  components: {
    SidesLip
  },
  data() {
    return {
      visible: false,
      disabled: false,
      dataLoading: false,
      factoryList: [],
      pageTitle: '',
      isCopy: false,
      dataForm: {
        id: '',
        brandId: '',
        factoryId: '',
        productNoRegex: '',
        productMainNoRegex: '',
        includeComponentNo: false,
        productComponentNoRegex: '',
        includeVersionNo: false,
        productVersionNoRegex: '',
        productMainNoDescription: '',
        productComponentNoDescription: '',
        productVersionNoDescription: '',
        productMainNoDescriptionEn: '',
        productComponentNoDescriptionEn: '',
        productVersionNoDescriptionEn: '',
        baseSuffix: '',
        instructions: '',
        instructionsEn: ''
      },
      dataRule: {
        factoryId: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('factory') }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getFactoryList()
  },
  methods: {
    init(id, disabled, isCopy) {
      this.isCopy = false
      this.dataForm.id = id || 0
      this.disabled = disabled
      this.visible = true

      if (!id) {
        this.pageTitle = this.$t('create_new')
      } else if (disabled) {
        this.pageTitle = this.$t('view')
      } else if (isCopy) {
        this.pageTitle = this.$t('copy')
        this.isCopy = true
      } else {
        this.pageTitle = this.$t('edit')
      }

      this.$refs.SidesLip.init(this.pageTitle, '900px', 'block')

      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          info({ id: this.dataForm.id }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.productNoValidation
            }
          })
        }
      })
    },
    getFactoryList() {
      getFactory().then(({ data }) => {
        if (data && data.code === 0) {
          this.factoryList = data.list
        } else {
          this.factoryList = []
        }
      })
      this.$forceUpdate()
    },
    handleFactoryChange() {
      this.dataForm.brandId = this.factoryList.filter(
        (item) => item.id == this.dataForm.factoryId
      )[0].brandId
    },
    close() {
      this.performanceList = []
      //清除缓存数据
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          let params = this.dataForm
          if (this.isCopy || this.dataForm.id == '') {
            params.id = undefined
          }
          var tick = !this.dataForm.id ? add(params) : update(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                  this.$refs.SidesLip.sideClose()
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    }
  }
}
</script>
