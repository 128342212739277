import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/product/productnovalidation/list',
    method: 'get',
    params: params
  })
}

export function info (params) {
  return request({
    url: '/product/productnovalidation/info',
    method: 'get',
    params: params
  })
}

export function getProductNoValidation (params) {
  return request({
    url: '/product/productnovalidation/getProductNoValidation',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/product/productnovalidation/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/product/productnovalidation/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
  return request({
    url: '/product/productnovalidation/delete',
    method: 'post',
    data: params
  })
}
