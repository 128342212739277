import { render, staticRenderFns } from "./formDisable.vue?vue&type=template&id=2380c0a9&"
import script from "./formDisable.vue?vue&type=script&lang=js&"
export * from "./formDisable.vue?vue&type=script&lang=js&"
import style0 from "./formDisable.vue?vue&type=style&index=0&id=2380c0a9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports