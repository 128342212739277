<template>
  <div class="app-container">
    <el-table :data="dataList" border size="small" v-loading="dataListLoading"
      :default-sort="{ prop: 'createDate', order: 'descending' }" @selection-change="selectionChangeHandle"
      @sort-change="sortChange" style="width: 100%;">
      <el-table-column prop="orderNumber" header-align="center" align="center" fixed="left" :label="$t('order_number')">
      </el-table-column>
      <el-table-column prop="submitToFactory" header-align="center" align="center" fixed="left" :label="$t('submit_factory')">
      </el-table-column>
      <el-table-column prop="submitToUser" header-align="center" align="center" fixed="left" :label="$t('submit_user')">
      </el-table-column>
      <el-table-column prop="picUrl" header-align="center" align="center" :label="$t('product_image')">
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <el-image v-if="scope.row.picUrl" :src="scope.row.picUrl" :preview-src-list="srcPicUrlList" min-width="70"
            height="70"></el-image>
          <div v-else :style="{
            backgroundColor: scope.row.hex || scope.row.Hex,
            height: '70px',
            minWidth: '70px'
          }"></div>
        </template>
      </el-table-column>
      <el-table-column prop="gloss" header-align="center" align="center" :label="$t('gloss')">
      </el-table-column>
      <el-table-column prop="productCategory" header-align="center" align="center" :label="$t('product_category')">
      </el-table-column>
      <el-table-column prop="productSeries" header-align="center" align="center" :label="$t('serles')">
      </el-table-column>

      <el-table-column prop="materialSum" header-align="center" align="center" :label="$t('sample_poweder_quantity')">
      </el-table-column>
      <el-table-column prop="modelSum" header-align="center" align="center" :label="$t('sample_panel_quantity')">
      </el-table-column>
      <el-table-column prop="deliverDate" header-align="center" align="center" :sortable="'custom'" width="100px;"
        :label="$t('expected_completion_date')">
        <template slot-scope="scope">
          <span>{{ scope.row.deliverDate | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" header-align="center" align="center" :sortable="'custom'" width="100px;"
        :label="$t('submission_date')">
        <template slot-scope="scope">
          <span>{{ scope.row.createDate  }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" :label="$t('action')">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="viewRemoteProofingHandle(scope.row.id)">{{$t('view')}}</el-button>
          <el-button type="text" size="small" @click="copyData(scope.row.id)">{{$t('copy_data')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 新增远程打样 -->
    <view-remote-proofing ref="viewRemoteProofing" @refreshDataList="getDataDetails"
      v-if="viewRemoteProofingVisible"></view-remote-proofing>
  </div>
</template>
  
<script>
import { getRemoyeProofongList, getRemoyeProofongDetails ,copyProdectAttr} from '@/api'
import viewRemoteProofing from './view-remote-proofing'
import {
  getAttrTableCols, getAttrAndValueListInfo
} from '@/api/modules/productattr'
import {
  list,
  changePro,
  importExcel as importProductOrder,
  add as addLab,
  update as updateLab
} from '@/api/modules/productorder'
export default {
  data() {
    return {
      hex: '',
      pageIndex: 1,
      dataList: [],
      totalPage: 1,
      pageSize: 10,
      totalCount: 0,
      currentPage: 1,
      viewRemoteProofingVisible: false,
      dataListLoading: false,
      detailVisible: true,
      srcPicUrlList: [],
      id: 0,
      pageName: 'productAll',
      progress: 0,
      isdisabled: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timer_: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      is_query: false,
      testList: [
        {
          value: '0',
          label: this.$t('no')
        },
        {
          value: '1',
          label: this.$t('yes')
        }
      ],
      dataForm: {
        srcPicUrlList: [],
        productSeries: '',
        productName: '',
        id: '',
        productNo: '',
        picUrl: '',
        qrUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        surface: '',
        hasProductNum: '-1',
        isNomasge: '-1',
        isBase: '-1',
        colorSeries: '',
        productPerformance: '',
        otherPerformance: '',
        productCategory: '',
        productHierarchy: '',
        industryId: '',
        industryId_child: '',
        factoryId: '-1',
        brandId: '',
        description: '',
        labAve: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        productOrder: '',
        state: '',
        integral: '0',
        isIntegral: '0'
      },
      dataInfo: {
        name: '',
        info: '',
        alias: '',
        type: ''
      },
      searchForm: [
      ],
      searchData: {
      },
      notIncluded: [],
      tableCols: [
      ],

      pagination: {
        //分页数据
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      createDates: [],
      resultList: [],
      colorSeriesList: [], //色系
      productCategoryList: [], //产品分类
      productHierarchyList: [], //产品主题
      productSeriesList: [], //产品系列集合
      industryList: [],
      industryList_child: [],
      productPerformanceList: [],
      productSurfaceList: [],
      productAttrAndValueVoList: [],
      productGlossList: [],
      otherPerformanceList: [],
      loading: false,
      loading2: false,
      dataListSelections: [],
      brandList: [],
      factoryList: [],
      brandState: false,
      factoryState: false
    }
  },
  components: {
    viewRemoteProofing
  },
  async created() {
    await this.getAttrTableCols()
    await this.getRemoyeProofongLists(1)
    await this.getAttrAndValueListInfo()

  },
  filters: {
    formatDate(val) {
      if (!val) return '- -';
      var date = new Date(val.replace(/-/g, '/'));
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      var day = date.getDate();
      day = day < 10 ? '0' + day : day;
      return year + '-' + month + '-' + day;
    }
  },
  methods: {
    selectionChangeHandle() {},
    sortChange() {},
    sizeChangeHandle(size) {
      this.getRemoyeProofongLists(this.currentPage, size)
    },

    currentChangeHandle(index) {
      this.currentPage = index
      this.getRemoyeProofongLists(index)
    },
    //详情
    viewRemoteProofingHandle(id, isDisabled = true, isCopy = false) {
      this.viewRemoteProofingVisible = true
      let addUpdateMethods =
        this.state == 1
      this.$nextTick(() => {
        this.$refs.viewRemoteProofing.init(
          id,
          this.state,
          isDisabled,
          isCopy,
          addUpdateMethods
        )
      })
    },
    copyData(id) {
      var params = {
       id:id
      }
      var tick = copyProdectAttr({ ...params })
      tick.then(({ data }) => {
        if (data && data.code === 0) {
          navigator.clipboard.writeText(data.copyInfo)
          this.$message({
            message: this.$t('copy_success'),
            type: 'success',
            duration: 1500,
          })
        } else {
          this.$message.error(data.msg)
        }
      })
      

    },
    getAttrTableCols: function () {
      this.tableCols = []
      getAttrTableCols({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          this.tableCols = data.data
          // this.getRemoyeProofongLists()
        }
      })
    },
    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.notIncluded = []
      getAttrAndValueListInfo({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '130px' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
              obj.notIncluded = true
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.notIncluded = true
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.notIncluded = true
            }
            obj.label = this.localizeParam(e, 'label')
            var obj_ = {
              children: [],
              label: this.$t('other'),
              value: '0',
              valueProductAttrId: '0'
            }
            obj.options.push(obj_)
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },
   
    async getDataDetails(id) {
      this.dataListLoading = false
      const res = await getRemoyeProofongDetails({ id: id })
    },
    async getRemoyeProofongLists(page = 1, size = 10) {
      const res = await getRemoyeProofongList({ page: page, limit: size })
      if (res.data.page.list) {
        this.dataList = res.data.page.list
        this.totalPage = res.data.page.totalCount
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.select {
  color: #7679ff !important;
}
</style>
  