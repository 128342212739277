<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="clearfix">
        <span>{{ $t('search') }}</span>
      </div>
      <el-form
        :inline="true"
        size="small"
        :model="dataForm"
        ref="dataForm"
        @keyup.enter.native="restPage()"
      >
        <el-form-item :label="$t('date_of_upload')" prop="createDates">
          <div class="block">
            <el-date-picker
              v-model="dataForm.createDates"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              :start-placeholder="$t('search_from')"
              :end-placeholder="$t('search_to')"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item :label="$t('update_status')" prop="uploadState">
          <el-select v-model="dataForm.uploadState" size="small" placeholder="">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('importing')" value="0"></el-option>
            <el-option
              :label="$t('pending_confirmation')"
              value="1"
            ></el-option>
            <el-option :label="$t('updating')" value="2"></el-option>
            <el-option :label="$t('import_completed')" value="3"></el-option>
            <el-option :label="$t('import_cancelled')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" :label="$t('completed_by')">
          <el-input
            v-model="dataForm.name"
            :placeholder="$t('completed_by')"
            size="small"
            clearable
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="restPage()" type="primary" size="small">{{
            $t('search')
          }}</el-button>
          <el-button @click="reset('dataForm')" type="primary" size="small">{{
            $t('clear')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 30px">
      <div slot="header" class="clearfix">
        <span>{{ $t('import_record_table') }}</span>
      </div>
      <el-table
        :data="dataList"
        border
        size="small"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          prop="uploadTime"
          header-align="center"
          align="center"
          :label="$t('date_of_upload')"
        >
        </el-table-column>
        <el-table-column
          prop="uploadState"
          header-align="center"
          align="center"
          :label="$t('update_status')"
        >
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.uploadState == '0'">{{
              $t('importing')
            }}</el-tag>
            <el-tag type="warning" v-if="scope.row.uploadState == '1'">{{
              $t('pending_confirmation')
            }}</el-tag>
            <el-tag v-if="scope.row.uploadState == '2'">{{
              $t('updating')
            }}</el-tag>
            <el-tag type="success" v-if="scope.row.uploadState == '3'">{{
              $t('import_completed')
            }}</el-tag>
            <el-tag type="danger" v-if="scope.row.uploadState == '4'">{{
              $t('import_cancelled')
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="confirmTime"
          header-align="center"
          align="center"
          :label="$t('date_confirmed')"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          :label="$t('completed_by')"
        >
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="$t('action')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.uploadState == '1'"
              type="text"
              size="small"
              @click="importPreviewHandle(scope.row.id, true)"
              >{{ $t('view_import_changes') }}</el-button
            >
            <el-button
              v-if="
                scope.row.uploadState == '2' ||
                scope.row.uploadState == '3' ||
                scope.row.uploadState == '4'
              "
              type="text"
              size="small"
              @click="importPreviewHandle(scope.row.id)"
              >{{$t('view_details')}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <import-preview
      v-if="importPreviewVisible"
      ref="importPreview"
      @refreshDataList="getDataList"
    ></import-preview>
  </div>
</template>

<script>
import { selectUpdateInfoList } from '@/api/modules/productImportRecord'
import ImportPreview from './importPreview'
export default {
  data() {
    return {
      dataForm: {
        name: '',
        uploadState: '',
        createDates: []
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      importPreviewVisible: false,
      viewDetailsVisible: false,
      addOrUpdateVisible: false
    }
  },
  components: {
    ImportPreview
  },
  activated() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.dataForm.uploadState = ''
      this.dataForm.createDates = []
      this.restPage()
    },
    // 根据条件获取数据列表
    getDataList() {
      this.dataListLoading = true
      var startDate = null
      var endDate = null
      if (this.dataForm.createDates && this.dataForm.createDates.length > 0) {
        startDate = this.dataForm.createDates[0]
        endDate = this.dataForm.createDates[1]
      }
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        name: this.dataForm.name,
        uploadState: this.dataForm.uploadState,
        startDate: startDate ? startDate + ' 00:00:00' : '',
        endDate: endDate ? endDate + ' 23:59:59' : ''
      }
      selectUpdateInfoList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    //导入预览Import Preview//产看详情
    importPreviewHandle(id, show) {
      this.importPreviewVisible = true
      this.$nextTick(() => {
        this.$refs.importPreview.init(id, show)
      })
    }
  }
}
</script>
