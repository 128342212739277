<template>
  <div>
    <div v-if="!this.isAuthorized()">{{ $t('e401') }}</div>
    <div v-if="this.isAuthorized()">
      <el-card class="box-pro">
        <div slot="header" class="clearfix">
          <span>{{ $t('product_search') }}</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <el-row :gutter="20">
          <el-col :span="collapseState ? 18 : 24">
            <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
              <el-form-item :label="$t('batch_number')" prop="productOrder">
                <el-input v-model="dataForm.productOrder" :placeholder="$t('batch_number')" size="small" clearable
                  maxlength="30"></el-input>
              </el-form-item>
              <el-form-item :label="$t('product_number')" prop="productNo">
                <el-input v-model="dataForm.productNo" :placeholder="$t('product_number')" size="small"
                  maxlength="30"></el-input>
              </el-form-item>
              <el-form-item :label="$t('product_name')" prop="productName">
                <el-input v-model="dataForm.productName" :placeholder="$t('product_name')" size="small" clearable
                  maxlength="30"></el-input>
              </el-form-item>
              <el-form-item :label="$t('gloss_value')">
                <el-input-number maxlength="30" controls-position="right" size="small" v-model="dataForm.glossNumDw"
                  :min="0" :max="999"></el-input-number>
                <span style="margin-left: 10px; margin-right: 10px;">至</span>
                <el-input-number maxlength="30" controls-position="right" size="small" v-model="dataForm.glossNumUp"
                  :min="0" :max="999"></el-input-number>
              </el-form-item>
              <el-form-item label="L*a*b*" class="lab">
                <el-input v-model="dataForm.lAve" placeholder="L*" class="w100 mr2" size="small" clearable
                  maxlength="30"></el-input>
                <el-input v-model="dataForm.aAve" placeholder="a*" class="w100 mr2" size="small" clearable
                  maxlength="30"></el-input>
                <el-input v-model="dataForm.bAve" placeholder="b*" class="w100 mr2" size="small" clearable
                  maxlength="30"></el-input>

                <read-device @read="dataReceived" />
              </el-form-item>
              <el-form-item :label="$t('brand')" v-if="brandState">
                <el-select v-model="dataForm.brandId" size="small" :placeholder="$t('choose_brand')"
                  @change="brandOnchange()">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option v-for="item in brandList" :key="item.id" :label="item.brandName" :value="item.id"
                    size="small"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('product_factory')" v-if="factoryState">
                <el-select v-model="dataForm.factoryId" size="small" :placeholder="$t('select_factory')">
                  <el-option :label="$t('all')" value="-1"></el-option>
                  <el-option v-for="item in factoryList" :key="item.id" :label="item.factoryName" :value="item.id"
                    size="small"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('is_base')">
                <el-select v-model="dataForm.isBase" size="small" :placeholder="$t('please_select')">
                  <el-option :label="$t('please_select')" value="-1"></el-option>
                  <el-option v-for="item in testList" :key="item.value" :label="item.label" :value="item.value"
                    size="small"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('inventory')">
                <el-select v-model="dataForm.hasProductNum" size="small" :placeholder="$t('please_select')">
                  <el-option :label="$t('all')" value="-1"></el-option>
                  <el-option :label="$t('in_stock')" value="0"></el-option>
                  <el-option :label="$t('out_of_stock')" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('is_integrating_sphere')">
                <el-select v-model="dataForm.integral" size="small" :placeholder="$t('is_integrating_sphere')">
                  <el-option :label="$t('all')" value="0"></el-option>
                  <el-option :label="$t('integrating_sphere')" value="1"></el-option>
                </el-select>
              </el-form-item>
              <br v-if="brandState && factoryState" />
              <el-form-item :label="$t('date_created')">
                <div class="block">
                  <el-date-picker v-model="createDates" value-format="yyyy-MM-dd" type="daterange" range-separator="-"
                    :start-placeholder="$t('search_from')" :end-placeholder="$t('search_to')">
                  </el-date-picker>
                </div>
              </el-form-item>
              <!-- 是否有图片 -->
              <el-form-item :label="$t('is_Nomasge')" style="margin-left: 113px;">
                <el-select v-model="dataForm.isNomasge" size="small" :placeholder="$t('please_select')">
                  <el-option :label="$t('all')" value="-1"></el-option>
                  <el-option :label="$t('has_image')" value="0"></el-option>
                  <el-option :label="$t('no_image')" value="1"></el-option>
                </el-select>
              </el-form-item>

              <div class="ces-main">
                <search-form ref="form" size="mini" labelWidth="50px" :searchData="searchData" :searchForm="searchForm"
                  :notIncluded="notIncluded"></search-form>
              </div>
              <template v-if="!collapseState">
                <div style="
                    text-align: right;
                    min-width: 205px;
                    right: 0;
                    bottom: 20px;
                  ">
                  <el-button @click="restPage()" size="small">{{
                    $t('search')
                    }}</el-button>
                  <el-button @click="reset('dataForm')" size="small">{{
                    $t('clear_all')
                    }}</el-button>
                  <el-button @click="handleCollapse" type="text">{{ collapseState ? $t('expand') : $t('minimize')
                    }}<i :class="[
                      collapseState
                        ? 'el-icon-arrow-down'
                        : 'el-icon-arrow-up',
                      'el-icon--right'
                    ]"></i></el-button>
                </div>
              </template>
            </el-form>
          </el-col>
          <template v-if="collapseState">
            <el-col :span="6">
              <div style="text-align: right; min-width: 205px">
                <el-button @click="restPage()" size="small">{{
                  $t('search')
                  }}</el-button>
                <el-button @click="reset('dataForm')" size="small">{{
                  $t('clear_all')
                  }}</el-button>
                <el-button @click="handleCollapse" type="text">{{ collapseState ? $t('expand') : $t('minimize')
                  }}<i :class="[
                    collapseState ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                    'el-icon--right'
                  ]"></i></el-button>
              </div>
            </el-col>
          </template>
        </el-row>
      </el-card>

      <el-card style="margin-top: 30px">
        <div slot="header" class="clearfix">
          <span>{{ $t('product_list') }}</span>
          <div style="float: right; padding: 3px 0">
            <el-button icon="el-icon-plus" v-if="
              this.state.length != 0 &&
              this.state != '0' &&
              isAuth(`product:${pageName}:save`)
            " type="primary" @click="addOrUpdateHandle()" size="small">{{ $t('create_new') }}
            </el-button>
            <el-dropdown trigger="click" v-if="
              this.state != '0' &&
              (isAuth(`product:${pageName}:export`) ||
                (this.state.length == 0 &&
                  isAuth(`product:${pageName}:importinventory`)) ||
                isAuth(`product:${pageName}:downloadimport`) ||
                isAuth(`product:${pageName}:import`))
            ">
              <span class="el-dropdown-link" style="margin-left: 10px; cursor: pointer">
                {{ $t('manage')
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button v-if="isAuth(`product:${pageName}:export`)" type="text" @click="exportExcelProduct()"
                    size="small" :loading="exportloading">{{ $t('export_product_data') }}
                  </el-button>
                </el-dropdown-item>

                <el-dropdown-item>
                  <el-button v-if="isAuth(`product:${pageName}:export`)" type="text" @click="exportExcelProductData()"
                    size="small" :loading="exportloading">{{ $t('export_product_data_toTiger') }}
                  </el-button>
                </el-dropdown-item>


                <el-dropdown-item>
                  <el-button v-if="
                    this.state != '0' &&
                    isAuth(`product:${pageName}:downloadimport`)
                  " type="text" @click="exportExcelHandle(false)" size="small">{{ $t('product_import_template') }}
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state != '0' && isAuth(`product:${pageName}:import`)
                  " type="text" @click="uploadHandle(false, false)" size="small" :disabled="dataList.length < 0">{{
                    $t('import_products') }}</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state.length == 0 &&
                    isAuth(`product:${pageName}:downloadimportsphere`)
                  " type="text" @click="exportExcelHandle(true)" size="small">{{
                    $t('integrating_sphere_import_template') }}
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state.length == 0 &&
                    isAuth(`product:${pageName}:importsphere`)
                  " type="text" @click="uploadHandle(true, false)" size="small" :disabled="dataList.length < 0">{{
                    $t('import_integrating_sphere_data') }}</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state.length == 0 &&
                    isAuth(`product:${pageName}:importinventory`)
                  " type="text" @click="exportExcelHandle(false, true)" size="small" :disabled="dataList.length < 0">{{
                    $t('inventory_import_template') }}</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state.length == 0 &&
                    isAuth(`product:${pageName}:importinventory`)
                  " type="text" @click="uploadHandle(false, true)" size="small" :disabled="dataList.length < 0">{{
                    $t('import_inventory') }}</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="
                    this.state.length == 0 &&
                    isAuth(`product:${pageName}:importproductimages`)
                  " type="text" @click="uploadImagesHandle()" size="small" :disabled="dataList.length < 0">{{
                    $t('import_product_images') }}</el-button>
                </el-dropdown-item>
                <el-dropdown-item> </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
          style="width: 100%">
          <el-table-column prop="productOrder" header-align="center" align="center" :label="$t('batch_number')">
          </el-table-column>
          <el-table-column prop="productNo" header-align="center" align="center" :label="$t('product_number')"
            width="120">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <img :src="scope.row.qrUrl" min-width="100" height="100" />
                <div slot="reference">
                  <span style="font-weight: 600; color: green">{{
                    scope.row.productNo
                    }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="productName" header-align="center" align="center" :label="$t('product_name')">
          </el-table-column>
          <el-table-column prop="picUrl" header-align="center" align="center" :label="$t('product_image')">
            <!-- 图片的显示 -->
            <template slot-scope="scope">
              <el-image v-if="scope.row.picUrl" :src="scope.row.picUrl" :preview-src-list="srcPicUrlList" min-width="70"
                height="70"></el-image>
              <div v-else :style="{
                backgroundColor: scope.row.hex || scope.row.Hex,
                height: '70px',
                minWidth: '70px'
              }"></div>
            </template>
          </el-table-column>
          <el-table-column prop="labAve" header-align="center" align="center" label="L*a*b*" width="230">
            <template slot-scope="scope">
              <span>25°L*:{{ scope.row.l1 | numFilter }}</span>
              <span>|a*:{{ scope.row.a1 | numFilter }}|</span>
              <span>b*:{{ scope.row.b1 | numFilter }}</span>
              <p></p>
              <span>45°L*:{{ scope.row.l2 | numFilter }}</span>
              <span>|a*:{{ scope.row.a2 | numFilter }}|</span>
              <span>b*:{{ scope.row.b2 | numFilter }}</span>
              <p></p>
              <span>75°L*:{{ scope.row.l3 | numFilter }}</span>
              <span>|a*:{{ scope.row.a3 | numFilter }}|</span>
              <span>b*:{{ scope.row.b3 | numFilter }}</span>
              <p></p>
              <div v-if="scope.row.L && scope.row.A && scope.row.B">
                <span>D8 L*:{{ scope.row.L | numFilter }} </span>
                <span>|A*:{{ scope.row.A | numFilter }}|</span>
                <span>B*: {{ scope.row.B | numFilter }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="glossValue" header-align="center" align="center" :label="$t('gloss_value')">
          </el-table-column>
          <el-table-column prop="productNum" header-align="center" align="center" :label="$t('inventory')">
          </el-table-column>
          <template v-for="item in tableCols">
            <el-table-column :prop="localizeParam(item, 'label')" :label="localizeParam(item, 'label')"
              :key="localizeParam(item, 'label')" header-align="center" align="center">
            </el-table-column>
          </template>
          <el-table-column v-if="is_query" prop="e00Value" header-align="center" align="center"
            :label="$t('color_difference')">
          </el-table-column>
          <el-table-column prop="createDate" header-align="center" align="center" :label="$t('date_created')">
          </el-table-column>
          <el-table-column prop="updateDate" header-align="center" align="center" :label="$t('date_updated')">
          </el-table-column>
          <el-table-column prop="name" header-align="center" align="center" :label="$t('created_by')">
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="150" :label="$t('action')">
            <template slot-scope="scope">
              <el-button v-if="isAuth(`product:${pageName}:update`)" type="text" size="small"
                @click="addOrUpdateHandle(scope.row.id)">{{ $t('edit') }}</el-button>
              <el-button v-if="isAuth(`product:${pageName}:update`)" type="text" size="small"
                @click="addOrUpdateHandle(scope.row.id, false, true)">{{ $t('copy') }}</el-button>
              <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id, true)"
                v-if="isAuth(`product:${pageName}:view`)">{{ $t('view') }}</el-button>
              <el-dropdown trigger="click" v-if="
                isAuth(`product:${pageName}:print`) ||
                isAuth(`product:${pageName}:delete`)
              ">
                <span class="el-dropdown-link" style="font-size: 12px; margin-left: 10px; cursor: pointer">
                  {{ $t('more')
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button v-if="isAuth(`product:${pageName}:print`)" type="text" size="small" style="color: green"
                      @click="printPreviewHandle(scope.row.id)">{{ $t('print') }}</el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button v-if="
                      isAuth(`product:${pageName}:changingproduction`) &&
                      scope.row.state == '1'
                    " type="text" size="small" @click="zc(scope.row.id)">{{ $t('to_production') }}</el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button v-if="isAuth(`product:${pageName}:delete`)" type="text" size="small" style="color: red"
                      @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--点击配方，跳转到展示产品配方的页面，显示当前产品的配方，应该不可以做修改，只是展示 ，传递过去的值应该是产品ID,根据产品ID查询配方，调涂春明的接口和页面-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
          :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-card>
      <el-dialog title="" class="loading_dialog" :visible.sync="exportloading" :close-on-click-modal="false"
        :show-close="true" @close="closeimport" width="500px" center>
        <div>
          <div style="
              color: #000000;
              margin-bottom: 30px;
              margin-top: -30px;
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-font-weight: blod;
            ">
            {{ importTitle }}
          </div>
          <div style="
              color: #999999;
              margin-bottom: 30px;
              width: 100%;
              text-align: center;
            " v-if="
              progress != 100 && importTitle != '导出失败！' && hideProgress
            ">
            {{ $t('closing_window_abort_export') }}
          </div>
          <el-progress v-if="hideProgress" :text-inside="true" :percentage="progress" :stroke-width="20"
            :indeterminate="true" :color="importTitle == '导出失败！' ? 'red' : '#5A5FFF'"></el-progress>
          <div style="
              margin-top: 30px;
              margin-bottom: 30px;
              width: 100%;
              text-align: center;
              color: #337ab7;
            " v-if="progress == 100">
            <span style="color: #000000">{{ $t('download_link') }} </span><a :href="exportUrl">{{ exportUrl }}</a>
          </div>
        </div>
      </el-dialog>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList2"></add-or-update>
      <print-preview v-if="printPreviewVisible" ref="printPreview" @refreshDataList="getDataList2"></print-preview>
      <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList2"></upload>
      <upload-product-images v-if="uploadImagesVisible" ref="uploadImages"
        @refreshDataList="getDataList2"></upload-product-images>
    </div>
  </div>
</template>

<script>
import {
  del,
  add as addProduction,
  update as updateProduction,
  list2,
  syncProduct,
  exportExcelProduct,
  exportExcelProductData,
  getSpeedOfProgress,
  importExcel,
  importNumExcel
} from '@/api/modules/product'
import { importProductExcel } from '@/api/modules/colorbook'
import {
  list,
  changePro,
  importExcel as importProductOrder,
  add as addLab,
  update as updateLab
} from '@/api/modules/productorder'
import axios from '@/api/modules/axiosExport'
import { axiosDownFile } from '@/utils/index'
import {
  getProductAttrValue,
  selectChildAttr
} from '@/api/modules/productattrvalue'
import {
  getAttrAndValueListInfo,
  getAttrTableCols
} from '@/api/modules/productattr'
import AddOrUpdate from './add-or-update'
import PrintPreview from './printingPreview'
import Upload from './oss-upload'
import { getDataFactory } from '@/api/modules/factory'
import { brandList } from '@/api/modules/brand'
import SearchForm from '@/components/form.vue'
import { getSecondsAgoMessage } from '../../util/i18n/time'
import ReadDevice from '../../components/readDevice.vue'
import UploadProductImages from './uploadProductImages.vue'
// var SEARCH_HISTORY_KEY = 'Yitu_ColorX_PRODUCT_SEARCH'

// import cesTable from '@/components/table.vue';
export default {
  props: {
    state: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      srcPicUrlList: [],
      pageName: 'productAll',
      hideProgress: true, //控制导出进度条是否展示
      gettime: '',
      progress: 0,
      exportUrl: '',
      importTitle: this.$t('currently_exporting'),
      collapseState: true,
      cloudLoading: false,
      isdisabled: false,
      exportloading: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timer_: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      is_query: false,
      testList: [
        {
          value: '0',
          label: this.$t('no')
        },
        {
          value: '1',
          label: this.$t('yes')
        }
      ],
      dataForm: {
        glossNumDw: 0,
        glossNumUp: 0,
        srcPicUrlList: [],
        productSeries: '',
        productName: '',
        id: '',
        productNo: '',
        picUrl: '',
        qrUrl: '',
        ralColorNo: '',
        gloss: '',
        glossValue: '',
        surface: '',
        hasProductNum: '-1',
        isNomasge: '-1',
        isBase: '-1',
        colorSeries: '',
        productPerformance: '',
        otherPerformance: '',
        productCategory: '',
        productHierarchy: '',
        industryId: '',
        industryId_child: '',
        factoryId: '-1',
        brandId: '',
        description: '',
        labAve: '',
        l1: '',
        a1: '',
        b1: '',
        l2: '',
        a2: '',
        b2: '',
        l3: '',
        a3: '',
        b3: '',
        lAve: '',
        aAve: '',
        bAve: '',
        isOpen: '',
        productOrder: '',
        state: '',
        integral: '0',
        isIntegral: '0'
      },
      dataInfo: {
        name: '',
        info: '',
        alias: '',
        type: ''
      },
      searchForm: [
        //这里是渲染查询表单的表头和类型的数据
        // {type:'Input',label:'姓名',prop:'name', width:'180px',placeholder:'请输入姓名...'},
        // {type:'Select',label:'性别',prop:'sex',width:'180px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别...'},
      ],
      searchData: {
        //查询表单的对应的值
        // name:'',
        // sex:null,
      },
      notIncluded: [],
      tableCols: [
        //表格列数据
        // {label:'姓名',prop:'name'},
        // {label:'年龄',prop:'age'},
      ],

      pagination: {
        //分页数据
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      createDates: [],
      resultList: [],
      colorSeriesList: [], //色系
      productCategoryList: [], //产品分类
      productHierarchyList: [], //产品主题
      productSeriesList: [], //产品系列集合
      industryList: [],
      industryList_child: [],
      dataList: [],
      productPerformanceList: [],
      productSurfaceList: [],
      productAttrAndValueVoList: [],
      productGlossList: [],
      otherPerformanceList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      loading: false,
      loading2: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      uploadVisible: false,
      uploadImagesVisible: false,
      printPreviewVisible: false,
      brandList: [],
      factoryList: [],
      brandState: false,
      factoryState: false
    }
  },
  components: {
    AddOrUpdate,
    Upload,
    PrintPreview,
    SearchForm,
    axios,
    ReadDevice,
    UploadProductImages
  },
  watch: {
    $route: {
      handler() {
        this.getState()
      },
      deep: true
    }
    // dataForm: {
    //   handler() {
    //     sessionStorage.setItem(
    //       SEARCH_HISTORY_KEY + this.state,
    //       JSON.stringify(this.dataForm)
    //     )
    //   },
    //   deep: true
    // }
  },
  created() {
    this.getState()
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '' && value !== null) {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    }
  },

  methods: {
    isAuthorized() {
      // 核查用户的权限
      if (
        (this.state != undefined &&
          this.state != null &&
          this.state != '' &&
          this.state != '0' &&
          this.state != '1' &&
          this.state != '2') ||
        ((this.state == undefined ||
          this.state == null ||
          this.state.length == 0) &&
          !this.isAuth('product:productall')) ||
        (this.state == '0' && !this.isAuth('product:productprocess')) ||
        (this.state == '1' && !this.isAuth('product:productorder')) ||
        (this.state == '2' && !this.isAuth('product:product'))
      ) {
        // 用户没有权限
        return false
      } else {
        return true
      }
    },
    getState() {
      // const searchForm = sessionStorage.getItem(SEARCH_HISTORY_KEY + this.state)
      // if (searchForm) {
      //   this.dataForm = JSON.parse(searchForm)
      // }

      if (this.state) {
        this.dataForm.state = this.state
      }

      if (this.state.length == 0) {
        this.pageName = 'productall'
      }
      if (this.state == '0') {
        // 过程样板 (productprocess)
        this.pageName = 'productprocess'
      }
      if (this.state == '1') {
        // 实验室产品 (productorder)
        this.pageName = 'productorder'
      }
      if (this.state == '2') {
        // 生产产品 (product)
        this.pageName = 'product'
      }
      this.getAttrAndValueListInfo()
      this.getAttrTableCols()
      this.getBrandList()
    },
    //获取品牌列表
    getBrandList() {
      let params = {}
      brandList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
          this.brandState = data.isSys
          if (!data.isSys) {
            this.dataForm.brandId = this.brandList[0].id
            this.getFactoryList()
          }
          this.factoryState = true
        } else {
          this.brandList = []
        }
      })
    },
    brandOnchange() {
      this.dataForm.factoryId = ''
      this.getFactoryList()
    },
    getFactoryList() {
      if (
        this.dataForm.brandId != null ||
        this.dataForm.brandId != '' ||
        this.dataForm.brandId != '-1'
      ) {
        let params = {
          brandId: this.dataForm.brandId
        }
        getDataFactory(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.factoryList = data.factory
            this.dataForm.factoryId = '-1'
            if (data.factory.length == 1) {
              this.dataForm.factoryId = data.factory[0].id
              if (!this.brandState) {
                this.factoryState = false
              }
            }
          } else {
            this.factoryList = []
          }
        })
      } else {
        this.dataForm.factoryId = '-1'
      }
    },
    handleCurrentChange() {
      this.getAttrTableCols()
    },
    handleCollapse() {
      const $dom = document.getElementsByClassName('el-card__body')[0]
      //const $domEl = document.getElementsByClassName('el')[0];
      if (this.collapseState) {
        $dom.style.height = 'auto'
        $dom.style.overflow = ''
        this.collapseState = false
        // $domEl.style.bottom = '20px'
        // $domEl.style.top = ''
      } else {
        $dom.style.height = '70px'
        $dom.style.overflow = 'hidden'
        this.collapseState = true
        // $domEl.style.top = '0'
      }
    },
    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.notIncluded = []
      getAttrAndValueListInfo({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          data.data.forEach((e) => {
            var obj = { type: '', label: '', width: '130px' }
            if (e.type == '1') {
              // 如果是单选
              obj.type = 'Select'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.label = e.value
              obj.notIncluded = true
            }
            if (e.type == '2') {
              obj.type = 'SelectMultiple'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.notIncluded = true
            }
            if (e.type == '3') {
              obj.type = 'Cascader'
              obj.options = e.options
              obj.prop = e.type + '-' + e.productAttrId
              obj.value = e.value
              obj.notIncluded = true
            }
            obj.label = this.localizeParam(e, 'label')
            var obj_ = {
              children: [],
              label: this.$t('other'),
              value: '0',
              valueProductAttrId: '0'
            }
            obj.options.push(obj_)
            this.$set(this.searchData, e.productAttrId, e.value)
            this.searchForm.push(obj)
          })
          // console.log(this.searchForm)
        } else {
          this.$message.error(data.msg)
          this.loading2 = false
        }
      })
    },

    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.productPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.productCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        }
      })
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getAttrTableCols()
    },
    reset(dataForm) {
      // sessionStorage.removeItem(SEARCH_HISTORY_KEY + this.state)
      this.$refs.dataForm.resetFields()
      this.dataForm.productPerformance = ''
      this.dataForm.otherPerformance = ''
      this.dataForm.productName = ''
      this.dataForm.surface = ''
      this.dataForm.industryId_child = ''
      this.industryList_child = []
      this.dataForm.gloss = ''
      this.dataForm.lAve = ''
      this.dataForm.aAve = ''
      this.dataForm.bAve = ''
      this.dataForm.factoryId = '-1'
      this.dataForm.brandId = ''
      this.dataForm.isBase = '-1'
      this.is_query = false
      this.dataForm.hasProductNum = '-1'
      this.dataForm.isNomasge = '-1',
        this.dataForm.glossNumDw = 0,
        this.dataForm.glossNumUp = 0,
        this.createDates = []
      if (this.searchData) {
        for (var i in this.searchData) {
          this.$set(this.searchData, i, {})
        }
        this.searchData = {}
      }
      this.notIncluded = []
      this.restPage()
    },
    setSearchData() {
      var searchData = JSON.parse(JSON.stringify(this.searchData))
      Object.keys(searchData).forEach((key) => {
        if (Array.isArray(searchData[key])) {
          for (var i = 0; i < searchData[key].length; i++) {
            if (searchData[key][i] === '0') {
              searchData[key].splice(i, 1)
            }
          }
        } else {
          if (searchData[key] === '0') {
            searchData[key] = ''
          }
        }
      })
      return searchData
    },
    // 获取数据列表2
    getDataList2() {
      this.loading2 = true
      this.dataListLoading = true
      this.dataList = []
      if (
        (this.dataForm.lAve || this.dataForm.aAve || this.dataForm.bAve) &&
        !(this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve)
      ) {
        this.$message.error(this.$t('e710', { x: 'LAB' }))
        return
      }
      var params = {
        notIncluded: this.notIncluded,
        searchData: this.setSearchData(),
        page: this.pageIndex,
        limit: this.pageSize,
        productName: this.dataForm.productName,
        productNo: this.dataForm.productNo,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.dataForm.l2,
        a2: this.dataForm.a2,
        b2: this.dataForm.b2,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        state: this.dataForm.state,
        isBase: this.dataForm.isBase,
        productOrder: this.dataForm.productOrder,
        integral: this.dataForm.integral,
        createDates: this.createDates,
        hasProductNum: this.dataForm.hasProductNum,
        isNomasge: this.dataForm.isNomasge,
        factoryId: this.dataForm.factoryId,
        brandId: this.dataForm.brandId,
        test: '',
        glossNumDw: this.dataForm.glossNumDw,
        glossNumUp: this.dataForm.glossNumUp,
      }

      let listFunction

      if (this.dataForm.state == '1') {
        listFunction = list
        params.test = '1'
      } else {
        listFunction = list2
      }
      listFunction(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.resultList
          this.totalPage = data.page.totalCount
          this.pagination.pageSize = data.page.pageSize
          this.pagination.total = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
        this.loading2 = true
      })
      if (this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve) {
        this.is_query = true
      }
    },
    getAttrTableCols: function () {
      this.tableCols = []
      getAttrTableCols({ isUniversal: '0' }).then(({ data }) => {
        if (data && data.code === 0) {
          this.tableCols = data.data
          this.getDataList2()
        }
      })
    },

    //同步数据至云平台
    syncProduct() {
      this.cloudLoading = true
      let params = {}
      syncProduct(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.cloudLoading = false
        } else {
          this.$message.error(data.msg)
          this.cloudLoading = false
        }
      })
    },
    getprogress() {
      var params_ = {
        taskNo: this.gettime
      }
      getSpeedOfProgress(params_).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.data.state != 0) {
            this.progress = data.data.speedOofProgress
            if (data.data.speedOofProgress == 100) {
              this.exportUrl = data.data.url.replace('http', 'https')
              this.importTitle = this.$t('x_success', { x: this.$t('export') })
              window.clearInterval(this.timer_)
              this.timer_ = null
            }
          } else {
            this.importTitle = this.$t('x_failed', { x: this.$t('export') })
            window.clearInterval(this.timer_)
            this.timer_ = null
          }
        } else if (data && data.code === 10400) {
          this.importTitle = this.$t('no_data')
          window.clearInterval(this.timer_)
          this.timer_ = null
          this.hideProgress = false
        } else {
          this.importTitle = this.$t('x_failed', { x: this.$t('export') })
          window.clearInterval(this.timer_)
          this.timer_ = null
          this.gettime = ''
        }
      })
    },
    closeimport() {
      window.clearInterval(this.timer_)
      this.timer_ = null
      this.exportloading = false
        ; (this.progress = 0),
          setTimeout(() => {
            this.importTitle = this.$t('exporting')
          }, 500)
    },

    //导出产品数据到excle
    exportExcelProduct() {
      this.hideProgress = true
      this.gettime = 'task' + new Date().getTime()
      var params = {
        notIncluded: this.notIncluded,
        searchData: this.setSearchData(),
        productOrder: this.dataForm.productOrder,
        productNo: this.dataForm.productNo,
        productName: this.dataForm.productName,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.dataForm.l2,
        a2: this.dataForm.a2,
        b2: this.dataForm.b2,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        hasProductNum: this.dataForm.hasProductNum,
        isNomasge: this.dataForm.isNomasge,
        createDates: this.createDates,
        state: this.dataForm.state,
        isBase: this.dataForm.isBase,
        integral: this.dataForm.integral,
        factoryId: this.dataForm.factoryId,
        brandId: this.dataForm.brandId,
        taskNo: this.gettime,
        totalCount: this.totalPage,
        test: ''
      }
      if (this.dataForm.state == '1') {
        params.test = '1'
      }
      exportExcelProduct(params).then(({ data }) => {
        // 处理返回的文件流
        // console.log(data);
        if (data && data.code === 0) {
          // console.log(111);
          this.progress = 0
          this.exportloading = true
          this.timer_ = setInterval(this.getprogress, 3000)
        } else {
          // console.log(222);
          this.$message.warning(data.msg)
        }
      })
    },

    //导出产品数据到excle
    exportExcelProductData() {
      this.hideProgress = true
      this.gettime = 'task' + new Date().getTime()
      var params = {
        notIncluded: this.notIncluded,
        searchData: this.setSearchData(),
        productOrder: this.dataForm.productOrder,
        productNo: this.dataForm.productNo,
        productName: this.dataForm.productName,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
        l1: this.dataForm.l1,
        a1: this.dataForm.a1,
        b1: this.dataForm.b1,
        l2: this.dataForm.l2,
        a2: this.dataForm.a2,
        b2: this.dataForm.b2,
        l3: this.dataForm.l3,
        a3: this.dataForm.a3,
        b3: this.dataForm.b3,
        hasProductNum: this.dataForm.hasProductNum,
        isNomasge: this.dataForm.isNomasge,
        createDates: this.createDates,
        state: this.dataForm.state,
        isBase: this.dataForm.isBase,
        integral: this.dataForm.integral,
        factoryId: this.dataForm.factoryId,
        brandId: this.dataForm.brandId,
        taskNo: this.gettime,
        totalCount: this.totalPage,
        test: ''
      }
      if (this.dataForm.state == '1') {
        params.test = '1'
      }
      exportExcelProductData(params).then(({ data }) => {
        // 处理返回的文件流
        // console.log(data);
        if (data && data.code === 0) {
          // console.log(111);
          this.progress = 0
          this.exportloading = true
          this.timer_ = setInterval(this.getprogress, 3000)
        } else {
          // console.log(222);
          this.$message.warning(data.msg)
        }
      })
    },
    //导出excel表格模板
    exportExcelHandle(isIntegratingSphere, isInventory = false) {
      if (isIntegratingSphere) {
        if (isIntegratingSphere) {
          return this.$alert(
            this.$t('not_supporting_integrating_sphere_import')
          )
        }
      }
      if (isInventory) {
        axiosDownFile(`/product/product/inventoryTemplate`)
      } else {
        axiosDownFile(
          `/product/product/${this.state.length != 0 ? 'exportExcel' : 'exportProductExcel'
          }`
        )
      }
    },
    getImportMethodAndTitle(isInventory) {
      if (isInventory) {
        return [importNumExcel, this.$t('import_inventory')]
      }
      if (this.state == '1') {
        // 实验室产品 (productorder)
        return [importProductOrder, this.$t('import_sample_products')]
      }
      if (this.state == '2') {
        // 生产产品 (product)
        return [importExcel, this.$t('import_production_products')]
      }
      return [importProductExcel, this.$t('import_products')]
    },
    // 上传文件
    uploadHandle(isIntegratingSphere, isInventory) {
      if (isIntegratingSphere) {
        return this.$alert(this.$t('not_supporting_integrating_sphere_import'))
      }

      this.uploadVisible = true
      this.$nextTick(() => {
        let importMethodAndTitle = this.getImportMethodAndTitle(isInventory)
        this.$refs.upload.init(
          importMethodAndTitle[0],
          importMethodAndTitle[1],
          this.state.length == 0 && !isInventory
        )
      })
    },
    // 上传图片
    uploadImagesHandle() {
      this.uploadImagesVisible = true
      this.$nextTick(() => {
        this.$refs.uploadImages.init()
      })
    },
    //转产按钮
    zc(id) {
      let params = {
        proId: id
      }
      this.$confirm(
        this.$t('are_you_sure_to_production'),
        this.$t('attention'),
        {
          type: 'warning'
        }
      ).then(() => {
        changePro(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message.success(this.$t('success'))
            this.restPage()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getAttrTableCols()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getAttrTableCols()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id, isDisabled = false, isCopy = false) {
      this.addOrUpdateVisible = true
      let addUpdateMethods =
        this.state == 1
          ? [addLab, updateLab]
          : [addProduction, updateProduction]
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(
          id,
          this.state,
          isDisabled,
          isCopy,
          addUpdateMethods
        )
      })
    },
    // 打印
    printPreviewHandle(id) {
      this.printPreviewVisible = true
      this.$nextTick(() => {
        this.$refs.printPreview.init(id)
      })
    },
    selectChildAttr() {
      let params = {
        attrValueId: this.dataForm.industryId
      }
      selectChildAttr(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.industryList_child = data.list
        } else {
          this.industryList_child = []
        }
        this.dataForm.industryId_child = ''
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
          return item.id
        })
      this.$confirm(
        this.$t('are_you_sure_delete_product'),
        this.$t('attention'),
        {
          type: 'warning'
        }
      ).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('success'),
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getAttrTableCols()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    dataReceived(data) {
      // 收到了设备数据
      this.dataForm.lAve = data.lAve
      this.dataForm.aAve = data.aAve
      this.dataForm.bAve = data.bAve
      this.dataForm.l1 = data.l1
      this.dataForm.a1 = data.a1
      this.dataForm.b1 = data.b1
      this.dataForm.l2 = data.l2
      this.dataForm.a2 = data.a2
      this.dataForm.b2 = data.b2
      this.dataForm.l3 = data.l3
      this.dataForm.a3 = data.a3
      this.dataForm.b3 = data.b3

      if (data.secondsAgo) {
        this.$message.success({
          message: this.$t('read_success', {
            x: getSecondsAgoMessage(data.secondsAgo)
          }),
          duration: 8000
        })
      }
      this.$forceUpdate()
    }
  },
  mounted() {
    const params = this.$route.query.params
    if (params === 'proAdd') {
      setTimeout(() => {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(undefined)
        })
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
_ {
  word-wrap: normal !important;
}

.app-container {
  background: #eaf1fc;
}

.w100 {
  width: 100px;
}

.mr2 {
  margin-right: 2px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.el-date-editor--daterange.el-input__inner {
  width: 250px;
}

.loading_dialog {
  margin-top: 200px;
}

.btn {
  padding: 0px;
  height: 32px;
  margin: 2px;

  span {
    height: 100%;
    padding: 10px 4px 10px 10px;
    display: inline-block;
    margin: 0px;
  }

  img {
    margin: 0px 6px 3px 0px;
    vertical-align: middle;
  }
}
</style>
<style lang="scss">
.box-pro {
  .el-card__body {
    position: relative;
    //height: max-content;
    overflow: hidden;
    height: 70px;
  }

  .el-form-item.lab {
    .el-form-item__content {
      width: auto !important;
    }
  }

  .el-form-item__label {
    font-weight: 400 !important;
  }

  .el-form--inline .el-form-item {
    margin-right: 10px;
  }
}
</style>
