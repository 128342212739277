import i18n from "../../i18n"
import moment from 'moment'
import 'moment/locale/zh-cn'

/**
 * 
 * @param {*} secondsAgo Seconds before now
 * @returns localized message
 */
export function getSecondsAgoMessage(secondsAgo) {
    var scanTime = new Date().getTime() - secondsAgo * 1000
    moment.locale(i18n.locale)
    moment.relativeTimeThreshold('ss', 5)
    let timeMessage =
        moment(scanTime).format('LLL') + ', ' + moment(scanTime).fromNow()

    return timeMessage
}