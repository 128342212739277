<template>
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="160px"
        size="small"
      >
        <el-form-item :label="$t('username')" prop="userName">
          <el-input
            v-model="dataForm.userName"
            :placeholder="$t('username')"
            :disabled="dataForm.id != '0'"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('full_name')" prop="name">
          <el-input
            v-model="dataForm.name"
            :placeholder="$t('full_name')"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('password')"
          prop="password"
          :class="{ 'is-required': !dataForm.id }"
        >
          <el-input
            v-model="dataForm.password"
            type="password"
            :placeholder="$t('password')"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('confirm_password')"
          prop="comfirmPassword"
          :class="{ 'is-required': !dataForm.id }"
        >
          <el-input
            v-model="dataForm.comfirmPassword"
            type="password"
            :placeholder="$t('confirm_password')"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('employee_number')" prop="userNo">
          <el-input
            v-model="dataForm.userNo"
            :placeholder="$t('employee_number')"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input
            v-model="dataForm.email"
            :placeholder="$t('email')"
            style="width: 445px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('phone_number')" prop="phoneCode">
          <div style="margin: 0px; display: inline">
            <el-input
              v-model="dataForm.phoneCode"
              placeholder="86"
              style="width: 70px"
            >
              <i slot="prefix" style="margin-left: 10px">+</i></el-input
            >
            <el-input
              v-model="dataForm.mobile"
              :placeholder="$t('phone_number')"
              style="
                width: 370px;
                margin-top: 1px;
                right: 0px;
                position: absolute;
              "
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('role')" size="mini" prop="roleId">
          <el-radio-group v-model="dataForm.roleId" style="width: 445px">
            <el-radio
              v-for="role in roleList"
              :key="role.roleId"
              :label="role.roleId"
              >{{ role.roleName }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('assigned_brand')" prop="brandId">
          <el-select
            v-model="dataForm.brandId"
            :placeholder="$t('assigned_brand')"
            @change="getDataFactory()"
          >
            <el-option
              v-for="brand in brandList"
              :key="brand.id"
              :label="brand.brandName"
              :value="brand.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('assigned_factory')" prop="factoryId">
          <el-select
            v-model="dataForm.factoryId"
            :placeholder="$t('assigned_factory')"
          >
            <el-option
              v-for="factory in factoryList"
              :key="factory.id"
              :label="factory.factoryName"
              :value="factory.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('status')" size="mini" prop="isFreeze">
          <el-radio-group v-model="dataForm.isFreeze">
            <el-radio :label="1">{{ $t('frozen') }}</el-radio>
            <el-radio :label="0">{{ $t('normal') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <el-button @click="sideClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" :loading="loading" @click="dataFormSubmit()">{{
        $t('confirm')
      }}</el-button>
    </div>
  </sides-lip>
</template>

<script>
import { isEmail, isMobile, isPhoneCode } from '@/utils/validate'
import {
  getRoleListParams,
  userDataFormSubmit,
  userUpdate,
  getDataFactory,
  getDataBrand
} from '@/api/sys'
import SidesLip from '@/components/SidesLip'

// import "@/styles/sideslip.scss";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(
          new Error(this.$t('e706', { x: this.$t('password') }))
        )
      } else {
        callback()
      }
    }
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(
          new Error(this.$t('e706', { x: this.$t('password') }))
        )
      } else if (this.dataForm.password !== value) {
        callback(new Error(this.$t('confirm_password_incorrect')))
      } else {
        callback()
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(
          new Error(this.$t('e710', { x: this.$t('email') }))
        )
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(this.dataForm.mobile)) {
        callback(
          new Error(
            this.$t('e710', { x: this.$t('phone_number') })
          )
        )
      } else {
        callback()
      }
    }
    var validatePhoneCode = (rule, value, callback) => {
      if (!isPhoneCode(this.dataForm.phoneCode)) {
        callback(
          new Error(this.$t('e710', { x: this.$t('phone_code') }))
        )
      } else if (this.dataForm.mobile.length == 0) {
        callback(
          new Error(
            this.$t('e706', {
              x: this.$t('phone_number')
            })
          )
        )
      } else if (!isMobile(this.dataForm.mobile)) {
        callback(
          new Error(
            this.$t('e710', { x: this.$t('phone_number') })
          )
        )
      }
      {
        callback()
      }
    }
    return {
      loading: false,
      visible: false,
      roleList: [],
      factoryList: [],
      brandList: [],
      dataForm: {
        id: 0,
        userNo: '',
        name: '',
        userName: '',
        password: '',
        comfirmPassword: '',
        salt: '',
        email: '',
        mobile: '',
        phoneCode: '',
        roleId: '',
        brandId: '',
        factoryId: '',
        roleIdList: [],
        isFreeze: 0
      },
      dataRule: {
        userName: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('username') }),
            trigger: 'blur'
          }
        ],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        comfirmPassword: [
          { validator: validateComfirmPassword, trigger: 'blur' }
        ],
        email: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('email') }),
            trigger: 'blur'
          },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          {
            required: true,
            message: this.$t('e706', {
              x: this.$t('phone_number')
            }),
            trigger: 'blur'
          },
          { validator: validateMobile, trigger: 'blur' }
        ],
        phoneCode: [
          {
            required: true,
            message: this.$t('e706', {
              x: this.$t('phone_code')
            }),
            trigger: 'blur'
          },
          { validator: validatePhoneCode, trigger: 'blur' }
        ],
        roleId: [
          {
            required: true,
            message: this.$t('e706', { x: this.$t('role') }),
            trigger: 'change'
          }
        ],
        brandId: [
          {
            required: true,
            message: this.$t('e706', {
              x: this.$t('assigned_brand')
            }),
            trigger: 'change'
          }
        ],
        factoryId: [
          {
            required: true,
            message: this.$t('e706', {
              x: this.$t('assigned_factory')
            }),
            trigger: 'change'
          }
        ]
      }
    }
  },
  components: {
    SidesLip
  },

  methods: {
    //获取当前登录人的工厂信息
    getDataFactory() {
      var params = {
        brandId: this.dataForm.brandId
      }
      getDataFactory(params).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log(data.factory);
          this.factoryList = data.factory
        }
      })
    },
    //获取当前登录人的品牌信息
    getDataBrand() {
      getDataBrand().then(({ data }) => {
        if (data && data.code === 0) {
          // console.log(data.brand);
          this.brandList = data.brand
        }
      })
    },
    getRoleListParams() {
      var params = {
        roleType: '2'
      }
      getRoleListParams(params).then(({ data }) => {
        if (data && data.code === 0 && data.list && data.list.length > 0) {
          // console.log(data.list);
          this.roleList = data.list
        }
      })
    },

    init(id) {
      this.dataForm.id = id || 0

      this.getRoleListParams()
      this.getDataBrand()
      this.getDataFactory()
      // if(!(this.roleList && this.roleList.length > 0)){
      //   this.$message.error("需先创立可用的角色");
      //   return '';
      // }

      this.$refs.SidesLip.init(
        this.dataForm.id
          ? this.$t('edit_x', { x: this.$t('account').toLowerCase() })
          : this.$t('new_x', { x: this.$t('account').toLowerCase() }),
        '700px',
        'block'
      )
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          userUpdate(`/sys/user/info/${this.dataForm.id}`).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.name = data.user.name
              this.dataForm.userName = data.user.username
              this.dataForm.userNo = data.user.userNo
              this.dataForm.salt = data.user.salt
              this.dataForm.email = data.user.email
              this.dataForm.mobile = data.user.mobile
              this.dataForm.phoneCode = data.user.phoneCode
              this.dataForm.roleId = data.user.roleId
              this.dataForm.brandId = data.user.brandId
              this.dataForm.factoryId = data.user.factoryId
              this.dataForm.roleIdList = data.user.roleIdList
              this.dataForm.isFreeze = Number.parseInt(data.user.isFreeze)
            }
          })
        }
      })
    },

    sideClose() {
      this.$refs.SidesLip.sideClose()
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const params = {
            userId: this.dataForm.id || undefined,
            username: this.dataForm.userName,
            password: this.dataForm.password,
            name: this.dataForm.name,
            salt: this.dataForm.salt,
            email: this.dataForm.email,
            mobile: this.dataForm.mobile,
            phoneCode: this.dataForm.phoneCode,
            userType: 'employee',
            brandId: this.dataForm.brandId,
            factoryId: this.dataForm.factoryId,
            roleId: this.dataForm.roleId,
            isFreeze: this.dataForm.isFreeze,
            userNo: this.dataForm.userNo
            //roleIdList: this.dataForm.roleIdList
          }
          const { data } = await userDataFormSubmit(
            `/sys/user/${!this.dataForm.id ? 'save' : 'update'}`,
            params
          )
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.loading = false
                this.sideClose()
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>


