<template xmlns="http://www.w3.org/1999/html">
  <el-dialog
    class="proDialog"
    :title="$t('product_details')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="800px"
    @click="close()"
    top="5vh"
  >
    <div class="details">
      <div class="d_top">
        
        <div
          v-if="list.picUrl || list.hex"
          :style="{
            backgroundColor: list.hex || list.Hex,
            height: '146px',
            minWidth: '100%',
            position: 'relative'
          }"
        >
        <el-image style="width: 35%; height: 90%; position: absolute; left: 63.5%; top: 5%;" v-if="list.picUrl" :src="list.picUrl" :preview-src-list="picUrlList">
        </el-image>
      </div>
        <p style="left: 0px;">{{ list.brandName == null ? 'TIGER' : list.brandName }}</p>
      </div>
      <div class="d_bottom">
        <div class="d_b_top">
          <div class="d_b_t_row">
            <div class="d_b_t_col">
              <p class="name">{{ $t('product_number') }}</p>
              <p class="message">{{ list.productNo }}</p>
            </div>
            <div class="d_b_t_col">
              <p class="name">{{ $t('batch_number') }}</p>
              <p class="message">
                {{ list.productOrder == '' ? $t('none') : list.productOrder }}
              </p>
            </div>
            <div class="d_b_t_col">
              <p class="name">{{ $t('standard_color_charts') }}</p>
              <p class="message">
                {{ list.ralColorNo == null ? $t('none') : list.ralColorNo }}
              </p>
            </div>
          </div>
          <div class="d_b_t_row">
            <div class="d_b_t_col">
              <p class="name">{{ $t('brand') }}</p>
              <p class="message">
                {{ list.brandName == null ? $t('none') : list.brandName }}
              </p>
            </div>
          </div>
        </div>
        <div class="d_b_bot">
          <div class="d_b_t_content">
            <div class="d_b_t_c_left">
              <div class="d_b_t_col">
                <p class="name">{{ $t('production_factory') }}</p>
                <p class="message">
                  {{ list.factoryName == null ? $t('none') : list.factoryName
                  }}{{ list.state == '2' ? '' : $t('sample_product') }}
                </p>
              </div>
              <!-- <div class="d_b_t_col">
                <p class="name">分类</p>
                <p
                  class="message"
                >{{list.productCategoryLabel==null?'暂无':list.productCategoryLabel}}</p>
              </div> -->
              <div class="d_b_t_col">
                <p class="name">LAB</p>
                <p class="message">
                  L：{{ list.lAve == null ? $t('none') : list.lAve }}
                </p>
                <p class="message">
                  A：{{ list.aAve == null ? $t('none') : list.aAve }}
                </p>
                <p class="message">
                  B：{{ list.bAve == null ? $t('none') : list.bAve }}
                </p>
              </div>
              <div class="d_b_t_col">
                <p class="name">{{ $t('gloss') }}</p>
                <p class="message">
                  {{ list.glossValue == null ? $t('none') : list.glossValue }}
                </p>
              </div>
              <div class="d_b_t_col">
                <p class="name">{{ $t('45deg_color_diff') }}</p>
                <p class="message">
                  {{ list.e00lab2 == '-1' ? '0' : list.e00lab2 }}
                </p>
              </div>
              <div class="d_b_t_col">
                <p class="name">{{ $t('color_difference') }}</p>
                <p class="message">
                  {{ list.e00Value == '-1' ? '0' : list.e00Value }}
                </p>
              </div>
              <div class="d_b_t_col">
                <p class="name">{{ $t('inventory') }}</p>
                <p class="message">
                  {{ list.productNum == null ? '0' : list.productNum }} kg
                </p>
              </div>
            </div>
            <div>
              <div class="d_b_t_col">
                <div id="app">
                  <div v-for="(value, key) in localizeParam(list, 'resultMap')">
                    <p class="name">{{ key }}</p>
                    <p class="message">{{ value }}</p>
                  </div>
                </div>
              </div>
              <div class="d_b_t_col">
                <p class="name">{{ $t('product_status') }}</p>
                <!-- <p class="message">{{list.test=='0'?'过程样板':(list.test=='1' ?  "实验产品" :'正式产品')}}< -->
                <p class="message">
                  {{
                    list.state == '0'
                      ? $t('sampling_process')
                      : list.state == '2'
                      ? $t('production_product')
                      : $t('sample_product')
                  }}
                </p>
              </div>
            </div>
            <div class="d_b_t_c_right_sm"  @click="addRemoteProofingHandle(item)">
              <i class="el-icon-goods custom-icon" ></i>
              <span class="smple_span">{{ $t('sample_acquisition') }}</span>
            </div>
            <!-- <div>
              <span class="smple_span">{{ $t('sample_acquisition') }}</span>
            </div> -->
            <img class="d_b_t_c_right" :src="list.qrUrl" />
          </div>
          <!-- <button class="btn" bindtap="saveImg">保存图片</button> -->
        </div>
        <div class="d_b_bot"></div>
      </div>
    </div>
     <!-- 新增远程打样 -->
     <add-remote-proofing v-if="addRemoteProofingVisible" ref="addRemoteProofing" :proposObject="proposObject"
      ></add-remote-proofing>
  </el-dialog>
</template>
<script>
import { searchProductInfo, add as addProduction, } from '@/api/modules/product'
import addRemoteProofing from './add-remote-proofing'
export default {
  data() {
    return {
      id: '',
      item:'',
      visible: false,
      list: {},
      picUrlList:[],
      addRemoteProofingVisible:false,
      proposObject:{},
      labStr:'888',
      searchData: {},
      gloss:''
    }
  },
  components: {
    addRemoteProofing
  },
  methods: {
    addRemoteProofingHandle(item ,id, isDisabled = true, isCopy = false,) {
      this.proposObject = {
        labStr : this.labStr,
        list :this.list,
        searchData: this.searchData,
        gloss : this.gloss
      }
      this.addRemoteProofingVisible = true
      let addUpdateMethods =
        this.state == 
          [addProduction]
      this.$nextTick(() => {
        this.$refs.addRemoteProofing.init(
          id,
          this.state,
          isDisabled,
          isCopy,
          addUpdateMethods,
          this.proposObject
        )
      })
    },
    init(id, e00Value, e00lab2, brandId) {
      this.visible = true
      this.id = id
      this.list = {}
      var params = {
        id: this.id,
        brandId: brandId
      }
      searchProductInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.list = data.productInfoVo
          this.list.e00Value = e00Value
          this.list.e00lab2 = e00lab2
          this.picUrlList = data.productInfoVo.picUrlList
          this.searchData = data.productInfoVo.searchData
          this.gloss = data.productInfoVo.gloss

        }
      })
    },
    //打样
    SampleHandle() {
      this.sampleVisible = true
      this.$nextTick(() => {
        this.$refs.sample.init(this.dataForm.recipeId)
      })
    },
    close() {
      this.list = {}
      this.visible = false
    }
  },
  beforeDestroy() {
    this.list = {}
  }
}
</script>
<style lang="scss" scoped>
.details {
  padding: 0 15px 0;
}

.d_top {
  position: relative;
}

.d_top img {
  width: 100%;
  height: 146px;
}

.d_top p {
  position: absolute;
  bottom: 5px;
  right: 12px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.09);
  font-weight: blod;
}

.d_bottom {
  padding: 0 43px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}

.d_bottom::before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  /* background: rgb(60, 53, 53);*/
  position: absolute;
  top: 137.5px;
  left: -10px;
  transform: translateY(-50%);
  box-shadow: -10px -1px 7.5px -6.5px rgba(0, 0, 0, 0.16) inset;
}

.d_bottom::after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  background: #fff;
  position: absolute;
  top: 137.5px;
  right: -10px;
  transform: translateY(-50%);
  box-shadow: 10px -1px 7.5px -6.5px rgba(0, 0, 0, 0.16) inset;
}

.d_b_top {
  padding: 13px 0 0;
  border-bottom: 1px #ccc dashed;
  margin-bottom: 10px;
  // height: 240px;
}

.d_b_t_row {
  display: flex;
  /* justify-content: space-between; */
}

.d_b_t_col {
  width: 100%;
  margin-bottom: 20px;
  #app {
    background: none;
  }
}

.d_b_t_col p {
  display: block;
}

.name {
  font-size: 15px;
  color: #ababab;
  /*margin-bottom: 8px;*/
  margin: 5px 0;
}

.message {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin: 5px 0;
}

.d_b_bot {
  padding-bottom: 32px;
}

.d_b_t_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d_b_t_c_left {
  width: 35%;
}

.d_b_t_c_right {
  margin-top: 230px;
  width: 90px;
  height: 90px;
}
.d_b_t_c_right_sm{
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 88px;
  height: 88px;
  Position:absolute;
  top: 220px;
  right:45px;
}
.smple_span{
  text-align: center;
  margin-top: 63px;
  color: black;
}

.custom-icon {
    font-weight: bold;
    color: white;
    position: absolute;
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 20px;
    padding: 5px 15px 15px 12px;
    background: #303133;
    opacity: 0.5;
    z-index: 2;
    cursor: pointer;
    font-size: 65px;
  }
  .custom-icon:hover {
    color: black;
  }
  
.btn {
  background: linear-gradient(
    315deg,
    rgba(126, 0, 255, 1) 0%,
    rgba(0, 120, 255, 1) 100%
  );
  color: #ffffff;
  font-size: 16px;
  border-radius: 50px;
  padding: 8px 0;
  width: 168px;
  margin-top: 32px;
}
</style>
