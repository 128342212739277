<template>
  <div style="display: inline-block; margin: 0px; padding: 0px">
    <el-button
      size="small"
      type="primary"
      class="btn"
      @click="syncDevice()"
      :loading="loading"
      ><span>{{ $t('read_data_device') }}</span>
      <img src="../assets/usb_white.png" height="25" width="25"
    /></el-button>
    <el-button
      size="small"
      type="primary"
      class="btn"
      @click="syncWirelessDeviceData()"
      ><span>{{ $t('read_data_wireless') }}</span>
      <img src="../assets/wireless_connection_white.png" height="32" width="32"
    /></el-button>

    <!-- <div style="margin: 5px;">
      <el-select v-model="observerAngle" class="condition-form" size="mini" @change="$emit('changeObserver', observerAngle)">
        <el-option label="2°" value="two_deg"></el-option>
        <el-option label="10°" value="ten_deg"></el-option>
      </el-select>
      <el-select v-model="illuminant" class="condition-form" size="mini" @change="$emit('changeIlluminant', illuminant)">
        <el-option label="A" value="A"></el-option>
        <el-option label="C" value="C"></el-option>
        <el-option label="D50" value="D50"></el-option>
        <el-option label="D55" value="D55"></el-option>
        <el-option label="D65" value="D65"></el-option>
      </el-select>
    </div> -->


    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
  </div>
</template>

<script>
/**
 * 此组件有'read' emit，会带一个直接包括所有读取的颜色信息
 * 以及·'readError' emit, 会带异常msg
 */

import { getWirelessDeviceData, syncDevice } from '../api/modules/product'
import { setDeviceState } from '@/api/modules/device'

import DeviceBoot from './DeviceBoot.vue'
export default {
  props: ['observerAngle', 'illuminant'],
  components: { DeviceBoot },
  data: () => ({
    loading: false,
    deviceBootShow: false,
    websock: null,
    timer: null, //首先我在data函数里面进行定义定时器名称：
    timer_: null, //首先我在data函数里面进行定义定时器名称：
    timerNum: 0, // 设置定时器时间
  }),
  methods: {
    //设备同步
    syncDevice() {
      this.loading = true
      var id = this.$store.getters.userDevice.id
      console.log(id)
      if (
        null == id ||
        '' == id ||
        this.$store.getters.userDevice.connectionType == 1
      ) {
        this.loading = false
        this.handleDeviceBoot()
        return
      }
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      syncDevice(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.initWebSocket()
          this.timer = setInterval(() => {
            //如果已经同步就关闭定时任务
            this.$message.warning('数据同步中，请确认设备是否有待同步数据')
            this.timerNum = this.timerNum + 1
            if (this.timerNum == 5) {
              this.clearTimer() // 关闭定时器
              this.setDeviceState()
              this.loading = false
              this.$emit('readError', this.$t('device_timeout'))
            }
          }, 5000)
        } else {
          this.$emit('readError', this.$t(data.msg))
          this.$message.error(data.msg)
          this.loading = false
        }
      })
    },
    initWebSocket() {
      //初始化weosocket
      //ws地址
      if (this.websock) {
        this.websock.close()
      }
      var id = this.$store.getters.userDevice.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      console.log('RECEIVED WESOCKET:')
      console.log(e)
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      console.log(code)
      var msg = retData.msg
      console.log(msg)
      var data = retData.data
      console.log(data)
      if (code == 0) {
        let readData = {
          hex: data.hex,
          lAve: data.lAve,
          aAve: data.aAve,
          bAve: data.bAve,
          l1: data.l1,
          a1: data.a1,
          b1: data.b1,
          l2: data.l2,
          a2: data.a2,
          b2: data.b2,
          l3: data.l3,
          a3: data.a3,
          b3: data.b3
        }

        this.$emit('read', readData)

        this.clearTimer() // 关闭定时器
        this.loading = false
        this.websock.close()
        this.$message.success(this.$t('data_sync_success'))
      }
    },
    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      this.$emit('readError', this.$t('websocket_connection_failed'))
      return this.$message.error(this.$t('websocket_connection_failed'))
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      console.log('connection closed (' + e.code + ')')
    },
    setDeviceState() {
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      setDeviceState(params)
    },
    syncWirelessDeviceData() {
      var deviceId = this.$store.getters.userDevice.id
      if (
        null == deviceId ||
        '' == deviceId ||
        this.$store.getters.userDevice.connectionType != '1'
      ) {
        this.loading = false
        this.handleDeviceBoot(false)
        return
      }

      getWirelessDeviceData({
        deviceId: deviceId,
        observerAngle: this.observerAngle,
        illuminant: this.illuminant
        }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.LABvalue) {
            let readData = {
              hex: data.hex,
              lAve: data.LABvalue.lAve,
              aAve: data.LABvalue.aAve,
              bAve: data.LABvalue.bAve,
              l1: data.LABvalue.l1,
              a1: data.LABvalue.a1,
              b1: data.LABvalue.b1,
              l2: data.LABvalue.l2,
              a2: data.LABvalue.a2,
              b2: data.LABvalue.b2,
              l3: data.LABvalue.l3,
              a3: data.LABvalue.a3,
              b3: data.LABvalue.b3,
              spectral1: data.LABvalue.spectral1,
              spectral2: data.LABvalue.spectral2,
              spectral3: data.LABvalue.spectral3,
              illuminant: this.illuminant,
              observerAngle: this.observerAngle,
              secondsAgo: data.secondsAgo
            }
            this.$emit('read', readData)
          } else {
            this.$emit('readError', this.$t('no_data'))
            this.$message.error(this.$t('no_data'))
          }
        } else {
          this.$emit('readError', this.$t('no_data'))
          this.$message.error(this.$t('no_data'))
        }
      })
    },
    //设备引导
    handleDeviceBoot(isUsb = true) {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init(isUsb)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  padding: 0px;
  height: 32px;
  margin: 2px;
  span {
    height: 100%;
    padding: 10px 4px 10px 10px;
    display: inline-block;
    margin: 0px;
  }
  img {
    margin: 0px 6px 3px 0px;
    vertical-align: middle;
  }
}

.condition-form {
  width: 70px;
}
</style>