<template>
  <el-dialog
    :title="pageTitle"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      label-width="150px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item :label="$t('model_viewer_name')" prop="viewerTitle">
        <el-input
          style="width: 70%"
          v-model="dataForm.viewerTitle"
          :placeholder="$t('model_viewer_name')"
          size="small"
          maxlength="150"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('industry_category')" prop="industry">
        <el-select
          style="width: 70%"
          v-model="dataForm.industry"
          clearable
          :placeholder="$t('industry_category')"
          size="small"
          :disabled="disabled"
        >
          <el-option
            v-for="item in industries"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Emersya ID" prop="emersyaId">
        <el-input
          style="width: 70%"
          v-model="dataForm.emersyaId"
          placeholder="Emersya ID"
          size="small"
          maxlength="150"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="Project Code" prop="projectCode">
        <el-input
          style="width: 70%"
          v-model="dataForm.projectCode"
          placeholder="Project Code"
          size="small"
          maxlength="150"
          :disabled="disabled"
        ></el-input>
      </el-form-item>

      <el-form-item :label="$t('model_image')" prop="picUrl">
        <el-upload
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar" />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            title="点击上传"
          ></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      *模型项生成后，请查看预览确认并发布模型
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{
        $t('cancel')
      }}</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >{{ $t('confirm') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { info, add, update } from '@/api/modules/modelViewerManagement'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      action: `${
        process.env.VUE_APP_BASE_API
      }/sys/oss/upload?token=${getToken()}`,
      pageTitle: '',
      visible: false,
      disabled: false,
      dataLoading: false,
      industries: [
        { value: '0', label: '交通运输' },
        { value: '1', label: '建筑建材' },
        { value: '2', label: '通用工业' },
        { value: '3', label: '绿色生活' },
        { value: '4', label: '其他' }
      ],
      dataForm: {
        viewerId: 0,
        isCopy: false,
        viewerTitle: '',
        industry: '',
        emersyaId: '',
        projectCode: '',
        picUrl: 'test'
      },
      dataRule: {
        viewerTitle: [
          { required: true, message: '模型项名称不能为空', trigger: 'blur' }
        ],
        industry: [
          { required: true, message: '行业分类不能为空', trigger: 'blur' }
        ],
        emersyaId: [
          { required: true, message: 'Emersya ID不能为空', trigger: 'blur' }
        ],
        projectCode: [
          { required: true, message: 'Project Code不能为空', trigger: 'blur' }
        ],
        picUrl: [
          { required: true, message: '展示图片不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init(viewerId, disabled, isCopy) {
      this.dataForm.viewerId = viewerId || 0
      this.disabled = disabled
      this.visible = true

      if (!viewerId) {
        this.pageTitle = this.$t('create_new')
      } else if (disabled) {
        this.pageTitle = '查看'
      } else if (isCopy) {
        this.pageTitle = this.$t('copy')
        this.isCopy = true
      } else {
        this.pageTitle = this.$t('edit')
      }

      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.viewerId) {
          info({ viewerId: this.dataForm.viewerId }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.viewerTitle = data.modelViewer.viewerTitle
              this.dataForm.industry = data.modelViewer.industry
              this.dataForm.emersyaId = data.modelViewer.emersyaId
              this.dataForm.projectCode = data.modelViewer.projectCode
              this.dataForm.picUrl = data.modelViewer.picUrl
            }
          })
        }
      })
    },
    //图片上传
    handleAvatarSuccess(res, file, type) {
      if (res && res.code === 0) {
        this.dataForm.picUrl = res.data.fileUrl
      } else {
        this.$message.error(res.msg)
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 8
      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          if (this.isCopy) {
            this.dataForm.viewerId = undefined
          }
          var params = {
            viewerId: this.dataForm.viewerId || undefined,
            viewerTitle: this.dataForm.viewerTitle,
            industry: this.dataForm.industry,
            emersyaId: this.dataForm.emersyaId,
            projectCode: this.dataForm.projectCode,
            picUrl: this.dataForm.picUrl
          }
          var tick = !this.dataForm.viewerId ? add(params) : update(params)
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.dataLoading = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false
            }
          })
        }
      })
    }
  }
}
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  max-height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}
</style>
