<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '复制'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="150px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item  label="选择品牌" prop="brandId">
        <el-select v-model="dataForm.brandId" size="small" @change="getproductAttrInfo(dataForm.brandId)" placeholder="请选择品牌" style="width: 80%" >
          <el-option label="通用" value="0" size="small" ></el-option>
          <el-option
                  v-for="item in  brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="关联属性" prop="productAttrId">
        <el-select v-model="dataForm.productAttrId" size="small" @change="selectAttrValueList(dataForm.productAttrId)" placeholder="请选择属性" style="width: 80%" >
          <el-option
                  v-for="item in  productAttrList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="上级属性值" prop="fatherId">
        <el-select v-model="dataForm.fatherId" size="small" placeholder="请选择属性" style="width: 80%" >
          <el-option label="一级属性值" value="0"></el-option>
          <el-option
                  v-for="item in  dataList"
                  :key="item.id"
                  :label="item.valueName"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="属性值编号" prop="valueNo">
        <el-input v-model="dataForm.valueNo" placeholder="属性值编号"  size="small" style="width: 80%"></el-input>
      </el-form-item>

      <el-form-item label="属性值名称" prop="valueName">
        <el-input v-model="dataForm.valueName" placeholder="属性值名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
            <el-form-item label="属性值英文名称" prop="valueNameEn">
        <el-input v-model="dataForm.valueNameEn" placeholder="属性值英文名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="属性值别名" prop="valueAlias">
        <el-input v-model="dataForm.valueAlias" placeholder="属性值名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>




      <el-form-item label="是否显示" prop="isOpen">
        <!--<el-input v-model="dataForm.isShow" placeholder="是否展示"  size="small"></el-input>-->
        <el-select v-model="dataForm.isOpen" size="small" placeholder="是否显示" style="width: 80%">
          <el-option
                  v-for="item in isOpenList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input v-model="dataForm.sort" placeholder="排序"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="关联通用属性" prop="" v-if="relationAttr.relationAttrName && dataForm.brandId != '0'">
        <div style="width: 80%">{{relationAttr.relationAttrName}}</div>
      </el-form-item>
      <el-form-item label="关联通用属性值" prop="relationAttrValueId"  v-if="relationAttr.relationAttrName && dataForm.brandId != '0'">
        <el-radio-group v-model="dataForm.relationAttrValueId">
          <el-radio v-for="item in  dataList_"
                  :key="item.id"
                  :label="item.id"
                  :value="item.id"
                  style="margin:10px;"
                  size="small">{{item.valueName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="属性值说明" prop="description">
        <div style="margin: 5px 0;"></div>
        <el-input
                type="textarea"
                :rows="4"
                placeholder="产品说明"
                v-model="dataForm.description"
                maxlength="150"
                show-word-limit
                style="width: 80%"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {list,info,add,update,getproductAttrInfo,selectAttrValueList} from '@/api/modules/productattrvalue'
  import {
      getBrand,
  } from "@/api/modules/product";
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
          brandList: [],
          productAttrList:[],
        dataList: [],
        dataList_:[],
        relationAttr:{
          relationAttrName:'',
          relationAttrId:'',
        },
        dataForm: {
          id: 0,
            valueNo: '',
            valueName: '',
          valueNameEn:'',
          valueAlias:'',
          price: '',
          relationId: '',
          relationAttrId: '',
          description: '',
          productAttrId: '',
          fatherId: '',
          sort: '',
          brandId: '',
          otherSystemId: '',
          relationAttrValueId:'',
          delFlag: '',
          createBy: '',
          createDate: '',
          updateBy: '',
          updateDate: '',
          remarks: '',
          chooseBrand:'0',
          isOpen:"0",
        },
        isOpenList: [
          {
            value: '0',
            label: '展示'
          },
          {
            value: '1',
            label: '隐藏'
          }
        ],
        dataRule: {
          valueNo: [
            { required: true, message: '属性值编号不能为空', trigger: 'blur' }
          ],
          valueName: [
            { required: true, message: '属性值名称不能为空', trigger: 'blur' }
          ],
          valueAlias: [
            { required: true, message: '属性值别名不能为空', trigger: 'blur' }
          ],
          isOpen: [
            { required: true, message: '是否显示不能为空', trigger: 'blur' }
          ],
          chooseBrand: [
            { required: true, message: '关联品牌不能为空', trigger: 'blur' }
          ],
          price: [
            { required: true, message: '属性价格不能为空', trigger: 'blur' }
          ],
          productAttrId: [
            { required: true, message: '属性不能为空', trigger: 'blur' }
          ],
          fatherId: [
            { required: true, message: '上级属性值不能为空', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ],
          relationAttrValueId: [
            { required: true, message: '关联属性值不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur' }
          ],
        }
      }
    },
      created(){
          this.getBrandList();
          this.getproductAttrInfo(this.dataForm.brandId);
      },
    methods: {
      setBrandId(id){
        if(id === "0"){
          console.log("id为0")
          // 如果是全部
          this.dataForm.brandId ="0"
        }else{
          this.dataForm.brandId =null;
          this.getBrandList();
        }
      },
      // 获取数据列表
      selectAttrValueList () {
        this.productAttrList.map( item =>{
          if (item.id == this.dataForm.productAttrId) {
            this.relationAttr={
              relationAttrName:item.relationAttrName,
              relationAttrId:item.relationAttrId,
            }
            this.selectAttrValueList_()
          }
        })
        this.dataForm.fatherId = "0"
        this.dataListLoading = true
        var params = {
          productAttrId: this.dataForm.productAttrId,
          brandId: this.dataForm.brandId,
        }
        selectAttrValueList(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            console.log("dataList:"+this.dataList)
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      selectAttrValueList_ () {
        console.log(this.relationAttr);
        var params = {
          productAttrId: this.relationAttr.relationAttrId,
          brandId: this.dataForm.brandId,
        }
        selectAttrValueList(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList_ = data.page.list
          } else {
            this.dataList_ = [];
          }
        })
      },
        //产品属性值列表
        getproductAttrValueInfo(brandId){
          this.dataListLoading = true
          var params = {
            productAttrId: this.dataForm.productAttrId,
            brandId:brandId ? brandId : ''
          }
          selectAttrValueList(params).then(({data}) => {
            if (data && data.code === 0) {
              this.dataList = data.page.list
            } else {
              this.dataList = []
              this.totalPage = 0
            }
            this.dataListLoading = false
          })
        },
        //产品属性列表
        getproductAttrInfo(param){
          this.dataForm.productAttrId = null;
          this.relationAttr={
            relationAttrName:'',
            relationAttrId:'',
          },
          this.dataForm.relationAttrValueId = '',
          this.dataForm.fatherId = null;
          this.dataList = [];
            let params = {
              brandId : param == null || param == '0' || param == ' ' ? '' : param
            };
            getproductAttrInfo(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.productAttrList = data.list;
                    if (this.dataForm.id !== 0) {
                    this.selectAttrValueList(this.dataForm.productAttrId) 
                    }
                } else {
                    this.productAttrList = [];
                }
            });
        },
        //获取品牌列表
        getBrandList() {
            let params = {};
            getBrand(params).then(({ data }) => {
                if (data && data.code === 0) {
                    this.brandList = data.list;
                } else {
                    this.brandList = [];
                }
            });
        },
      init (id,brandId) {
        this.dataForm.chooseBrand = "0"
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.relationAttrName = ''
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            var params = {
              id:this.dataForm.id,
              brandId: brandId ? brandId : '',
            }
            info(params).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.brandId = data.productAttrValue.brandId
                console.log("brandId:"+this.dataForm.brandId)
                this.getproductAttrInfo(this.dataForm.brandId)
                this.dataForm.valueNo = data.productAttrValue.valueNo
                this.dataForm.valueName = data.productAttrValue.valueName
                this.dataForm.valueNameEn = data.productAttrValue.valueNameEn
                this.dataForm.valueAlias = data.productAttrValue.valueAlias
                this.dataForm.price = data.productAttrValue.price
                this.dataForm.relationId = data.productAttrValue.relationId
                this.dataForm.relationAttrId = data.productAttrValue.relationAttrId
                this.dataForm.description = data.productAttrValue.description
                this.dataForm.productAttrId = data.productAttrValue.productAttrId
                this.dataForm.fatherId = data.productAttrValue.fatherId
                this.dataForm.sort = data.productAttrValue.sort
                this.dataForm.relationAttrValueId = data.productAttrValue.relationAttrValueId
                if(this.dataForm.brandId !== null && this.dataForm.brandId !== "0" ){
                  this.dataForm.chooseBrand = "1"
                }
                this.dataForm.otherSystemId = data.productAttrValue.otherSystemId
                this.dataForm.delFlag = data.productAttrValue.delFlag
                this.dataForm.createBy = data.productAttrValue.createBy
                this.dataForm.createDate = data.productAttrValue.createDate
                this.dataForm.updateBy = data.productAttrValue.updateBy
                this.dataForm.updateDate = data.productAttrValue.updateDate
                this.dataForm.remarks = data.productAttrValue.remarks
                this.dataForm.isOpen = data.productAttrValue.isOpen
                this.getproductAttrValueInfo(brandId);
                // this.getBrandList();
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              'id': undefined,
              'valueNo': this.dataForm.valueNo,
              'valueName': this.dataForm.valueName,
              'valueNameEn': this.dataForm.valueNameEn,
              'valueAlias': this.dataForm.valueAlias,
              'price': this.dataForm.price,
              'relationId': this.dataForm.relationId,
              'relationAttrId': this.dataForm.relationAttrId,
              'description': this.dataForm.description,
              'productAttrId': this.dataForm.productAttrId,
              'fatherId': this.dataForm.fatherId,
              'sort': this.dataForm.sort,
              'relationAttrValueId': this.dataForm.brandId == '0' ? '' : this.dataForm.relationAttrValueId,
              'brandId': this.dataForm.brandId == '0' ? '' : this.dataForm.brandId,
              'otherSystemId': this.dataForm.otherSystemId,
              'delFlag': this.dataForm.delFlag,
              'createBy': this.dataForm.createBy,
              'createDate': this.dataForm.createDate,
              'updateBy': this.dataForm.updateBy,
              'updateDate': this.dataForm.updateDate,
              'remarks': this.dataForm.remarks,
              'isOpen': this.dataForm.isOpen
            }
            // var tick = !this.dataForm.id ? add(params) : update(params)
            var tick = add(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
