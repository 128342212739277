<template>
  <sides-lip ref="SidesLip">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="100px"
        size="small"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model="dataForm.roleName"
            placeholder="角色名称"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="dataForm.remark"
            placeholder="备注"
            maxlength="150"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色类型" prop="roleType">
          <template>
            <el-radio v-model="dataForm.roleType" label="1">管理员</el-radio>
            <el-radio v-model="dataForm.roleType" label="2">普通员工</el-radio>
            <el-radio v-model="dataForm.roleType" label="3"
              >实验室主管</el-radio
            >
            <el-radio v-model="dataForm.roleType" label="4">打样员</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="角色等级" prop="roleLevel">
          <template>
            <el-radio-group v-model="dataForm.roleLevel">
              <el-radio v-if="dataForm.roleType == 1" :label="1">系统</el-radio>
              <el-radio v-if="dataForm.roleType == 1" :label="2">品牌</el-radio>
              <el-radio
                v-if="dataForm.roleType == 1 || dataForm.roleType == 2"
                :label="3"
                >工厂</el-radio
              >
              <el-radio
                v-if="
                  dataForm.roleType == 2 ||
                  dataForm.roleType == 3 ||
                  dataForm.roleType == 4
                "
                :label="4"
                >实验室</el-radio
              >
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item label="是否自定义" prop="isSystem">
          <template>
            <el-radio-group v-model="dataForm.isSystem">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item size="mini" label="授权">
          <el-tree
            :data="menuList"
            :props="menuListTreeProps"
            node-key="menuId"
            ref="menuListTree"
            :default-expand-all="true"
            show-checkbox
          >
          </el-tree>
        </el-form-item>
      </el-form>
    </div>
    <div slot="soltFooter">
      <el-button @click="sideClose" size="small">取消</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </div>
  </sides-lip>
</template>

<script>
import { treeDataTranslate } from '@/utils'
import { menuList, roleDataFormSubmit, roleInfo } from '@/api/sys'
import SidesLip from '@/components/SidesLip'
export default {
  data() {
    return {
      loading: false,
      dataLoading: false,
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      },
      dataForm: {
        id: 0,
        roleName: '',
        remark: '',
        roleType: '1',
        roleLevel: 1,
        isSystem: '1'
      },
      dataRule: {
        roleName: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    SidesLip
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || 0
      const { data } = await menuList()
      this.menuList = treeDataTranslate(data, 'menuId')
      this.$refs.SidesLip.init(
        this.dataForm.id ? '修改' : '新增',
        '700px',
        'block'
      )
      await this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        this.$refs.menuListTree.setCheckedKeys([])
      })
      if (this.dataForm.id) {
        const { data } = await roleInfo(`/sys/role/info/${this.dataForm.id}`)
        if (data && data.code === 0) {
          this.dataForm.roleName = data.role.roleName
          this.dataForm.remark = data.role.remark
          this.dataForm.roleType = data.role.roleType
          this.dataForm.roleLevel = data.role.roleLevel
          this.dataForm.isSystem = data.role.isSystem
          this.$refs.menuListTree.setCheckedKeys(data.role.menuIdList)
        }
      }
    },
    sideClose() {
      this.$refs.SidesLip.sideClose()
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.dataLoading = true
          const params = {
            roleId: this.dataForm.id || undefined,
            roleName: this.dataForm.roleName,
            remark: this.dataForm.remark,
            roleType: this.dataForm.roleType,
            roleLevel: this.dataForm.roleLevel,
            isSystem: this.dataForm.isSystem,
            menuIdList: [].concat(
              this.$refs.menuListTree.getCheckedKeys(),
              this.$refs.menuListTree.getHalfCheckedKeys()
            )
          }
          const { data } = await roleDataFormSubmit(
            `/sys/role/${!this.dataForm.id ? 'save' : 'update'}`,
            params
          )
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.sideClose()
                this.dataLoading = false
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
            this.dataLoading = false
          }
        }
      })
    }
  }
}
</script>
